import React, { FC, Fragment, useEffect, ReactNode, useMemo } from 'react'
import ReactDOM from 'react-dom';

let wrap: HTMLElement;
const Toast = () => {
	return (content: ReactNode, type?: string) => {
		if (typeof document === "undefined") {
			return;
		}
		if (!wrap) {
			wrap = document.createElement("div");
			wrap.style.cssText = `width:100%; pointer-events:none; color: #fff; text-align: center; border-radius: 4px; position: fixed; top: 20px;  z-index: 10001; color: white;`
			if (wrap) {
				document.body && document.body.appendChild(wrap);
			}
		}
		
		const divs = document.createElement("div");
		wrap.appendChild(divs);
		ReactDOM.render(
			<Message rootDom={wrap} parentDom={divs} content={content} msgType={type}/>,
			divs
		);
	};
};

type MessageProps = {
	rootDom: HTMLElement;
	parentDom: Element | DocumentFragment;
	content: ReactNode;
  msgType: string | undefined
};

function Message(props: MessageProps) {
	const { rootDom, parentDom, content, msgType } = props;
	const unmount = useMemo(() => {
		return () => {
			if (parentDom && rootDom) {
				ReactDOM.unmountComponentAtNode(parentDom);
				rootDom.removeChild(parentDom);
			}
		};
	}, [parentDom, rootDom]);

	useEffect(() => {
		setTimeout(() => {
			unmount();
		}, 5000);
	}, [unmount]);
	return <div style={{ background: msgType === 'warning' ? '#ff9900' : msgType === 'error' ? '#ed4014' : '#19be6b', padding:'5px 8px', borderRadius: 5, marginBottom: 8, display: 'inline-block'}}>{content}</div>
}



export default Toast()