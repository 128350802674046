import { Input, message, Modal, Select, Spin, Button, Row, Col, Divider, Table, Slider, Progress, Image } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios'
import Datatopbox from "./Components/Datatopbox";
import UploadModel from '@/components/UploadModel'
import html2canvas from 'html2canvas'
import { dataURItoBlob } from '@/utils/utils'






const model_type_arr: any = [
  {
    label: '车身',
    value: '车身'
  },
  {
    label: '底盘',
    value: '底盘'
  },
  {
    label: '发动机',
    value: '发动机'
  },
  {
    label: '电池',
    value: '电池'
  },
  {
    label: '变速箱',
    value: '变速箱'
  }
]
let model_type_obj: any = {
  car_body: '车身',
  car_chassis: '底盘',
  car_engine: '发动机',
  car_battery: '电池',
  car_transmission: '变速箱'
}

let indexObj: any = {}
let active_model_id = ""
let category = ""
let imgShow = ''
let car_model_name = ""
let model_file_android_url = ''
let model_file_ios_url = ''
let model_file_url = ''
let file_name = ''

const Tmodel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [keyword, setKeyword] = useState<string>("")
  const [brandCode, setBrandCode] = useState<string>('haval')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [vinOpen, setVinOpen] = useState<boolean>(false)
  

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const [vinList, setVinList] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)

  const [updateType, setUpdateType] = useState<number>(1)

  const [farkOpen, setFarkOpen] = useState<any>(false)
  const [vinLoading, setVinLoading] = useState<boolean>(false)

  const [imgOpen, setImgOpen] = useState<boolean>(false)
  const [fileOpen, setFileOpen] = useState<boolean>(false)
  

  const [selectObj, setSelectObj] = useState<any>({})

  useEffect(() => {
    initExcel()

    setTimeout(() => {
      getList()
    })
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    axios.get("/v1/operate/threed/optimize/category/list").then((res: any) => {
      if(res.code === 1) {
        let _selectObj: any = {}
        Object.values(res.data).forEach((items: any) => {
          items.forEach((item: any) => {
            _selectObj[item.key] = item.values
          })
        })
        setSelectObj(_selectObj)
      }
    })
  },[])


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 10,
        width: 120
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('change',(e: any) => console.log(e))

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        active_model_id = rowData[indexObj['id']] ? rowData[indexObj['id']].text : ''
        if(!active_model_id) return false
        if(ci === indexObj['operate']) {
          setOpen(true)
        }else if(ci === indexObj['associated_count']) {
          category = rowData[indexObj['category']]?.text || ''
          car_model_name = rowData[indexObj['car_model_name']]?.text || ''
          setFarkOpen(true)
        }else if(ci === indexObj['model_img']) {
          imgShow = rowData[indexObj['model_img']]?.text || ''
          imgShow && setImgOpen(true)
        }else if(ci === indexObj['delete']) {
          Modal.confirm({
            content: '确定删除此模型吗？',
            onOk() {
              let params = {
                model_car_id: active_model_id
              }
              axios.get("/v1/operate/threed/optimize/models/car/delete", {params}).then((res: any) => {
                if(res.code === 1) {
                  message.success("操作成功！")
                  getList()
                }
              })
            }
          })
          
        }else if(ci === indexObj['model_file_ios']) {
          model_file_ios_url = rowData[indexObj['model_file_ios_url']]?.text || ''
          model_file_url = model_file_ios_url
          file_name = rowData[indexObj['model_file_ios']]?.text || ''
          if(model_file_ios_url && file_name) setFileOpen(true)
          
        }else if(ci === indexObj['model_file_android']) {
          model_file_android_url = rowData[indexObj['model_file_android_url']]?.text || ''
          model_file_url = model_file_android_url
          file_name = rowData[indexObj['model_file_android']]?.text || ''
          if(model_file_android_url && file_name) setFileOpen(true)
        }
      }
      
    })
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = ['associated_count', 'model_img', 'model_file_ios', 'model_file_android']
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        if(other.includes(item.key)) {
          cells.push({
            text,
            editable: false,
            style: 3
          })
        }else if(item.key === 'operate') {
          cells.push({
            text: '编辑',
            editable: true,
            style: 3
          })
        }else if(item.key === 'delete') {
          cells.push({
            text: '删除',
            editable: true,
            style: 2
          })
        } else {
          cells.push({
            text,
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        },
        {
          color: '#096BED'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    let params = {
    }
    setLoading(true)
    axios.get("/v1/operate/threed/optimize/models/car/list", {params}).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        res.data.forEach((item: any) => {
          item.completed_total = item.completed + '/' + item.total
        })
        let header = [
          {label: '类型', key: 'category'},
          {label: '名称', key: 'car_model_name'},
          {label: '安卓文件', key: 'model_file_android'},
          {label: 'IOS文件', key: 'model_file_ios'},
          {label: '图片', key: 'model_img'},
          {label: '关联车型', key: 'associated_count'},
          {label: '完成度', key: 'completed_total'},
          {label: '操作时间', key: 'update_time'},
          {label: '操作', key: 'operate'},
          {label: '操作', key: 'delete'},
          {label: 'id', key: 'id'},
          {label: '类型', key: 'model_type'},
          {label: 'model_file_android_url', key: 'model_file_android_url'},
          {label: 'model_file_ios_url', key: 'model_file_ios_url'},
        ]
        let list = res.data
        initData(list, header)
      }
    })
  }


  const downloadFile = () => {
    let a: any = document.createElement("a")
    a.setAttribute("download", true)
    a.href = model_file_url
    a.click()
    a.remove()
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div style={{ position: 'relative' }}>
          <Button
            style={{ position: 'absolute', right: 10, top: 5 }}
            type="primary"
            onClick={() => {active_model_id = "" ;setOpen(true)}}
          >添加模型</Button>
          <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
        </div>
      </Spin>
      

      <Modal
        title="vin详细"
        open={vinOpen}
        onCancel={() => setVinOpen(false)}
        footer={null}
      >
        <div className='mtb20'>
          
        </div>
      </Modal>

      {
        open ? (
          <Emodel
            key={active_model_id}
            onCancel={() => setOpen(false)}
            model_id={active_model_id}
            reflush={() => getList()}
            selectObj={selectObj}
          />
        ): null
      }

      {
        farkOpen ? (
          <Fark
            onCancel={() => setFarkOpen(false)}
            model_id={active_model_id}
            reflush={() => getList()}
          />
        ): null
      }

      <Modal
        open={imgOpen}
        onCancel={() => setImgOpen(false)}
        width={846}
        footer={null}
      >
        <Image src={imgShow} width={800} />
      </Modal>

      <Modal
        open={fileOpen}
        onCancel={() => setFileOpen(false)}
        width={400}
        footer={null}
      >
        <div className='mtb20'>
          <h2 className='mb20 p-color'>{file_name}</h2>
          <Button size="large" type="primary" style={{ width: '100%' }} onClick={() => downloadFile()}>点击下载</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Tmodel

let indexObj1: any = {}
let part_sn = ''
let midIndex = -1
let model_name_map: any = []

const CancelToken = axios.CancelToken
const controller = new AbortController();
let timeout: any = null

// 新增修改模型
const Emodel: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { onCancel, model_id, reflush = () => {}, selectObj } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({id: model_id})
  const [attributes, setAttributes] = useState<any>({})

  const [open, setOpen] = useState<boolean>(false)
  const [stdOptions, setStdOptions] = useState<any>([])
  const [updateSid, setuUpdateSid] = useState<any>("")

  const [ sliderVal, setSliderVal ] = useState<number>(0)
  const [ progress, setProgress ] = useState<number>(0)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [change, setChange] = useState<boolean>(false)

  const [stdTip, setStdTip] = useState<string>("")
  const [stdTipOpen, setStdTipOpen] = useState<boolean>(false)

  


  const model = useRef<any>(null)

  const canUpdate_3d = useRef<any>(null) 

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  let getCancel = useRef<any>(null)

  let header = [
    {
      label: '模型ID',
      key: 'model_id'
    },
    {
      label: '标准名称',
      key: 'standard_name'
    },
    {
      label: '方位',
      key: 'position'
    },
  ]

  useEffect(() => {
    if(activeItem.model_file_android_url) {
      setTimeout(() => {
        update3d()
      },500)
    }
  },[activeItem.model_file_android_url])


  useEffect(() => {
    axios.get("/v1/operate/threed/optimize/standard/name", {}).then((res: any) => {
      if(res.code === 1) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.standard_label + `${item.extend ? '-' + item.extend : ''}`,
            // value: item.standard_label + `${item.extend ? '-' + item.extend : ''}`,
            value: item.uuid,
            ...item
          }
        })
        setStdOptions(_list)
      }
    })
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
      model.current && model.current.uninstall()
    }
  },[])

  useEffect(() => {
    if(activeItem.id) {
      getModelDetail()
    }
  },[activeItem.id])


  const getModelDetail = async () => {
    let params = {
      model_car_id: model_id
    }
    setLoading(true)
    let res: any = await axios.get(`/v1/operate/threed/optimize/models/car/detail`, {params})
    if(res.code === 1) {
      setLoading(false)
      setActiveItem(res.data)
    }
  }

  const update3d = () => {
    let file = activeItem.model_file_android_url
    model.current && model.current.uninstall()
    model.current = (window as any).matchbox({
      file,
      id: 'container',
      scale: 1.5,
      loaded: function(val: any){
        if(val === 100) {
          setProgress(0)
        }else {
          setProgress(val)
        }
      },
      click: (obj: any, status: any) => {
        let pos = obj.object?.name || ""
        setTimeout(() => {
          model.current.setActive([pos], true, true, '#FF00FF')
        })
        jexcelRef.current.setScrollbar(0, pos)
      },
      sceneBack(ids: any) {
        console.log(activeItem.model_name_map, !canUpdate_3d.current)
        if(activeItem.model_name_map && activeItem.model_name_map.length) {
          canUpdate_3d.current = true
          initData(activeItem.model_name_map, header)
          return false
        }
        function getDuplicates(arr: any) {
          return [...new Set(arr) as any].filter(item => arr.indexOf(item) !== arr.lastIndexOf(item));
        }
        const duplicates = getDuplicates(ids)
        if(duplicates.length) {
          setStdTip(duplicates.join(","))
          setStdTipOpen(true)
        }
        let _ids = [...new Set(ids) as any]
        
        let list = _ids.map((id: any) => {
          return {
            model_id: id,
            standard_name: '',
            position: ''
          }
        })
        initData(list, header)
        
      }
    })
    const setModel = (data: any) => {
      model.current.setActive(data, true, true, '#FF00FF')
    }
  }
  const explosion = (val: any) => {
    model.current.applyScalar(val)
    setSliderVal(val)
  }

  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj1 = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj1[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname + '1')
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'read', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 3,
        width: 86
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        part_sn = rowData[indexObj1['model_id']] ? rowData[indexObj1['model_id']].text : ''
        let old_sid =  rowData[indexObj1['oe_id']] ? rowData[indexObj1['oe_id']].text : ''
        let standard_name = rowData[indexObj1['standard_name']]?.text || ""
        let position = rowData[indexObj1['position']]?.text || ""
        midIndex = ri
        if(!part_sn) return false
        model.current.setActive([part_sn], true, true, '#FF00FF')
        if(ci === indexObj1['standard_name']) {
          old_sid = standard_name + (position ? '-' + position : '')
          if(old_sid) {
            setuUpdateSid(old_sid)
          }else {
            setuUpdateSid("")
          }
          setOpen(true)
        }
      }
      
    })
    // jexcelRef.current.on("change", () => console.log(123))

    jexcelRef.current.validate()
  }

  const submit = async () => {
    let rows = jexcelRef.current.getData()[0].rows ? Object.values(jexcelRef.current.getData()[0].rows) : []
    let _model_name_map: any = []
    rows.forEach((it: any, index: number) => {
      let cells = it.cells || []
      let model_id = cells[indexObj1['model_id']]?.text || ""
      let standard_name = cells[indexObj1['standard_name']]?.text || ""
      let position = cells[indexObj1['position']]?.text || ""
      if(index && model_id) {
        _model_name_map.push({
          model_id,
          standard_name,
          position,
        })
      }
    })
    
    let params = {
      ...activeItem,
      model_name_map: _model_name_map
    }
    if(params.id) {
      params.model_car_id = params.id
      delete params.id
    }


    if(!(params.car_model_name && params.model_file_android_url && params.category)) {
      message.warning("请填写必填信息！")
      return false
    }
    if(params.model_file_android_url) {
      let model_file_android_url_arr = params.model_file_android_url.split(".")
      params.model_file_android = model_file_android_url_arr[model_file_android_url_arr.length - 2]
    }
    if(params.model_file_ios_url) {
      let model_file_ios_url_arr = params.model_file_ios_url.split(".")
      params.model_file_ios = model_file_ios_url_arr[model_file_ios_url_arr.length - 2]
    }

    setSubmitLoading(true)
    let base64Url: string = model.current.generateImg()
    let file = dataURItoBlob(base64Url)
    let formData = new FormData();
    formData.append('file', file, `${activeItem.car_model_name}.png`)
    let imgRes: any = await axios.post("/v1/operate/common/upload", formData)
    params.model_img = imgRes.code === 1 ? imgRes.data?.img_url : ''
    
    

    console.log(params)
    delete params.completed
    
    axios.post(`/v1/operate/threed/optimize/models/car/edit`, params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功！")
        onCancel()
        reflush()
      }
    })
  }

  const getStdOptions = (value?: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
    const getCompanyOption = () => {
      let params = {
        cancelToken: rToken,
        signal: controller.signal,
        name: value
      }
      axios.get("/v1/operate/threed/optimize/standard/name", {params}).then((res: any) => {
        if(res.code === 1) {
          let _list = res.data.map((item: any) => {
            return {
              label: item.standard_label + `${item.extend ? '-' + item.extend : ''}`,
              value: item.standard_label + `${item.extend ? '-' + item.extend : ''}`,
              ...item
            }
          })
          setStdOptions(_list)
        }
      })
    }
    if (value && value.length >= 2) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setStdOptions([]);
    }
  }
  const handleStdData = (option = {label: "", value: ""}) => {
    // 清除
    
    let { label } = option
    let _s_name = label.split("-")[0] || ""
    let _pos= label.split("-")[1] || ""
    jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['standard_name'], {
      text: _s_name,
      editable: true,
    }, 'all')
    jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['position'], {
      text: _pos,
      editable: true,
    }, 'all')
    jexcelRef.current.reRender()

    setuUpdateSid(option.value)
    setOpen(false)
    
    // message.success("操作成功！")
    
  }
  return (
    <>
      <Modal
        title={`${activeItem.id ? '编辑' : '新增'}模型`}
        open={true}
        onCancel={() => onCancel()}
        width={1300}
        centered
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={submit}
      >
        <Spin spinning={loading}>
          <div className='flex mt30'>
            <div className='flex-1'>
              <div className='flex-s-c'>
                <label><span className='red'>*</span>类型：</label>
                <Select
                  options={model_type_arr}
                  value={activeItem.category}
                  onChange={(value) => setActiveItem({...activeItem, category: value})}
                  style={{ width: 120 }}
                  disabled={activeItem.id}
                />
                <label className='ml15'><span className='red'>*</span>名称：</label>
                <Input
                  value={activeItem.car_model_name}
                  style={{ width: 160 }}
                  onChange={(e: any) => setActiveItem({...activeItem, car_model_name: e.target.value})}
                />
                <label className='ml10'><span className='red'>*</span>文件：</label>
                <div className=' flex-s-c'>
                  <UploadModel
                    item={activeItem}
                    lkey="iourl_for_ios"
                    uploadLabel="上传IOS文件(加密zip)"
                    updateWrap={() => setActiveItem({...activeItem})}
                  />
                  <div className='mlr5'></div>
                  <UploadModel
                    item={activeItem}
                    lkey="model_file_android_url"
                    uploadLabel="上传安卓文件(Gltf)"
                    updateWrap={() => setActiveItem({...activeItem})}
                  />
                </div>
              </div>
              <div className='flex-s-c mt10'>
                <label><span className='red'>*</span>参数：</label>
                {
                  activeItem.category === '底盘' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择燃油方式"
                        options={selectObj['fuel_type']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.fuel_type || undefined}
                        onChange={(value: any) => { setActiveItem({...activeItem, fuel_type: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择驱动方式"
                        options={selectObj['drive_mode']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.drive_mode || undefined}
                        onChange={(value: any) => { setActiveItem({...activeItem, drive_mode: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择前悬架"
                        options={selectObj['front_suspension']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.front_suspension || undefined}
                        onChange={(value: any) => { setActiveItem({...activeItem, front_suspension: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择后悬架"
                        options={selectObj['rear_suspension']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.rear_suspension || undefined}
                        onChange={(value: any) => { setActiveItem({...activeItem, rear_suspension: value}) }}
                      />
                    </>
                  ):activeItem.category === '发动机' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择燃油方式"
                        options={selectObj['fuel_type']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.fuel_type}
                        onChange={(value: any) => {
                          setActiveItem({...activeItem, fuel_type: value})
                        }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择气缸数"
                        options={selectObj['cylinders']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.cylinders}
                        onChange={(value: any) => {
                          setActiveItem({...activeItem, cylinders: value})
                        }}
                      />
                      {/* <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择布局"
                        options={selectObj['layout']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.layout}
                        onChange={(value: any) => {
                          setActiveItem({...activeItem, layout: value})
                        }}
                      /> */}
                    </>
                  ):activeItem.category === '电池' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择电池品牌"
                        options={selectObj['battery_brand']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.battery_brand}
                        onChange={(value: any) => {
                          setActiveItem({...activeItem, battery_brand: value})
                        }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择电池类型"
                        options={selectObj['battery_type']?.map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={activeItem.battery_type}
                        onChange={(value: any) => {
                          setActiveItem({...activeItem, battery_type: value})
                        }}
                      />
                    </>
                  ):activeItem.category === '变速箱' ? (
                    <Select
                      style={{ width: 130, marginRight: 10 }}
                      placeholder="选择变速箱类型"
                      options={selectObj['gearbox']?.map((it: string) => {
                        return {
                          label: it,
                          value: it
                        }
                      })}
                      value={activeItem.gearbox}
                      onChange={(value: any) => {
                        setActiveItem({...activeItem, gearbox: value})
                      }}
                    />
                  ): null
                }
              </div>

              <div className='flex-c-c mt10' style={{ width: '100%', height: 450 }}>
                {
                  activeItem.model_file_android_url ? (
                    <div style={{ position: 'relative', border: '1px solid #f8f8f8', height: 450 }}>
                      {
                        progress ? <div className='flex-c-c' style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}><Progress style={{ width: 600 }} percent={progress} /></div> : null 
                      }
                      <div id="container" style={{ width: 920, height: 450 }}></div>
                      <div className='flex-c-c'>
                        <Slider style={{ width: 300 }} min={0} max={100} value={sliderVal} onChange={(val)=>explosion(val)} />
                      </div>
                    </div>
                  ): (
                    <UploadModel
                      item={activeItem}
                      lkey="model_file_android_url"
                      updateWrap={() => setActiveItem({...activeItem})}
                    >
                      <div className='flex-c-c pointer' style={{ flexDirection: 'column', width: 180, height: 180, background: '#EDF4FE', color: '#096BED' }}>
                        <i className='iconfont icon-guanlian' style={{ fontSize: 30 }} />
                        <p>上传安卓文件</p>
                      </div>
                    </UploadModel>
                  )
                }
              </div>

            </div>
            <div className='del-x-spreadsheet-total' style={{ width: 320, height: 600 }} ref={jexcelDivRef} id={pathname + '1'}></div>
          </div>
        </Spin>
      </Modal>

      <Modal
        open={open}
        title={`选择标准名称(${part_sn})`}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div className='flex-s-c mt20'>
          <label style={{ width: 120 }}>标准名称-方位</label>
          <Select
            style={{ flex: 1 }}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={stdOptions}
            // onSearch={(value: any) => getStdOptions(value)}
            value={updateSid}
            allowClear
            onChange={(value: any, option: any) => handleStdData(option)}
          />
        </div>
      </Modal>
      
      <Modal
        title='提示'
        open={stdTipOpen}
        onCancel={() => setStdTipOpen(false)}
        okText="是"
        cancelText="否"
        onOk={() => {
          setActiveItem({...activeItem, model_file_android_url: ''})
          setStdTipOpen(false)
          let a = {
            name: '',
            merges: [],
            rows: [],
            autofilter: {
              filters: [],
              ref: 'A1:O1'
            },
            cols: {
              
            },
            freeze: "A2",
            styles: [
              {
                bgcolor: '#d8d8d8',
                font: {
                  bold: true
                }
              },
              {
                color: '#ff0000'
              },
              {
                color: '#ec8d00'
              }
            ]
          }
          jexcelRef.current.loadData([a])
        }}
        onClose={() => {
          setStdTipOpen(false)
        }}
      >
        <div className='mtb20'>
          存在重复的模型下id：
          <p>{stdTip}</p>
          是否需要重新选择模型？
        </div>
      </Modal>

    </>
    
  )
}




let indexObj2: any = {}
let selectIndexs: any = []
let hasSelect: any = []
// 关联车型
const Fark: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandsList } = store
  const { pathname } = useLocation()
  const { onCancel, model_id, reflush = () => {} } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [brand, setBrand] = useState<any>('')

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  
  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  let header = [
    {
      label: '品牌',
      key: 'brand'
    },
    {
      label: '车系',
      key: 'series'
    },
    {
      label: '车型',
      key: 'model'
    },
    {
      lable: 'code',
      key: 'code'
    }
  ]

  useEffect(() => {
    getHasSelect()
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
      hasSelect = []
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}" && brand) {
      getList()
    }
  },[brand])

  const getHasSelect = () => {
    let params = {
      category,
      model_car_id: model_id
    }
    axios.get("/v1/operate/threed/optimize/models/standard/car/list", {params}).then((res: any) => {
      if(res.code === 1) {
        hasSelect = res.data
      }
    })
  }

  const getList = () => {
    let params = {
      brand
    }
    setLoading(true)
    axios.get("/v1/operate/threed/optimize/standard/cars/list", {params}).then((res: any) => {
      setLoading(false)
      let codes = hasSelect.map((it: any) => parseInt(it.code))
      let other = ['produced_year', 'chassis_code', 'engine_model', 'displacement', 'transmission_description', 'drive_mode', 'fuel_type']
      let list = res.data.filter((it: any) => !codes.includes(it.code)).map((item: any) => {
        item.model = item.models
        other.forEach((key: string) => {
          if(item[key]) {
            item.model += '>' + item[key]
          }
        })
        return item
      })
      initData(list, header)
    })
  }
  
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj2 = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj2[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        2: {
          width: 590
        }
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname + '2')
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'read', //edit
      row: {
        len: 50000,
      },
      col: {
        len: header.length - 1,
        width: 150
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)

    jexcelRef.current.on("cell-row-selected", (cell: any, rows: any) => {
      console.log(cell, rows)
      selectIndexs = rows
    })
    jexcelRef.current.validate()
  }

  const submit = () => {
    let _associate_list: any = []
    selectIndexs.forEach((index: number) => {
      let item = jexcelRef.current.datas[0].rows.getOrNew(index).cells
      console.log(item)
      let code = item[indexObj2['code']]?.text || ''
      let brand = item[indexObj2['brand']]?.text || ''
      let series = item[indexObj2['series']]?.text || ''
      let models = item[indexObj2['model']]?.text || ''
      if(code) {
        _associate_list.push({
          brand,
          code,
          series,
          models
        })
      }
    })
    hasSelect.forEach((item: any) => {
      _associate_list.push({
        brand: item.brand,
        code: parseInt(item.code),
        series: item.series,
        models: item.models
      })
    })
    let params = {
      model_car_id: model_id,
      associate_list: _associate_list
    }
    console.log(params)
    // return false
    setSubmitLoading(true)
    axios.post(`/v1/operate/threed/optimize/cars/model/associate`, params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功！")
        onCancel()
        reflush()
      }
    })
  }
  return (
    <Modal
      title={`关联车型-${car_model_name}`}
      open={true}
      onCancel={() => onCancel()}
      width={1000}
      centered
      okButtonProps={{
        loading: submitLoading
      }}
      onOk={submit}
    >
      <Spin spinning={loading}>
        <div>
          <div className='flex-s-c'>
            <Select
              className="mr12"
              style={{ width: 200 }}
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={brandsList}
              placeholder="品牌"
              value={brand || undefined}
              allowClear
              onChange={(value) => setBrand(value)}
            />
          </div>
          <div className='mt10' style={{ width: '100%', height: 500 }} ref={jexcelDivRef} id={pathname + '2'}></div>
        </div>
      </Spin>
    </Modal>
  )
}

