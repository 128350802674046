import { Button, Checkbox, Input, message, Modal, Select, Spin, Tag } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import axios from 'axios'
import namestdApi from '@/api/namestdApi'
import { color } from 'html2canvas/dist/types/css/types/color'



let indexObj: any = {}
let activeIndex: any = -1


const Stdclass: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [associationOpen, setAssociationOpen] = useState<boolean>(false)
  
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [activeItem, setActiveItem] = useState<any>({})

  const [ctid, setCtid] = useState<number>(0)
  const [qdlxList, setQdlxList] = useState<any>([])
  const [topList, setTopList] = useState<any>([])

  const [selectStdList, setSelectStdList] = useState<any>([])
  const [searchKey, setSearchKey] = useState<string>("")

  const [change, setChange] = useState<boolean>(false)

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const ctidRef = useRef<any>(0)
  const fidRef = useRef<any>(0)

  let header = [
    {
      label: '渠道类型',
      key: 'name'
    },
    {
      label: '一级分类',
      key: 'one'
    },
    {
      label: '二级分类',
      key: 'two'
    },
    {
      label: '三级分类',
      key: 'three'
    },
    // {
    //   label: '关联品类',
    //   key: 'association'
    // },
    {
      label: '品类数量',
      key: 'num'
    },
    {
      label: 'fid',
      key: 'fid'
    }
  ]

  
  

  useEffect(() => {
    getQdlx()
    getTopList()
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
      ctidRef.current = null
      fidRef.current = null
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}" && ctid) {
      ctidRef.current = ctid
      getList()
    }
  },[ctid])

  const getQdlx = () => {
    namestdApi.getQdlxList().then((res: any) => {
      if(res.code === 1) {
        setQdlxList(res.data)
        setCtid(res.data[0].ctid)
      }
    })
  }

  const getTopList = () => {
    namestdApi.getTopList().then((res: any) => {
      if(res.code === 1) {
        setTopList(res.data)
      }
    })
  }


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: header.length - 1,
        width: 1200 / (header.length - 1)
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    
    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        console.log(rowData)
        let fid = rowData[indexObj['fid']] ? rowData[indexObj['fid']].text : ''
        fidRef.current = fid
        if(ci === indexObj['num'] && fid) {
          let params = {
            fid,
            ctid: ctidRef.current
          }
          setSearchKey("")
          setSelectStdList([])
          setAssociationOpen(true)
          activeIndex = ri
          namestdApi.getMappingList(params).then((res: any) => {
            if(res.code === 1) {
              setSelectStdList(res.data)
            }
          })
        }
      }
      
    })

    // 编辑输入
    jexcelRef.current.on("cell-blur", (text: any, ri: any, ci: any) => {
      if(!ri) return false
      console.log(text, ri, ci)
      let key = Object.keys(indexObj).filter((it: any) => indexObj[it] === ci)[0] || ''
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      rowData = Object.values(rowData)
      let params: any = {}
      Object.keys(indexObj).forEach((k: string) => {
        params[k] = rowData[indexObj[k]]?.text || ''
      })
      delete params['operate']
      if(key) {
        params[key] = text
        submit(params)
      } 
      
    })
    
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers = header) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        let editable = true
        let styleObj = {}
        if(item.key === 'operate') {
          editable = false
          styleObj = {style: 2}
          text = '删除'
        }else if(item.key === 'name') {
          editable = false
        }else if(item.key === 'num') {
          styleObj = {style: 3}
          editable = false
        }
        cells.push({
          text,
          editable,
          ...styleObj
        })
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        },
        {
          color: '#096BED'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    setLoading(true)
    namestdApi.getStdclassList({ctid: ctidRef.current}).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        initData(res.data)
      }
    })
  }


  const submit = (inputItem?: any) => {
    let item = inputItem || activeItem
    let api: any
    if(item.fid) {
      api = namestdApi.updateStdclass(item)
    }else {
      api = namestdApi.addStdclass(item)
    }
    setSubmitLoading(true)
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        if(!item.fid) getList()
        setOpen(false)
        message.success("操作成功！")
      }
    })
  }

  const submitAssociation = () => {
    let params = {
      ctid: ctidRef.current,
      fid: fidRef.current,
      uuid: selectStdList.map((it: any) => it.uuid).join(",")
    }
    setSubmitLoading(true)
    namestdApi.updateMappingList(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setAssociationOpen(false)

        jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['num'], {
          text: selectStdList.length,
          style: 3,
          editable: false
        }, 'all')
        jexcelRef.current.reRender()

        message.success("操作成功！")
      }
    })
  }
  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div className='flex-b-c'>
          <div className='flex-s-c'>
            <label>渠道类型：</label>
            <Select
              placeholder="渠道类型"
              style={{ width: 170 }}
              options={qdlxList}
              value={ctid ? ctid : undefined}
              onChange={(value) => setCtid(value)}
              fieldNames={{ label: 'name', value: 'ctid' }}
            />
          </div>
          
          <Button onClick={() => { setOpen(true); setActiveItem({ctid}) }} type="primary" >添加分类</Button>
        </div>
        <div className='mt10' style={{ height: 'calc(100vh - 100px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title="添加分类"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => submit()}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className='mtb20'>
          <div className='flex-s-c'>
            <label className='right' style={{ width: 100 }}><span className='red'>*</span>渠道类型：</label>
            <Select
              placeholder="渠道类型"
              style={{ flex: 1 }}
              options={qdlxList}
              value={activeItem.ctid}
              onChange={(value) => setActiveItem({...activeItem, ctid: value})}
              fieldNames={{ label: 'name', value: 'ctid' }}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>一级分类：</label>
            <Input style={{ flex: 1 }} value={activeItem.one} onChange={(e: any) => setActiveItem({...activeItem, one: e.target.value})} />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>二级分类：</label>
            <Input style={{ flex: 1 }} value={activeItem.two} onChange={(e: any) => setActiveItem({...activeItem, two: e.target.value})} />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>三级分类：</label>
            <Input style={{ flex: 1 }} value={activeItem.three} onChange={(e: any) => setActiveItem({...activeItem, three: e.target.value})} />
          </div>
        </div>
      </Modal>

      <Modal
        title="关联品类"
        open={associationOpen}
        onCancel={() => setAssociationOpen(false)}
        onOk={() => submitAssociation()}
        okButtonProps={{
          loading: submitLoading
        }}
        width={600}
      >
        <div className='flex-b mtb20'>
          <div className='pr15' style={{ width: '50%', borderRight: '1px solid #E9E9E9' }}>
            <Input.Search placeholder='输入名称搜索'  onSearch={(value) => setSearchKey(value)} />
            <div className='border br4 mt10 plr10 ptb5' style={{ maxHeight: 500, overflow: 'scroll' }}>
              {
                topList.map((item: any, index: number) => {
                  let uuid = item.uuid
                  let selectUuids = selectStdList.map((it: any) => it.uuid)
                  if(searchKey && !item.name.includes(searchKey)) return null
                  return (
                    <div className='mtb5'>
                      <Checkbox 
                        checked={selectUuids.includes(uuid)} 
                        onClick={() => {
                          let _selectStdList = [...selectStdList]
                          if(selectUuids.includes(uuid)) {
                            let i = selectUuids.indexOf(uuid)
                            _selectStdList.splice(i, 1)
                          }else {
                            _selectStdList.push(item)
                          }
                          setSelectStdList(_selectStdList)
                        }} 
                      >{item.name}</Checkbox>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='pl15' style={{ width: '50%' }}>
            <div className='b'>已关联品类（{selectStdList.length}）</div>
            <div className='mt5'>
              {
                selectStdList.map((it: any, i: number) => {
                  return (
                    <div className='mtb5' key={i}>
                      <Tag style={{ width: '100%' }} closable 
                        onClose={(e: any) => {
                          console.log(e)
                          e.preventDefault()
                          selectStdList.splice(i, 1)
                          setChange(c => !c)
                        }}
                      >{it.name}</Tag>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default Stdclass









