import { Input, message, Modal, Select, Spin, Button } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal

let indexObj: any = {}
let oldWord: any

const Smodel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandsList, brandData, writeAuth } = store
  const { pathname } = useLocation()

  const [brand, setBrand] = useState<string>(brandsList[0] ? brandsList[0].value : '')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})


  useEffect(() => {
    initExcel()
  },[])

  useEffect(() => {
    getList()
  },[brand])

  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 16,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',cellEdited)

    jexcelRef.current.on('cell-selected', (e: any) => {
      if(e) {
        oldWord = e.text || ""
      }
    })
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    // jexcelRef.current.on('cell-edited', (e: any) => console.log(e))
    
    
    // 编辑输入
    jexcelRef.current.on("cell-blur", (text: any, ri: any, ci: any) => {
      if(!ri) return false
      console.log(text, ri, ci)
      if(true) {
        let key = Object.keys(indexObj).filter((it: any) => indexObj[it] === ci)[0] || ''
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        rowData = Object.values(rowData)
        console.log(rowData)
        let params: any = {
          operate: '修改',
          ys_value: oldWord,
          value: text,
          key
        }
        let other = ['brand', 'carid']
        let keys: any = Object.keys(indexObj)
        rowData.forEach((it: any, i: number) => {
          let key = keys[i]
          if(other.includes(key)) {
            params[key] = it.text
          }
        })
        params.carsid = params.carid
        delete params.carid
        confirm({
          title: '确认修改此条数据吗？',
          icon: <ExclamationCircleFilled />,
          onOk: () => {
            if(!writeAuth[pathname]) {
              message.warning("暂不支持编辑操作！")
              return false
            }
            if(params.ys_value === params.value) return false
            dataApi.updateSmodel(params).then((res: any) => {
              if(res.code === 1) {
                message.success("操作成功！")
              }else {
                message.warning("操作失败")
              }
            }) 
          },
          onCancel: () => {
            jexcelRef.current.datas[0].rows.setCell(ri, ci, {
              text: params.ys_value,
            }, 'all')
            jexcelRef.current.reRender()
          }
        })
      }
    })
    
    jexcelRef.current.validate()
    
    // getList()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other = ['carid', 'operate', 'time']
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }
  const getList = () => {
    let params = {
      brand
    }
    setLoading(true)
    dataApi.getSmodelList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let column_name_dict = res.data.column_name_dict
        let header = Object.keys(column_name_dict).map((key: any) => {
          return {
            label: column_name_dict[key],
            key
          }
        })
        initData(res.data.row_list, [...header])
      }
    })
  }


  const submit = () => {
    let params = {
      ...activeItem,
      operate: '新增'
    }
    setSubmitLoading(true)
    dataApi.addSmodel(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setOpen(false)
        getList()
        message.success("新增成功！")
      }
    })
  }

  return (
    <div className='p10 bgc-fff' >
      <div className='flex-b-c'>
        <div className='flex-s-c'>
          <span>筛选：</span>
          <Select
            className="mr12"
            style={{ width: 170 }}
            placeholder="品牌"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={brandsList}
            value={brand || undefined}
            onChange={(value: any) => setBrand(value)}
          />
        </div>
        {
          writeAuth[pathname] ? (
            <Button type="primary" ghost onClick={() => setOpen(true)} icon={<i className="iconfont icon-guanlian" />}>新增</Button>
          ): null
        }
      </div>
      
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      <Modal
        title="新增车型"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className='mtb20'>
          {
            [
              {
                label: '地区',
                key: 'market'
              },
              {
                label: '厂家',
                key: 'manufacturers'
              },
              {
                label: '品牌',
                key: 'brand',
                type: 'select'
              },
              {
                label: '车系',
                key: 'series',
                not_must: true
              },
              {
                label: '车型',
                key: 'models',
                
              },
              {
                label: '底盘号',
                key: 'chassis_code',
                not_must: true
              },
              {
                label: '年份',
                key: 'produced_year',
              },
              {
                label: '发动机型号',
                key: 'engine_model',
              },
              {
                label: '排量',
                key: 'displacement',
              },
              {
                label: '燃油方式',
                key: 'fuel_type',
              },
              {
                label: '变速箱类型',
                key: 'transmission_description',
              },
              {
                label: '驱动方式',
                key: 'drive_mode',
              },
            ].map((item: any, index: number) => {
              let key: string = item.key
              if(item.type === 'select') {
                return (
                  <div className="flex-s-c mt10" key={index}>
                    <label className="right" style={{ width: 120 }}>{!item.not_must ? <span className='red'>* </span> : null}{item.label}：</label>
                    <Select
                      style={{ flex: 1 }}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      fieldNames={{ label: 'label', value: 'label' }}
                      options={brandsList}
                      value={activeItem[key] || undefined}
                      onChange={(value: any) => setActiveItem({...activeItem, brand: value})}
                    />
                  </div>
                )
              }
              return (
                <div className="flex-s-c mt10" key={index}>
                  <label className="right" style={{ width: 120 }}>{!item.not_must ? <span className='red'>* </span> : null}{item.label}：</label>
                  <Input 
                    style={{ flex: 1 }}
                    value={activeItem[key] || undefined}
                    onChange={(e: any) => setActiveItem({...activeItem, [key]: e.target.value})}
                  />
                </div>
              )
            })
          }
        </div>
      </Modal>
    </div>
  )
}

export default Smodel