import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, message, Popconfirm } from 'antd'
import accountApi from '@/api/accountApi'
import context from '@/context/MainContext'




const User: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { store } = useContext(context)
  const { writeAuth } = store
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [keyword, setKeyword] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [pwdOpen, setPwdOpen] = useState<boolean>(false)
  
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [roleOptions, setRoleOptions] = useState<any>([])

  useEffect(() => {
    getRoleList()
  },[])

  useEffect(() => {
    getList()
  },[keyword, pager, pageSize])

  const getList = () => {
    let params = {
      search_key: keyword,
      page: pager.page,
      pagesize: pageSize
    }
    setLoading(true)
    accountApi.getUserList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.records ? res.data.records : [])
      }
    })
  }
  const getRoleList = () => {
    accountApi.getRoleList({ pagesize: 100000 }).then((res: any) => {
      if(res.code === 1) {
        setRoleOptions(res.data.records ? res.data.records : [])
      }
    })
  }

  const editItem = (item: any, type?: string) => {
    setActiveItem({...item})
    setOpen(true)
  }

  const submit = (record?: any) => {
    let params = {
      edit_id: record?.uid || activeItem.uid || undefined,
      username: record?.username || activeItem.username,
      nickname: record?.nickname || activeItem.nickname,
      remark: record?.remark || activeItem.remark,
      password: record ? '123456' : undefined,
      role_id: record?.role_id || activeItem.role_id || undefined
    }
    if(params.edit_id) {
      params.edit_id = params.edit_id + ''
    }
    setSubmitLoading(true)
    accountApi.updateUser(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功")
        setOpen(false)
        if(!record) getList()
      }
    })
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '账号',
      dataIndex: 'username',
      align: 'center',
    },
    {
      title: '名称',
      dataIndex: 'nickname',
      align: 'center'
    },
    {
      title: '角色 (权限)',
      dataIndex: 'role_name',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>{text || '-'}</div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 220,
      render: (text: any, record: any, index: number) => {
        if(!writeAuth[pathname]) return null
        return (
          <div className="flex-c-c">
            <div className="flex-c-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
            <Popconfirm
              title="确定重置此账号的密码吗？"
              okButtonProps={{
                loading: submitLoading
              }}
              onConfirm={() => submit(record)}
            >
              <div className="flex-c-c d-color pointer mlr20" >
                <i className="iconfont icon-zhongzhimima mr5 fs14" />
                重置密码
              </div>
            </Popconfirm>
          </div>
        )
      }
    },
  ]
  
  return (
    <div className={'bgc-fff p15 br4 min-content-height1' } >
      <div className='flex-b-c'>
        <Input.Search placeholder="输入账号/名称" style={{ width: 220 }} onSearch={(value: any) => { setPager({page: 1}); setKeyword(value) }}  />
        {
          writeAuth[pathname] ? (
            <Button onClick={() => { setActiveItem({}); setOpen(true); }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />} >添加用户</Button>
          ): null
        }
      </div>
      <div className="mt10">
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pager.page,
            total: pager.total,
            pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current: any, size: any) => setPageSize(size),
            onChange: (page: any) => setPager({...pager, page}),
            showTotal: (total: number) => `共 ${total} 条`
          }}
        />
      </div>

      <Modal
        title={activeItem.id ? '编辑' : '添加'}
        open={open}
        width={460}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={() => submit()}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}>账号：</label>
            <Input
              style={{ flex: 1 }}
              value={activeItem.username}
              onChange={(e: any) => setActiveItem({ ...activeItem, username: e.target.value })}
              disabled={activeItem.id}
            />
          </div>
          {
            !activeItem.id ? (
              <div className="flex-s-c mt5">
                <label className="right" style={{ width: 100, opacity: 0 }}>账号：</label>
                <span style={{ opacity: .7, fontSize: 12 }}>初始密码为：账号后6位➕###</span>
              </div>
            ): null
          }
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}>名称：</label>
            <Input
              style={{ flex: 1 }}
              value={activeItem.nickname}
              onChange={(e: any) => setActiveItem({ ...activeItem, nickname: e.target.value })}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}>角色 (权限)：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择"
              options={roleOptions}
              fieldNames={{ label: 'role_name', value: 'id' }}
              value={activeItem.role_id || undefined}
              onChange={(value: any) => setActiveItem({ ...activeItem, role_id: value })}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}>备注：</label>
            <Input
              style={{ flex: 1 }}
              value={activeItem.remark}
              onChange={(e: any) => setActiveItem({ ...activeItem, remark: e.target.value })}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default User