import React, { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'
import { getUrlParams } from '@/utils/utils'

import styles from './index.module.scss'

const Breadcrumb: React.FC<{update?: Function}> = (props) => {
  const { store, setStore } = useContext(MainContext)
  const { crumb } = store
  const navigate = useNavigate()
  const { vin, brandcode, mcid } = getUrlParams()

  const reSelected = (item: any, index: number) => {
    let url = ''
    //catalog
    // console.info(item.type)
    // return
    if(vin){
      if(item.type === 'group'){
        url = `/dataedit/vin?vin=${vin}&samecrumb=true`
      }
      if(item.type === 'subGroup'){
        url = `/dataedit/vin?vin=${vin}&num=${item.num}&samecrumb=true`
      }
      if(item.type === 'detail'){
        if(item.index || item.index === 0){
          url = `/dataedit/vin?vin=${vin}&num=${item.num}&index=${item.index}&samecrumb=true`
        }else {
          url = `/dataedit/vin?vin=${vin}&num=${item.num}&samecrumb=true`
        }
      }
    }else {
      if(item.type === 'group'){
        url = `/search`
        // reset()
        // return
      }
      if(item.type === 'subGroup'){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&samecrumb=true`
      }
      if(item.type === 'detail'){
        if(item.index || item.index === 0){
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&index=${item.index}&samecrumb=true`
        }else {
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&samecrumb=true`
        }
      }
    }

    let groupCrumb = window.sessionStorage.getItem('groupCrumb') || '[]'
    window.sessionStorage.setItem('crumb', groupCrumb)

    crumb.splice(index, crumb.length - index)
    setStore({value: crumb, type: 'crumb'})
    if(props.update){
      props.update()
    }
    navigate(url)
  }

  return (
    <div className={styles.crumb}>
      {
        crumb[0]?.type === 'logo' ? <img src={crumb[0].img} /> : null
      }
      <ul>
        {
          crumb.map((item: any, index: number)=>{

            return (
              item.type !== 'logo' ? (
                <Fragment key={index}>
                  {/* <li>
                    {
                      item.name?.length > 36 ? (
                        <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_name || item.name} overlayStyle={{width: 222}} >
                          <span>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                        </Tooltip>
                      ): (
                        <>{item.show_name || item.name}</>
                      )
                    }
                  </li>
                  {
                    crumb.length !== index + 1 ? <li>&gt;</li> : null
                  } */}


                  {
                    item.type === 'config' || index === 1 ? (
                      item.name?.length > 36 ? (
                        <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_name || item.name} overlayStyle={{width: 222}} >
                          <span>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                        </Tooltip>
                      ): (
                        <>{item.show_name || item.name}</>
                      )
                    ): (
                      <span onClick={()=>reSelected(item, index)} className={styles.selected}>
                        {
                          item.name?.length > 36 ? (
                            <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_name || item.name} overlayStyle={{width: 222}} >
                              <span>{item.mid || item.num} {item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                            </Tooltip>
                          ): (
                            <>{item.mid || item.num} {item.show_name || item.name || item.standard_subgroup_name}</>
                          )
                        }
                      </span>
                    )
                  }
                  {/* { 
                    item.type === 'config' || index === 1 ? (
                      <span>{item.show_name || item.name} </span>
                    ): null 
                  } */}
                  {
                    index !== crumb.length - 1 ? <i>&gt;</i> : null
                  }
                </Fragment>
              ): null
            )
          })
        }
      </ul>
    </div>
  )
}

export default Breadcrumb;