import React, { useState, useReducer, useEffect, useContext, useRef } from 'react'
import { Input, Button } from 'antd'
import accountApi from '@/api/accountApi'

import accountScss from './account.module.scss'

let initAccount: any = {}

const Login: React.FC = () => {
  const [loginLoading, setLoginLoading] = useState<boolean>(false)

  const [account, setAccount] = useState<any>({
    username: '',
    password: ''
  })

  const loginRef = useRef<any>(null)

  useEffect(() => {
    if(loginRef.current) {
      loginRef.current.onkeydown = (e: any) => {
        if(e.which === 13) {
          login()
        }
      }
    }
    return () => {
      initAccount = {}
      loginRef.current = null
    }
  },[])

  useEffect(() => {
    initAccount = account
  },[account])

  const login = () => {
    let params = {
      ...initAccount
    }
    setLoginLoading(true)
    accountApi.login(params).then((res: any) => {
      setLoginLoading(false)
      if(res.code === 1) {
        localStorage.setItem("token", res.data.token)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        window.location.href = '/home'
      }
    }).catch((err: any) => {
      setLoginLoading(false)
    })
  }

  return (
    <div ref={loginRef} className={accountScss.login} style={{backgroundImage: 'url(/admin/image/bg_1.png)'}}>
      <div className={accountScss.box}>
        <img src='/admin/image/logo_login.png' />
        <Input value={account.username} onChange={(e: any) => setAccount({ ...account, username: e.target.value })} className={accountScss.input} size="large" placeholder="帐号" prefix={<span className='iconfont icon-zhanghao'></span>} />
        <Input.Password value={account.password} onChange={(e: any) => setAccount({ ...account, password: e.target.value })} className={accountScss.input} size="large" placeholder="密码" prefix={<span className='iconfont icon-mima'></span>} />
        <Button loading={loginLoading} onClick={() => login()} className={accountScss.button} size="large" type="primary" block>登录</Button>
      </div>
      <div className={accountScss.footer}>
      Copyright © 2016-2024 007database.com 杭州云汽配配科技有限公司 版权所有 <a href="http://beian.miit.gov.cn" target="_blank">浙ICP备17026959号-16</a>
      </div>
    </div>
  )
}

export default Login