import { Button, Input, message, Modal, Select, Spin, AutoComplete } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import axios from 'axios'
import namestdApi from '@/api/namestdApi'

const { confirm } = Modal


const CancelToken = axios.CancelToken
const controller = new AbortController();

let indexObj: any = {}
let timeout: any = null
let activeIndex: any = -1

const Topname: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [activeItem, setActiveItem] = useState<any>({})

  const [standardOption, setStandardOption] = useState<any>([])

  const [nameOptions, setNameOptions] = useState<any>([])

  const [attrList, setAttrList] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})
  let getCancel = useRef<any>(null)

  let header = [
    {
      label: '标准名称',
      key: 'standlabel'
    },
    {
      label: '位置信息',
      key: 'pos'
    },
    {
      label: '别名',
      key: 'name'
    },
    {
      label: '属性',
      key: 'carsrule'
    },
    {
      label: '备注',
      key: 'remark'
    },
    {
      label: '操作',
      key: 'operate'
    },
    {
      label: 'uuid',
      key: 'uuid'
    },
  ]

  
  

  useEffect(() => {
    initExcel()
    getSelectStandardlist()

    dataApi.getAttrlist().then((res: any) => {
      if(res.code === 1) {
        let data = res.data
        let _options = Object.keys(data).map((key: string) => {
          return {
            label: data[key],
            value: data[key]
          }
        })
        setAttrList([..._options])
      }
    })

    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      getList()
    }
  },[])

  const searchNameList = (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
    const getCompanyOption = () => {
      namestdApi.getSelectNamelist({
        cancelToken: rToken,
        signal: controller.signal,
        name: value
      }).then((res: any) => {
        if(res.code === 1) {
          let _list = res.data.map((item: any) => {
            return {
              label: item,
              value: item
            }
          })
          setNameOptions(_list)
        }
      })
    }
    if (value) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setNameOptions([]);
    }
  }

  const getSelectStandardlist = () => {
    namestdApi.getSelectStandardlist({name: '123'}).then((res: any) => {
      if(res.code === 1) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.standlabel,
            value: item.standlabel,
            ...item
          }
          
        })
        setStandardOption(_list)
      }
    })
    return false
  }


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: header.length - 1,
        width: 1200 / (header.length - 1)
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    
    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let uuid = rowData[indexObj['uuid']] ? rowData[indexObj['uuid']].text : ''
        if(uuid) {
          if(ci === indexObj['operate']) {
            confirm({
              content: '确认删除此条数据吗？',
              onOk() {
                namestdApi.delTopItem({uuid: parseInt(uuid)}).then((res: any) => {
                  if(res.code === 1) {
                    message.success("删除成功！")
                    getList()
                  }
                })
              }
            })
            // 删除
            
          }else {
            let standlabel = rowData[indexObj['standlabel']] ? rowData[indexObj['standlabel']].text : ''
            let name = rowData[indexObj['name']] ? rowData[indexObj['name']].text : ''
            let pos = rowData[indexObj['pos']] ? rowData[indexObj['pos']].text : ''
            let remark = rowData[indexObj['remark']] ? rowData[indexObj['remark']].text : ''
            let carsrule = rowData[indexObj['carsrule']]?.text || ''
            setActiveItem({
              standlabel,
              name,
              pos,
              remark,
              uuid,
              carsrule
            })
            activeIndex = ri
 
            setOpen(true)
          }
        }
        
      }
      
    })

    // 编辑输入
    jexcelRef.current.on("cell-blur", (text: any, ri: any, ci: any) => {
      if(!ri) return false
      console.log(text, ri, ci)
      // let key = Object.keys(indexObj).filter((it: any) => indexObj[it] === ci)[0] || ''
      // let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      // rowData = Object.values(rowData)
      // let params: any = {}
      // Object.keys(indexObj).forEach((k: string) => {
      //   params[k] = rowData[indexObj[k]]?.text || ''
      // })
      // delete params['operate']
      // if(key) {
      //   params[key] = text
      //   submit(params)
      // } 
      
    })
    
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers = header) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        let editable = true
        let styleObj = {}
        if(item.key === 'operate') {
          editable = false
          styleObj = {style: 2}
          text = '删除'
        }
        cells.push({
          text,
          editable,
          ...styleObj
        })
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    setLoading(true)
    namestdApi.getTopList().then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        initData(res.data)
      }
    })
  }


  const submit = (inputItem?: any) => {
    let item = inputItem || activeItem
    let api: any
    if(!item.standlabel) {
      message.warning("请输入标准名称！")
      return false
    }
    if(item.uuid) {
      item.uuid = parseInt(item.uuid)
      api = namestdApi.updateTopItem(item)
    }else {
      api = namestdApi.addTopItem(item)
    }
    setSubmitLoading(true)
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        if(item.uuid) {
          let { uuid, standlabel, name, pos, remark, carsrule } = item
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['uuid'], {
            text: uuid,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['standlabel'], {
            text: standlabel,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['name'], {
            text: name,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['pos'], {
            text: pos,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['remark'], {
            text: remark,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['carsrule'], {
            text: carsrule,
          }, 'all')
          jexcelRef.current.reRender()
        }else {
          getList()
        }
        setOpen(false)
        message.success("操作成功！")
      }
    })
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div >
          <Button onClick={() => { setOpen(true); setActiveItem({}) }} type="primary" style={{ position: 'absolute', right: 5, top: 5 }}>添加标准名称</Button>
          <div className='mt10' style={{ height: 'calc(100vh - 100px)' }} ref={jexcelDivRef} id={pathname}></div>
        </div>
      </Spin>
      

      <Modal
        title={`${activeItem.uuid ? '修改' : '添加'}标准名称`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => submit()}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className='mtb20'>
          <div className='flex-s-c'>
            <label className='right' style={{ width: 100 }}><span className='red'>*</span>标准名称：</label>
            <AutoComplete 
              showSearch
              style={{ flex: 1 }} 
              value={activeItem.standlabel} 
              options={
                standardOption
              }
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value: any, option: any) => setActiveItem({...activeItem, standlabel: value, pos: option.pos})} 
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>位置信息：</label>
            <Input style={{ flex: 1 }} value={activeItem.pos} onChange={(e: any) => setActiveItem({...activeItem, pos: e.target.value})} />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>别名：</label>
            <AutoComplete 
              style={{ flex: 1 }} 
              value={activeItem.name} 
              options={
                nameOptions
              }
              showSearch
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              placeholder="输入名称搜索选择"
              onSearch={(value: any) => searchNameList(value)}
              onChange={(value: any) => setActiveItem({...activeItem, name: value})} 
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>属性：</label>
            <Select
              mode="tags"
              style={{ flex: 1 }} 
              placeholder="属性"
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={attrList}
              value={activeItem.carsrule ? activeItem.carsrule.split(",") : undefined}
              onChange={(value: any) => setActiveItem({...activeItem, carsrule: value ? value.join(",") : ''})}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>备注：</label>
            <Input style={{ flex: 1 }} value={activeItem.remark} onChange={(e: any) => setActiveItem({...activeItem, remark: e.target.value})} />
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default Topname









