import React, { useState } from 'react'
import { VinDetail } from '@/page/Dataedit/Vin'
import { getUrlParams } from '@/utils/utils'

const VinDetailPage: React.FC = () => {
  const [ key, setKey ] = useState<number>(1)
  const { vin, brandcode, mcid, num, index, subindex, finalid } = getUrlParams()
  const update = () => {
    setKey(key+1)
  }
  return (
    <>
      <VinDetail 
        key={key} 
        update={update} 
        vin={vin}
        brandcode={brandcode}
        mcid={mcid}
        num={num}
        index={index}
        subindex={subindex}
        finalid={finalid}
      />
    </>
    
  )
}

export default VinDetailPage;