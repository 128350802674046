import React, {} from 'react'
import styles from './copy.module.scss'
import Toast from '@/components/Toast'
import { useIntl } from 'react-intl'

interface Iprops {
  title: any,
  icon?: boolean,
  noTips?: boolean,
  noTitle?: boolean
}

const Copy: React.FC<Iprops> = (props) => {
  const intl = useIntl()
  const { icon = false, title, noTips = false, noTitle = false } = props
  const copy = (e: any) => {
    if(noTitle){
      e.stopPropagation()
    }
    let oDiv = document.createElement('textarea')
    oDiv.value = title
    oDiv.innerHTML = title
    document.body.appendChild(oDiv)
    oDiv.select()
    document.execCommand("Copy")
    document.body.removeChild(oDiv)
    if(!noTips){
      Toast(intl.formatMessage({ id: 'copySuccess' }))
    }
    
  }
  return (
    <span style={{cursor: 'pointer'}} onClick={(e) => copy(e)}>{noTitle ? '' : title} {icon ? <i className="iconfont icon-copy" style={{fontSize: 12}} /> : null}</span>
  )
}

export default Copy

