import React, { useEffect, useState } from "react";
import { Image, Spin } from "antd";
import { getUrlParams } from '@/utils/utils'
import dataOperationApi from "@/api/dataOperationApi";


const Otest: React.FC<any> = (props) => {
  const { docData } = props
  const { uuid } = getUrlParams()

  const [loading, setLoading] = useState<any>(false)
  const [data, setData] = useState<any>(docData || {})

  useEffect(() => {
    if(uuid) {
      getData()
    }
  },[uuid])

  const getData = () => {
    let params = {
      uuid
    }
    setLoading(true)
    dataOperationApi.showdoc(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        setData(res.data)
      }
    })
  }

  const contents: any = data.data?.content
  const contentsSort: any = data?.data?.sort
  const titles: any = data.data?.title
  return (
    <div className="bgc-fff">
      <Spin spinning={loading}></Spin>
      {
        contents && contentsSort.map((key: string) => {
          let itemTitle: any = contents[key].title
          let itemTable: any = contents[key].table
          let items = contents[key]
          if(!items) return null
          return (
            <>
              <h2 className="center mtb10" >{titles[key]}</h2>
              {
                items.map((item: any, index: number) => {
                  let itemTitle: any = item.title
                  let itemTable: any = item.table
                  // console.log(itemTitle, itemTable)
                  return (
                    <div>
                      <div  className="mtb10" key={index}>
                        {
                          itemTitle.map((titles: any) => <div  >
                            {
                              titles.map((title: any) => title && title.includes("<img>") ? <Image src={title.split("<img>")[1]} /> : title)
                            }
                          </div>)
                        }
                      </div>
                      <div style={{ width: '100%' }}>
                        {
                          itemTable.length ? itemTable.filter((k: string) => k !== 'sort').map((cols: any) => {
                            // console.log(cols)
                            let col: any = []
                            let sort = cols.sort || []
                            let len = sort.length
                            for(let i = 0; i < len; i++) {
                              let rowKey = sort[i]
                              col.push(cols[rowKey])
                            }
                            // console.log(col)
                            return (
                              <div className="center flex-s-c" style={{  width: (100 / itemTable.length) + '%' }}>
                                {
                                  col.map((rows: any) => {
                                    return (
                                      <div style={{ width: (100 / col.length) + '%' }}>
                                        {
                                          Object.values(rows).map((row: any) => {
                                            console.log(row)
                                            let texts = row.filter((it: any, i: number) => i)
                                            let borderLeft, borderRight, borderTop, borderBottom
                                            let borderData =  row[0].split(",")
                                            borderTop = borderData[0] + 'px solid #000'
                                            borderLeft = borderData[1] + 'px solid #000'
                                            borderRight = borderData[2] + 'px solid #000'
                                            borderBottom = borderData[3] + 'px solid #000'
                                            // console.log(borderTop, borderLeft, borderRight, borderBottom)
                                            return (
                                              <div 
                                                className="center flex-c-c" 
                                                style={{ 
                                                  height: 80, 
                                                  overflow: 'hidden',  
                                                  borderTop,
                                                  borderLeft,
                                                  borderRight,
                                                  borderBottom
                                                }}
                                              >
                                                {
                                                  texts.map((text: any) => {
                                                    if(text && text.indexOf("<doc>") === 0) {
                                                      let uuid = text.split("<doc>")[1]
                                                      return <a href={`/otest?uuid=${uuid}`} target="__blank" >{text}</a>
                                                    }
                                                    return (
                                                      text
                                                    )
                                                  })
                                                }
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                      
                                    )
                                  })
                                }
                              </div>
                            )
                          }): null
                        }
                      </div>

                    </div>
                  )
                })
              }
            </>
            
          )
        })
      }
    </div>
  )
}

export default Otest