import React, { Component, Fragment } from 'react'
import { Popover, Row, Col, Select, Button, Checkbox, Table, message, Popconfirm, Modal, Input, AutoComplete } from 'antd'
import dataApi from '@/api/dataApi'


const screen = [
  {
    key: '零件数量',
    value: 'pid_count'
  },
  {
    key: '标准名称',
    value: 'standard_label'
  },
  {
    key: '地区',
    value: 'market'
  },
  {
    key: '厂家',
    value: 'manufacturers'
  },
  {
    key: '品牌',
    value: 'brand'
  },
  {
    key: '车型',
    value: 'models'
  },
  {
    key: '底盘号',
    value: 'chassis_code'
  },
  {
    key: '年份',
    value: 'produced_year'
  },
  {
    key: '发动机码',
    value: 'engine_model'
  },
  {
    key: '排量',
    value: 'displacement'
  },
  {
    key: '燃油方式',
    value: 'fuel_type'
  },
  {
    key: '变速箱类型',
    value: 'transmission_description'
  },
  {
    key: '驱动类型',
    value: 'drive_mode'
  }
]

const partFilt = [
  {
    key: 'sa',
    value: 'sa'
  },
  {
    key: '车型',
    value: 'cars'
  },
  {
    key: '时间',
    value: 'date'
  },
  {
    key: 'vin码段',
    value: 'vin'
  }
]

class StandardSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      selectData: {
        is_end: 0,
        standard_label: '散热器'
      },
      fieldData: [],
      data: [],
      page: {
        current: 1,
        pageSize: 50,
        total: 0
      },
      pidVisible: false,
      pidLoading: false,
      pidActive: {},
      vinLoading: false,
      vinVisible: false,
      vinListVisible: false,
      pidListVisible: false,
      selectedRowKeys: [],
      pidSelectedRowKeys: [],
      cartData: [],
      cartCodeVisible: false,
      addCartCodeLoading: false,
      partFiltActive: {extra_info: {}},
      partFiltVisible: false,
      partFiltIndex: -1
    }
    this.columns = [
      {
        title: 'mcid',
        dataIndex: 'mcid',
        fixed: 'left',
        width: 100,
        render: (text, item, index)=>{
          return <Button type='link' onClick={()=>this.setSelectData('all', item)}>{text}</Button>
        }
      },
      {
        title: 'vin',
        dataIndex: 'vin_data',
        fixed: 'left',
        width: 180,
        render: (text, item, index)=>{
          let name = []
          let pop = []
          if(text && text.length){
            text.forEach((t, i)=>{
              if(i < 5){
                name.push(t.vin)
              }
              pop.push(t.vin)
            })
          }
          return (
            <Popover content={(pop.join('、'))}>
              <span style={{color: '#1890FF', cursor: 'pointer'}} onClick={()=>this.showListVin(item)}>{name.join('、')}</span>
            </Popover>
            // text && text.length ? (

            //   <Button type='link' size='small' onClick={()=>this.showListVin(item)}>查看</Button>
            // ): null
            // text && !item.edit ? (
            //   <span>{text}</span>
            // ): (
            //   <Input.Search
            //     size='small'
            //     placeholder={text}
            //     onSearch={()=>this.submitVin(index)}
            //     onChange={(e)=>this.setVin('vin', index, e.target.value)}
            //     value={item.vin}
            //     style={{ width: 200 }}
            //     enterButton={item.vin ? item.vin.length : "增"}
            //     loading={item.loading}
            //   />
            // )
            
          )
        }
      },
      {
        title: '车型编码',
        dataIndex: 'car_code',
        fixed: 'left',
        width: 100,
        render: (text, item, index)=>{
          let name = []
          let pop = []
          if(text && text.length){
            text.forEach((t, i)=>{
              if(i < 5){
                name.push(t)
              }
              pop.push(t)
            })
          }
          return (
            text && text.length ? (
              <Popover content={(pop.join('、'))}>
                <span style={{color: '#1890FF', cursor: 'pointer'}} onClick={()=>this.showCartCode(item, index)}>{name.join('、')}</span>
              </Popover>
            ): (
              <span style={{color: '#1890FF', cursor: 'pointer'}} onClick={()=>this.showCartCode(item, index)}>-</span>
            )
            
          )
        }
      },
      {
        title: 'pid',
        dataIndex: 'rep_data',
        fixed: 'left',
        width: 140,
        render: (text, item, index)=>{
          let name = []
          let pop = []
          if(text && text.length){
            text.forEach((t, i)=>{
              if(i < 5){
                name.push(t)
              }
              pop.push(t)
            })
          }
          return (
            <Popover content={(pop.join('、'))}>
              <span style={{color: '#1890FF', cursor: 'pointer'}} onClick={()=>this.showListPid(item, index)}>{name.join('、')}</span>
            </Popover>
            // text && text.length ? (
            //   <Button type='link' size='small' onClick={()=>this.showListPid(item)}>查看</Button>
            // ): null
          )
        }
      },
      // {
      //   title: '位置',
      //   dataIndex: 'std_remark',
      //   fixed: 'left',
      //   width: 100,
      //   render: (text, item, index)=>{
      //     return (
      //       <span className='edit'>{text} <span className='iconfont iconxitongguanli' onClick={()=>this.positionVin(item)}></span></span>            
      //     )
      //   }
      // }
    ]

    screen.forEach((item)=>{
      this.columns.push({
        title: item.key,
        dataIndex: item.value,
        width: 100,
      })
    })
    this.columns.push({
      title: '操作',
      dataIndex: 'pid',
      fixed: 'right',
      width: 120,
      render: (text, item, index)=>{
        return (
          <>
            {/* <Popconfirm
              title={`确定停用${text}?`}
              onConfirm={()=>this.del(item)}
            >
              <Button type='danger' size='small'>停</Button>
            </Popconfirm> */}
            <Button size='small' onClick={()=>this.setPidVisible(item, index)}>补</Button>
            <Button size='small' onClick={()=>this.setcartVisible(item, index)}>车</Button>
            {
              item.is_end === 0 ? (
                <Popconfirm
                  title={`确定完成${item.mcid}?`}
                  onConfirm={()=>this.complete(item, index)}
                >
                  <Button type='primary' size='small'>完</Button>
                </Popconfirm>
              ): null
            }
          </>
        )
      }
    })
    this.vinColumns = [
      {
        title: 'vin',
        dataIndex: 'vin'
      },
      {
        title: '配置信息',
        dataIndex: 'remark',
        render: (text, item, index)=>{
          return (
            <Input.Search
              size='small'
              onSearch={()=>this.submitRemark(index)}
              onChange={(e)=>this.setListVin('remark', index, e.target.value)}
              value={text}
              style={{ width: 200 }}
              enterButton="修改"
              loading={item.loading}
            />
          )
        }
      },
      {
        title: '操作',
        dataIndex: 'vin',
        render: (text, item, index)=>{
          return (
            <Popconfirm
              title={`确定删除${text}?`}
              onConfirm={()=>this.delListVin(index)}
            >
              <Button type='danger' size='small'>删除</Button>
            </Popconfirm>
          )
        }
      }
    ]
    this.pidColumns = [
      {
        title: 'rep_pid',
        dataIndex: 'rep_pid',
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {
              rowSpan: row.rowSpan
            }
          }
          return obj
        }
      },
      {
        title: 'pid',
        dataIndex: 'pid'
      },
      {
        title: '标准名称',
        dataIndex: 'standard_label'
      },
      {
        title: '位置信息',
        dataIndex: 'std_remark',
        render: (text, item, index)=>{
          return (
            <Input.Search
              size='small'
              onSearch={()=>this.submitStdRemark(index)}
              onChange={(e)=>this.setListPid('std_remark', index, e.target.value)}
              value={text}
              style={{ width: 200 }}
              enterButton="修改"
              loading={item.loading}
            />
          )
        }
      },
      {
        title: '操作',
        dataIndex: 'pid',
        render: (text, item, index)=>{
          return (
            <>
              <Button type='primary' size='small' onClick={()=>this.showPartFiltActive(item, index)}>过滤</Button>
              <Popconfirm
                title={`确定停用${text}?`}
                onConfirm={()=>this.delListPid(index)}
              >
                <Button type='danger' size='small'>停用</Button>
              </Popconfirm>
            </>
            
          )
        }
      }
    ]
    this.cartCodeColumns = [
      {
        title: 'code',
        dataIndex: 'code'
      },
      {
        title: '操作',
        dataIndex: 'code',
        render: (text, item, index)=>{
          return (
            <Popconfirm
              title={`确定删除${text}?`}
              onConfirm={()=>this.delCartCode(index)}
            >
              <Button type='danger' size='small'>删除</Button>
            </Popconfirm>
          )
        }
      }
    ]
    this.selectedRow = []
    this.pidActiveIndex = -1
  }
  componentDidMount() {
  }
  getField = (query_field) => {
    let param = JSON.parse(JSON.stringify(this.state.selectData))
    delete param[query_field]
    param.query_field = query_field
    this.setState({
      loading: true,
      fieldData: ['正在查询...']
    })
    dataApi.getField(param).then((result)=>{
      this.setState({
        loading: false
      })
      if(result.code === 1){
        result.data.forEach((item, i)=>{
          result.data[i] = item.toString()
        })
      }
      this.setState({
        fieldData: result.data || []
      })
    })
  }
  setSelectData = (type, value) => {
    let temp = JSON.parse(JSON.stringify(this.state.selectData))
    if(type === 'all'){
      let v = JSON.parse(JSON.stringify(value))
      delete v.mcid
      delete v.part_data
      delete v.vin_data
      temp = v
    }else {
      temp[type] = value
      if(!type && !value){
        temp = {
          is_end: 0,
          standard_label: '散热器'
        }
      }
    }
    
    this.setState({
      selectData: temp
    })
  }
  getData = () => {
    let { selectData, page } = this.state
    let param = JSON.parse(JSON.stringify(selectData))
    param.page = page.current
    param.page_size = page.pageSize
    this.setState({
      dataLoading: true,
      data: []
    })
    dataApi.getData(param).then((result)=>{
      this.setState({
        dataLoading: false
      })
      if(result.code === 1){
        let p = JSON.parse(JSON.stringify(page))
        p.total = result.data.total
        
        result.data.data.forEach((item)=>{
          item.part_data.sort((a,b) => {
            return a.rep_pid.localeCompare(b.rep_pid)
          })
        })
        result.data.data.forEach((item)=>{
          item.part_data.forEach((d, s)=>{
            if(!d.rowSpan && d.rowSpan !== 0){
              let rowSpan = 1
              for(let i=s+1; i < item.part_data.length; i++){
                if(d.rep_pid === item.part_data[i].rep_pid){
                  item.part_data[i].rowSpan = 0
                  rowSpan++
                }
              }
              d.rowSpan = rowSpan
            }
          })
        })
        this.setState({
          data: result.data.data,
          page: p
        })
      }
    })
  }
  pageChange(p, pageSize) {
    let { page } = this.state
    page.current = p
    if(pageSize){
      page.pageSize = pageSize
    }
    
    this.setState({
      page
    }, () => {
      this.getData()
    })
  }
  del = (item) => {
    const param = {
      mcid: item.mcid,
      vin: item.vin,
      pid: item.pid,
      is_use: 0
    }
    this.setState({
      dataLoading: true
    })
    dataApi.updateStatus(param).then((result)=>{
      if(result.code === 1){
        message.success('停用成功！')
        this.getData()
      }else {
        this.setState({
          dataLoading: false
        })
      }
    })
  }
  complete = (item, index) => {
    const { selectedRowKeys } = this.state
    let param = {}
    if(item.mcid){
      param = {
        mcid: item.mcid,
        vin: item.vin,
        standard_label: item.standard_label
      }
    }else {
      param = {
        mcid: selectedRowKeys.join(','),
        vin: '',
        standard_label: this.selectedRow[0].standard_label
      }
    }
    this.setState({
      dataLoading: true
    })
    dataApi.vinUpdate(param).then((result)=>{
      this.setState({
        dataLoading: false
      })
      if(result.code === 1){
        message.success('校验完成！')
        if(item.mcid){
          let { data } = this.state
          let temp = JSON.parse(JSON.stringify(data))
          temp.splice(index, 1)
          this.setState({
            data: temp
          })
        }else {
          this.setState({
            selectedRowKeys: []
          })
          this.selectedRow = []
          this.getData()
        }
      }
    })
  }
  setPidVisible = (item, index) => {
    let { selectData } = this.state
    let temp = JSON.parse(JSON.stringify(selectData))
    const pidActive = {
      mcid: item.mcid,
      vin: '',
      pid: '',
      standard_label: temp.standard_label,
      std_remark: '',
      is_end: temp.is_end
    }
    this.pidActiveIndex = index
    this.setState({
      pidActive,
      pidVisible: true
    })
  }
  setPidActive = (type, value) => {
    const { pidActive } = this.state
    let temp = JSON.parse(JSON.stringify(pidActive))
    temp[type] = value
    this.setState({
      pidActive: temp
    })
  }
  pidOk = () => {
    const { pidActive, data } = this.state
    let temp = JSON.parse(JSON.stringify(data))
    if(pidActive.pid && temp[this.pidActiveIndex].part_data){
      temp[this.pidActiveIndex].part_data.forEach((p)=>{
        if(p.pid === pidActive.pid){
          message.warning('已关联此pid，请重新输入！')
          return
        }
      })
    }
    this.setState({
      pidLoading: true
    })

    dataApi.addPart(pidActive).then((result)=>{
      this.setState({
        pidLoading: false
      })
      if(result.code === 1){
        message.success('添加成功！')
        this.pidCancel()
        let part_data = temp[this.pidActiveIndex].part_data
        let vin_data = temp[this.pidActiveIndex].vin_data
        if(temp[this.pidActiveIndex].standard_label === pidActive.standard_label){
          if(!part_data || (part_data.length === 1 && !part_data[0].pid && !part_data[0].std_remark)){
            temp[this.pidActiveIndex].part_data = []
          }
          if(!vin_data || (vin_data.length === 1 && !vin_data[0].vin && !vin_data[0].remark)){
            temp[this.pidActiveIndex].vin_data = []
          }
          if(pidActive.vin){
            temp[this.pidActiveIndex].vin_data.push({
              vin: pidActive.vin,
              remark: ''
            })
          }
          if(pidActive.pid){
            temp[this.pidActiveIndex].part_data.push({
              pid: pidActive.pid,
              standard_label: pidActive.standard_label,
              std_remark: pidActive.std_remark,
            })
          }
          this.setState({
            data: temp
          })
        }
        
      }
    })
  }
  pidCancel = () => {
    this.setState({
      pidVisible: false
    })
  }
  setVin = (type, index, value) => {
    const { data } = this.state
    let temp = JSON.parse(JSON.stringify(data))
    temp[index][type] = value
    temp[index]['edit'] = value ? true : false
    this.setState({
      data: temp
    })
  }
  submitVin = (index) => {
    const { data } = this.state
    let temp = JSON.parse(JSON.stringify(data))
    let item = temp[index]
    item.loading = true
    this.setState({
      data: temp
    })
    dataApi.addVin({mcid: item.mcid, vin: item.vin}).then((result)=>{
      let te = JSON.parse(JSON.stringify(data))
      te[index].loading = false
      if(result.code === 1){
        delete te[index]['edit']
      }
      this.setState({
        data: te
      })
    })
  }
  editVin = (item) => {
    const pidActive = {
      mcid: item.mcid,	
      vin: item.vin,
      remark: item.remark
    }
    this.setState({
      pidActive,
      vinVisible: true
    })
  }
  vinOk = () => {
    const { pidActive } = this.state
    this.setState({
      pidLoading: true
    })
    dataApi.addVinRemark(pidActive).then((result)=>{
      this.setState({
        pidLoading: false
      })
      if(result.code === 1){
        message.success('添加成功！')
        this.pidCancel()
        this.getData()
      }
    })
  }
  vinCancel = () => {
    this.setState({
      vinVisible: false
    })
  }

  positionVin = (item) => {
    const pidActive = {
      mcid: item.mcid,	
      vin: item.vin,
      pid: item.pid,
      std_remark: item.std_remark
    }
    this.setState({
      pidActive,
      positionVisible: true
    })
  }
  positionCancel = () => {
    this.setState({
      positionVisible: false
    })
  }
  positionOk = () => {
    const { pidActive } = this.state
    this.setState({
      pidLoading: true
    })
    dataApi.addPosition(pidActive).then((result)=>{
      this.setState({
        pidLoading: false
      })
      if(result.code === 1){
        message.success('添加成功！')
        this.positionCancel()
        this.getData()
      }
    })
  }

  showListVin = (item) => {
    this.setState({
      pidActive: item,
      vinListVisible: true
    })
  }
  vinListCancel = () => {
    this.setState({
      vinListVisible: false
    })
  }
  setListVin = (type, index, value) => {
    const { pidActive } = this.state
    let temp = JSON.parse(JSON.stringify(pidActive))
    temp.vin_data[index][type] = value
    this.setState({
      pidActive: temp
    })
  }
  submitRemark  = (index) => {
    const { pidActive } = this.state
    let temp = JSON.parse(JSON.stringify(pidActive))
    let item = temp.vin_data[index]
    item.loading = true
    this.setState({
      pidActive: temp
    })
    dataApi.addVinRemark({mcid: temp.mcid, vin: item.vin, remark: item.remark}).then((result)=>{
      let te = JSON.parse(JSON.stringify(pidActive))
      te.vin_data[index].loading = false
      this.setState({
        pidActive: te
      })
      if(result.code === 1){
        message.success('添加成功！')
      }
    })
  }
  delListVin = (index) => {
    const { pidActive } = this.state
    let item = pidActive.vin_data[index]
    dataApi.vinDel({mcid: pidActive.mcid, vin: item.vin}).then((result)=>{
      let te = JSON.parse(JSON.stringify(pidActive))
      te.vin_data.splice(index, 1)
      this.setState({
        pidActive: te
      })
      if(result.code === 1){
        message.success('删除成功！')
      }
    })
  }
  showListPid  = (item, index) => {
    this.setState({
      pidActive: item,
      pidListVisible: true,
      pidSelectedRowKeys: [],
      pidActiveIndex: index
    })
  }
  setListPid = (type, index, value) => {
    const { pidActive } = this.state
    let temp = JSON.parse(JSON.stringify(pidActive))
    temp.part_data[index][type] = value
    this.setState({
      pidActive: temp
    })
  }
  submitStdRemark = (index) => {
    const { pidActive } = this.state
    let temp = JSON.parse(JSON.stringify(pidActive))
    let item = temp.part_data[index]
    item.loading = true
    this.setState({
      pidActive: temp
    })
    dataApi.addPosition({mcid: temp.mcid, pid: item.pid, std_remark: item.std_remark}).then((result)=>{
      let te = JSON.parse(JSON.stringify(pidActive))
      te.part_data[index].loading = false
      this.setState({
        pidActive: te
      })
      if(result.code === 1){
        message.success('添加成功！')
      }
    })
  }
  delListPid = (index) => {
    const { pidActive, pidSelectedRowKeys, pidActiveIndex, data } = this.state
    
    let param = {}
    if(index === -1){
      param = {
        mcid: pidActive.mcid, 
        pid: pidSelectedRowKeys.join(','), 
        standard_label: pidActive.standard_label
      }
    }else {
      let item = pidActive.part_data[index]
      param = {
        mcid: pidActive.mcid, 
        pid: item.pid, 
        standard_label: 
        item.standard_label
      }
    }
    
    this.setState({
      vinLoading: true
    })
    dataApi.updateStatus(param).then((result)=>{
      this.setState({
        vinLoading: false
      })
      
      if(result.code === 1){
        message.success('停用成功！')
        let te = JSON.parse(JSON.stringify(pidActive))
        let temp = JSON.parse(JSON.stringify(data))
        if(index === -1){
          const s = te.part_data.length - 1
          for(let i = s; i > -1; i--){
            if(pidSelectedRowKeys.indexOf(te.part_data[i].pid) !== -1){
              te.part_data.splice(i, 1)
            }
          }
        }else {
          te.part_data.splice(index, 1)
        }

        te.part_data.forEach((d, s)=>{
          let rowSpan = 1
          if(d.rep_pid){
            for(let i=s+1; i < te.part_data.length; i++){
              if(d.rep_pid === te.part_data[i].rep_pid){
                te.part_data[i].rowSpan = 0
                te.part_data[i].is = true
                rowSpan++
              }
            }
          }
          if(!d.is){
            d.rowSpan = rowSpan
          }else {
            d.is = false
          }
          
        })
        temp[pidActiveIndex].part_data = te.part_data
        this.setState({
          pidActive: te,
          pidSelectedRowKeys: [],
          data: temp
        })
      }
    })
  }
  pidListCancel  = (item) => {
    this.setState({
      pidListVisible: false
    })
  }
  setcartVisible = (item, index) => {
    let temp = JSON.parse(JSON.stringify(screen))
    temp.splice(0, 2)
    temp.forEach((te)=>{
      te.val = item[te.value]
    })
    temp.unshift({
      key: 'mcid',
      value: 'mcid',
      val: item.mcid
    })
    this.setState({
      cartData: temp,
      cartVisible: true
    })
    this.pidActiveIndex = index
  }
  setCartData = (i, value) => {
    const { cartData } = this.state
    let temp = JSON.parse(JSON.stringify(cartData))
    temp[i].val = value
    this.setState({
      cartData: temp
    })
  }

  cartCancel = () => {
    this.setState({
      cartVisible: false
    })
  }
  cartOk  = () => {
    const { cartData, data } = this.state
    let temp = JSON.parse(JSON.stringify(data))
    let param = {}
    cartData.forEach((item)=>{
      temp[this.pidActiveIndex][item.value] = item.val || ''
      param[item.value] = item.val || ''
    })
    this.setState({
      pidLoading: true
    })
    
    dataApi.carsUpdate(param).then((result)=>{
      this.setState({
        pidLoading: false
      })
      if(result.code === 1){
        this.setState({
          data: temp,
          cartVisible: false
        })
        message.success('修改成功！')
      }
    })
  }

  showCartCode = (item, index) => {
    item.carCode = []
    item.car_code.forEach((code)=>{
      item.carCode.push({
        code
      })
    })
    this.setState({
      pidActive: item,
      cartCodeVisible: true,
      pidSelectedRowKeys: [],
      pidActiveIndex: index
    })
  }
  cartCodeOk = () => {
    const { pidSelectedRowKeys } = this.state
    console.info(pidSelectedRowKeys)
  }
  cartCodeCancel = () => {
    this.setState({
      cartCodeVisible: false
    })
  }
  addCartCode = (car_code) => {
    const { pidActive, pidActiveIndex, data } = this.state
    this.setState({
      addCartCodeLoading: true
    })
    dataApi.addCartCode({mcid: pidActive.mcid, car_code}).then((result)=>{
      this.setState({
        addCartCodeLoading: false
      })
      if(result.code === 1){
        let temp = JSON.parse(JSON.stringify(pidActive))
        let tempData = JSON.parse(JSON.stringify(data))
        temp.carCode.push({code: car_code})
        if(!tempData[pidActiveIndex].car_code){
          tempData[pidActiveIndex].car_code = []
        }
        tempData[pidActiveIndex].car_code.push(car_code)
        this.setState({
          pidActive: temp,
          data: tempData
        })
        message.success('增加成功！')
      }
    })
  }
  delCartCode = (i) => {
    const { pidActive, data, pidActiveIndex, pidSelectedRowKeys } = this.state
    let param = {
      mcid: pidActive.mcid,
      car_code: ''
    }
    if(i === -1){
      param.car_code = pidSelectedRowKeys.join(',')
    }else {
      param.car_code = pidActive.car_code[i]
    }
    dataApi.delCartCode(param).then((result)=>{
      if(result.code === 1){
        let temp = JSON.parse(JSON.stringify(pidActive))
        let tempData = JSON.parse(JSON.stringify(data))
        
        if(i === -1){
          const s = tempData[pidActiveIndex].car_code.length - 1
          for(let i = s; i > -1; i--){
            if(pidSelectedRowKeys.indexOf(tempData[pidActiveIndex].car_code[i]) !== -1){
              tempData[pidActiveIndex].car_code.splice(i, 1)
            }
            if(pidSelectedRowKeys.indexOf(temp.carCode[i].code) !== -1){
              temp.carCode.splice(i, 1)
            }
          }
        }else {
          tempData[pidActiveIndex].car_code.splice(i, 1)
          temp.carCode.splice(i, 1)
        }

        this.setState({
          data: tempData,
          pidActive: temp
        })
        message.success('删除成功！')
      }
    })
  }
  showPartFiltActive = (item, index) => {
    if(!item.extra_info){
      item.extra_info = {}
    }
    this.setState({
      partFiltActive: item,
      partFiltIndex: index,
      partFiltVisible: true
    })
  }
  partFiltOk = () => {
    const { partFiltActive, pidActive, partFiltIndex, pidActiveIndex, data } = this.state
    let param = {
      mcid: pidActive.mcid,
      pid: partFiltActive.pid
    }
    param = {...param, ...partFiltActive.extra_info}
    this.setState({
      partFiltLoading: true
    })
    dataApi.updatePartExtra(param).then((result)=>{
      this.setState({
        partFiltLoading: false
      })
      if(result.code === 1){
        let temp = JSON.parse(JSON.stringify(pidActive))
        let tempData = JSON.parse(JSON.stringify(data))
        tempData[pidActiveIndex].part_data[partFiltIndex] = partFiltActive
        temp.part_data[partFiltIndex] = partFiltActive
        this.setState({
          data: tempData,
          pidActive: temp,
          partFiltVisible: false
        })
        message.success('操作成功！')
      }
    })
  }
  partFiltCancel = () => {
    this.setState({
      partFiltVisible: false
    })
  }
  setPartFiltActive = (type, val) => {
    const { partFiltActive } = this.state
    let temp = JSON.parse(JSON.stringify(partFiltActive))
    temp.extra_info[type] = val
    this.setState({
      partFiltActive: temp
    })
  }
  render() {
    const { loading, selectData, fieldData, data, page, dataLoading, pidVisible, pidLoading, pidActive, vinVisible, positionVisible, vinLoading, vinListVisible, pidListVisible, selectedRowKeys, pidSelectedRowKeys, cartData, cartVisible, cartCodeVisible, addCartCodeLoading, partFiltVisible, partFiltActive, partFiltLoading } = this.state
    return (
      <Fragment>
        <div className="message bgc-fff p15 min-content-height1">
          <div className='flex-b-c'>
            <div className='l'>
              {
                screen.map((item)=>{
                  return (
                    <AutoComplete
                      allowClear
                      size='small'
                      style={{ width: 120, margin: '0 6px 6px 0' }} 
                      dataSource={fieldData}
                      placeholder={item.key}
                      value={selectData[item.value]}
                      onFocus={()=>this.getField(item.value)}
                      onChange={(value)=>this.setSelectData(item.value, value)}
                      onBlur={()=>this.setState({fieldData: []})}
                    />
                    // <Select 
                    //   allowClear
                    //   style={{ width: 120, margin: '0 6px 6px 0' }} 
                    //   loading={loading}
                    //   size='small'
                    //   placeholder={item.key}
                    //   onFocus={()=>this.getField(item.value)}
                    //   value={selectData[item.value]}
                    //   onChange={(value)=>this.setSelectData(item.value, value)}
                    // >
                    //   {
                    //     fieldData.map((field)=>{
                    //       return <Select.Option value={field}>{field}</Select.Option>
                    //     })
                    //   }
                    // </Select>
                  )
                })
              }
              <Select 
                allowClear
                style={{ width: 120, margin: '0 6px 6px 0' }} 
                loading={loading}
                size='small'
                placeholder='是否有vin'
                value={selectData.vin_count}
                onChange={(value)=>this.setSelectData('vin_count', value)}
              >
                <Select.Option value={0}>无vin</Select.Option>
                <Select.Option value={1}>有vin</Select.Option>
              </Select>
              <Input size='small' placeholder='vin' style={{ width: 160, marginRight: 10 }}  value={selectData.vin} onChange={(e)=>this.setSelectData('vin', e.target.value)} />
              {/* <Checkbox checked={selectData.vin_count ? true : false} onChange={(e)=>this.setSelectData('vin_count', e.target.checked ? 1 : 0)}>vin为空</Checkbox> */}

              <Checkbox checked={selectData.is_end ? true : false} onChange={(e)=>this.setSelectData('is_end', e.target.checked ? 1 : 0)}>是否校验完成</Checkbox>
              <Button size='small' style={{marginRight: 6}} onClick={()=>this.setSelectData()}>清空</Button>
              <Button type='primary' size='small' onClick={()=>{this.pageChange(1); this.setState({selectedRowKeys: []}); this.selectedRow = []}}>搜索</Button>
            </div>
          </div>
          <div>
            <Popconfirm
              title={`确定完成${selectedRowKeys}?`}
              onConfirm={()=>this.complete({})}
            >
              <Button type='primary' size='small' disabled={!selectedRowKeys.length}>完成</Button>
            </Popconfirm>
          </div>
          <Table 
            columns={this.columns}
            size='small'
            dataSource={data}
            pagination={{
              showQuickJumper: true,
              current: page.current,
              pageSize: page.pageSize,
              total: page.total,
              // onShowSizeChange: (current, pageSize) => this.pageChange(current, pageSize)
            }} 
            onChange={(pagination)=>this.pageChange(pagination.current, pagination.pageSize)}
            bordered
            loading={dataLoading}
            scroll={{ x: 800 }}
            rowKey="mcid"
            rowSelection={{
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  selectedRowKeys: selectedRowKeys
                })
                this.selectedRow = selectedRows
              },
              selectedRowKeys: selectedRowKeys
            }}
          />
        </div>
        <Modal 
          title="补充零件" 
          visible={pidVisible} 
          onOk={()=>this.pidOk()} 
          onCancel={()=>this.pidCancel()} 
          confirmLoading={pidLoading}
        >
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            mcid：
            </Col>
            <Col span={20}>
              {pidActive.mcid}
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            vin：
            </Col>
            <Col span={20}>
              <Input value={pidActive.vin} onChange={(e)=>this.setPidActive('vin', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            pid：
            </Col>
            <Col span={20}>
              <Input value={pidActive.pid} onChange={(e)=>this.setPidActive('pid', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            标准名称：
            </Col>
            <Col span={20}>
            <Input value={pidActive.standard_label} onChange={(e)=>this.setPidActive('standard_label', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            位置信息：
            </Col>
            <Col span={20}>
            <Input value={pidActive.std_remark} onChange={(e)=>this.setPidActive('std_remark', e.target.value)} />
            </Col>
          </Row>
        </Modal>
        <Modal 
          title="车架号配置信息" 
          visible={positionVisible} 
          onOk={()=>this.positionOk()} 
          onCancel={()=>this.positionCancel()} 
          confirmLoading={pidLoading}
        >
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            mcid：
            </Col>
            <Col span={20}>
              {pidActive.mcid}
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            vin：
            </Col>
            <Col span={20}>
              {pidActive.vin}
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            pid：
            </Col>
            <Col span={20}>
              {pidActive.pid}
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            位置：
            </Col>
            <Col span={20}>
              <Input.TextArea value={pidActive.std_remark} onChange={(e)=>this.setPidActive('std_remark', e.target.value)} />
            </Col>
          </Row>
        </Modal>

        <Modal 
          title="vin" 
          visible={vinVisible} 
          onOk={()=>this.vinOk()} 
          onCancel={()=>this.vinCancel()} 
          confirmLoading={pidLoading}
        >
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            mcid：
            </Col>
            <Col span={20}>
              {pidActive.mcid}
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            vin：
            </Col>
            <Col span={20}>
            <Input value={pidActive.vin} onChange={(e)=>this.setPidActive('vin', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            pid：
            </Col>
            <Col span={20}>
              <Input value={pidActive.pid} onChange={(e)=>this.setPidActive('pid', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            标准名称：
            </Col>
            <Col span={20}>
            <Input value={pidActive.standard_label} onChange={(e)=>this.setPidActive('standard_label', e.target.value)} />
            </Col>
          </Row>
          <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
            <Col span={4}>
            位置信息：
            </Col>
            <Col span={20}>
            <Input value={pidActive.std_remark} onChange={(e)=>this.setPidActive('std_remark', e.target.value)} />
            </Col>
          </Row>
        </Modal>

        <Modal 
          title="车架号配置信息" 
          visible={vinListVisible} 
          onOk={()=>this.vinListOk()} 
          onCancel={()=>this.vinListCancel()} 
          footer={null}
          className='vinconfig'
        >
          <Table 
            columns={this.vinColumns}
            size='small'
            dataSource={pidActive.vin_data}  
            pagination={false} 
            bordered
            loading={vinLoading}
          />
        </Modal>

        <Modal 
          title="零件配置信息" 
          width={800}
          visible={pidListVisible} 
          onOk={()=>this.pidListOk()} 
          onCancel={()=>this.pidListCancel()}
          footer={null}
          className='vinconfig'
        >
          <div>
            <Popconfirm
              title={`确定停用?`}
              onConfirm={()=>this.delListPid(-1)}
            >
              <Button type='danger' size='small' disabled={!pidSelectedRowKeys.length}>停用</Button>
            </Popconfirm>
          </div>
          <Table 
            columns={this.pidColumns}
            size='small'
            dataSource={pidActive.part_data}  
            pagination={false} 
            bordered
            loading={vinLoading}
            rowKey="pid"
            rowSelection={{
              onChange: (selectedRowKeys) => {
                this.setState({
                  pidSelectedRowKeys: selectedRowKeys
                })
              },
              selectedRowKeys: pidSelectedRowKeys
            }}
          />
        </Modal>

        <Modal 
          title="车型信息" 
          visible={cartVisible} 
          onOk={()=>this.cartOk()} 
          onCancel={()=>this.cartCancel()} 
          confirmLoading={pidLoading}
        >
          {
            cartData.map((item, index)=>{
              return (
                <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
                  <Col span={4}>
                    {item.key}
                  </Col>
                  <Col span={20}>
                    <Input disabled={!index} value={item.val} onChange={(e)=>this.setCartData(index, e.target.value)} />
                  </Col>
                </Row>
              )
            })
          }
        </Modal>
        
        <Modal 
          title="车型代码" 
          width={400}
          visible={cartCodeVisible} 
          onCancel={()=>this.cartCodeCancel()}
          footer={null}
          className='vinconfig'
        >
          <div style={{marginBottom: 10}}>
            <Popconfirm
              title={`确定停用${pidSelectedRowKeys}?`}
              onConfirm={()=>this.delCartCode(-1)}
            >
              <Button type='danger' size='small' disabled={!pidSelectedRowKeys.length}>停用</Button>
            </Popconfirm>
            <Input.Search
              placeholder="输入车型代码"
              onSearch={value => this.addCartCode(value)}
              style={{ width: 200, marginLeft: 103 }}
              loading={addCartCodeLoading}
              enterButton="新增"
              size='small'
            />
          </div>
          <Table 
            columns={this.cartCodeColumns}
            size='small'
            dataSource={pidActive.carCode}  
            pagination={false} 
            bordered
            loading={vinLoading}
            rowKey="code"
            rowSelection={{
              onChange: (selectedRowKeys) => {
                this.setState({
                  pidSelectedRowKeys: selectedRowKeys
                })
              },
              selectedRowKeys: pidSelectedRowKeys
            }}
          />
        </Modal>

        <Modal 
          title="零件过滤" 
          visible={partFiltVisible} 
          onOk={()=>this.partFiltOk()} 
          onCancel={()=>this.partFiltCancel()} 
          confirmLoading={partFiltLoading}
        >
          {
            partFilt.map((item, index)=>{
              return (
                <Row justify="space-around" align="middle" style={{ marginTop: 10}}>
                  <Col span={4}>
                    {item.key}
                  </Col>
                  <Col span={20}>
                    <Input value={partFiltActive.extra_info[item.value]} onChange={(e)=>this.setPartFiltActive(item.value, e.target.value)} />
                  </Col>
                </Row>
              )
            })
          }
        </Modal>
      </Fragment>
    )
  }
}

export default StandardSold
