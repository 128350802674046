const zh = {
  locale: 'zh-CN',
  messages: {
    'inputVinBrand': '请输入车架号/零件编号/品牌',
    'history': '历史记录',
    'home': '首页',
    'catalog': '车型查询',
    'search': '搜索',
    'dragFile': '粘贴或拖动文件到此区域以进行识别',
    'selectImage': '选择图像',
    'howWorks': '了解它是如何工作的',
    'more': '更多',
    'helpSection': '帮助',
    'freeTrialDay': '免费试用3天',
    'account': '账户',
    'purchase': '购买',
    'message': '消息',
    'favorites': '收藏',
    'feedbackMessage': '反馈消息',
    'watch': '收藏列表',
    'coupon': '优惠券',
    'signOut': '登出',
    'register': '注册',
    'subscribe': '订阅',
    'freeSearch': '免费搜索',
    'inviteTips': '点击按钮邀请10个朋友获得免费年卡',
    'invite': '邀请',
    'freeTrialTips': "要继续使用Partsbond，请开始免费试用",
    'freeTrial': '免费试用',
    'helpVideos': '帮助视频',
    'copyright': 'Copyright@2020 AutoBond Inc.All Rights Reserved',
    'api': 'API',
    'app': 'App',
    'about': '关于我们',
    'contact': '联系我们',
    'and': '和',
    'privacy': '隐私和条款',
    'feedback': '反馈',
    'writeOpinion': '写下你的意见 …',
    'sentUsEmail': '邮箱地址 : aaronw@autobondinc.com',
    'sentUs': '联系我们',
    'iosDownload': 'IOS 下载',
    'androidDownload': 'Android 下载',
    'inputPNMR': '请输入零件号/名称/型号/备注',
    'view3D': '点击查看3D模型',
    'again': '再一次',
    'pictures': '图片',
    'partsNumber': '零件编号',
    'partsName': '零件名称',
    'priceRange': '价格区间',
    'operation': '操作',
    'email': '邮箱',
    'vCode': '验证码',
    'nPassword': '新密码',
    'cNPassword': '确定新密码',
    'submit': '提交',
    'getCode': '获取验证码',
    'countdown': '{num}s',
    'inputEmail': '请输入您的邮箱',
    'emailCorrectly': '请正确填写邮件',
    'inputPassword': '请输入密码',
    'passwordsInconsistent': '输入的两个密码不一致',
    'sendCodeSuccess': '发送成功',
    'agreeUserAgreement': '请同意《用户协议》',
    'tryFree': '免费试用',
    'tryFreeDay': '登录后，点击免费试用获取7天试用',
    'registrationCodeSentSuccess': '注册码发送成功!如果您在1-2分钟内没有收到代码，请尝试刷新您的收件箱和检查您的垃圾邮件文件夹。',
    'findUs': '你怎么找到我们的',
    'iAgree': '我同意',
    'userAgreement': '用户协议',
    'brand': '品牌',
    'sampleSearch': '示例搜索',
    'passwordChangedSuccess': '密码更改成功',
    'passwordChangedFailed': '修改密码失败',
    'enterNewName': '请输入新的用户名',
    'userChangedSuccess': '用户修改成功',
    'userChangedFailed': '修改用户信息失败',
    'fillName': '请先填写用户名',
    'vinBindingSuccee': '车架号绑定成功',
    'success': '成功',
    'brandPackage': '汽车品牌查询套餐',
    'combo': '套餐',
    'validTime': '有效时间',
    'cancelRenewal': '取消更新',
    'packageContent': '套餐',
    'bindFrame': '再绑定1个号',
    'remainingQueries': '剩余查询数',
    'personalInformation': '个人信息',
    'loginAccount': '登录账户',
    'nickName': '别名',
    'modify': '修改',
    'bindingEmail': '绑定邮箱',
    'loginPassword': '登录密码',
    'enterContent': '输入内容',
    'noCoupons': '没有优惠券',
    'discountOperation': '折扣操作',
    'preferentialContent': '优惠内容',
    'serviceLife': '使用寿命',
    'user': '用户',
    'remarks': '备注',
    'noPartsCollection': '没有零件',
    'selectAll': '选择全部',
    'delete': '删除',
    'vin': '车架号',
    'oe': 'OE',
    'detail': '详情',
    'noNews': '没有新的信息',
    'feedbackDetails': '反馈信息',
    'feedbackStatus': '反馈状态',
    'feedbackTime': '反馈时间',
    'questionType': '问题类型',
    'problemDescription': '问题描述',
    'solved': '已解决',
    'pending': '等待',
    'supplementaryQuestion': '补充的问题',
    'endFeedback': '结束反馈',
    'cancel': '取消',
    'invalidCoupon': "没有找到这个折扣代码",
    'bindVin': '绑定 {num} 车架号',
    'annualSubscribe': '年度订阅',
    'currencySymbol': '$',
    'enterNumber': '请依次输入单个号',
    'boundRemaining': '{hasBound}/{remainingBound} 车架号绑定 [{has}/{remaining} 剩下车架号绑定]',
    'boundDescribe1': '多个订阅可以应用于一个帐户',
    'boundDescribe2': '订阅有效期为1个日历年(由购买日期起)',
    'boundDescribe3': 'VIN可以永久绑定到您的帐号;如果您希望稍后绑定您的VIN，您可以在您的帐户详细资料内进行绑定',
    'allInOne': 'ALL-IN-ONE',
    'asiaPack': 'ASIA PACK',
    'euroPack': 'EURO PACK',
    'usPack': 'US PACK',
    'daySubscription': '按天订阅',
    'monthSubscription': '按月订阅',
    'annualSubscription': '按年订阅',
    'use': '使用',
    'howGetCode': '如何获得折扣码',
    'payPalWith': 'PayPal with',
    'openingPermission': '开放权限',
    'availablePackages': '可用套餐',
    'bestMatch': '最佳匹配',
    'priceLowHigh': '价格从低到高',
    'priceHighLow': '价格从高到低',
    'pleaseLoginFirst': '请先登录',
    'here3DArea': '这是3D区域',
    'applicableVehicles': '适用车型',
    'applicableVehicle': '适用于汽车',
    'interchangeableParts': '替换件',
    'condition': '条件',
    'all': '全部',
    'channel': '渠道',
    'location': '位置',
    'partsFound': '个零件',
    'noData': '无数据',
    'carsModels': '车型(年代)',
    'year': '年',
    'market': '市场',
    'oeNumber': 'OE号',
    'partName': '零件名称',
    'count': '数',
    'partsPrice': '零件比价',
    'directReplacement': '直接替换',
    'indirectReplacement': '间接替换',
    'priceTrend': '价格趋势',
    'partPicture': '零件图片',
    'brands': '品牌',
    'replacement': '替换',
    'applicableVeh': '适用Veh',
    'supplier': '供应商',
    'getIt': '获取',
    'go': 'Go',
    'findResults': '为您查找有关{num}的结果',
    'partOe': 'OE',
    'mainGroup': '主组',
    'subGroup': '分组',
    'group': '组',
    'configDetails': '配置详情',
    'selectGroup': '选择组',
    'conflg': '配置',
    'picture': '图片',
    'mg': 'MG',
    'sg': 'SG',
    'illNo': 'ILL-NO',
    'name': '名称',
    'remark': '备注',
    'model': '车型',
    'selectConfigurationInformation': '选择配置信息',
    'groupOne': '第一组 !',
    'lastGroup': '最后一组 !',
    'list': '列表',
    'back': '返回',
    'switchGroup': '开关',
    'notFilter': '不过滤',
    'filter': '过滤器',
    'config': '配置',
    'pos': 'POS',
    'qty': 'QTY',
    'addWatch': '加入收藏列表',
    'brandPart': '品牌零件',
    'component': '组件',
    'disclaimer': '声明:查询结果仅供参考。',
    'partDetail': '零件详情',
    'vinNotAvailable': '车辆识别码不可用',
    'partNotAvailable': '零件号不可用',
    'lackModels': '缺少模型',
    'dataNotComplete': '数据不完整',
    'dataError': '数据错误',
    'dataNotNew': '数据不够新',
    'slowSpeed': '速度慢',
    'noUniqueness': '不唯一',
    'other': '其它',
    'selectQuestion': '选择问题',
    'close': '关闭',
    'howVin': '如何查看机架号?',
    'howVin1': '找到车架号，在车体前窗玻璃上记录车辆识别码',
    'howVin2': '输入车架号或点击上传图片，识别车架号',
    'howVin3': '单击查询图标可查询车架号',
    'howVin4': '输入品牌名称，单击查询图标进行查询',
    'findParts': '如何找到零件?',
    'findParts1': '将鼠标悬停在停车架号上，可查看基本配置信息',
    'findParts2': '点击此图标使用3D模型查询车身部件、底盘部件、发动机部件',
    'findParts3': '选择主组和组进入零件列表',
    'findParts4': '单击，重新选择主群组组和框号查询',
    'findParts5': '单击分解视图的零件号以选择零件',
    'howPart': '如何使用零件号查询?',
    'howPart1': '输入或上传图片识别零件号，点击搜索进行查询',
    'howPart2': '点击图标将零件收集到我的收藏中，当零件价格发生变化时，你会收到邮件通知',
    'howPart3': '点击图标，查看该零件的近期价格趋势',
    'noHelp': '不需要帮助',
    'understood': '知道了',
    'nextStep': '下一步',
    'clickCheckInvitation': '点击这里查看邀请状态',
    'inviteFriendsGetPackage': '邀请10个朋友获得年度套餐，价值540美元!',
    'emailAddress': '电子邮件地址',
    'status': '状态',
    'redeem': '兑换',
    'inviteRewardTips': '<p>请确认申请<br /><em>All-in-One年购</em>奖励</p><p>如果您有当前有效的套餐，您的奖励将在到期时自动兑换',
    'currentSubscriptionExpires': '您当前的套餐过期日期为',
    'confirm': '确认',
    'putAway': 'Put Away',
    'seeAll': 'See All',
    'pageCannotEmpty': '页数不能为空',
    'pleaseRegisterSignin': '请注册/登录以搜索更多车辆数据',
    'price': '价格',
    'quantity': '数量',
    'pleaseRL': '请注册后登录',
    'sign': '登录',
    'stay': '记住密码',
    'forget': '忘记密码',
    'password': '密码',
    'RFFVS': '注册免费搜索vin',
    'cName': '公司名称',
    'basic': '基础信息',
    'updateTime': '更新时间',
    'english': 'English',
    'russian': 'русск',
    'indonesian': 'IndonesiaName',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語',




    'notfoundCpart': '没有找到 {pid} 相关零件',
    'notfoundModel': '没有找到 {active} 适用车型',
    'notfoundModelToSelect': '未找到 {pid} 适用车型，请选择其它零件',
    'foundResultNum': '为您找到 {keyword} 相关结果',
    'totalNum': '共{total}个',

    
    '4sPrice': '4S价格',
    'onlineInventory': '在线库存',
    'famousBrand': '知名品牌',
    'importedBrand': '进口品牌',
    'originalOE': '原厂OE号',
    'standardName': '标准名称',
    'xmodel': '型号',
    'projectNumber': '工程编号',
    'deleteSuccess': '删除成功！',
    'xmessage': '信息',
    'official': '官方',
    'manufacturer': '厂商',
    'explain': '说明',
    'purchasePrice(excludingTax)': '进价(未含税)',
    'purchasePrice(includingTax)': '进价(含税)',
    'salesPrice(includingTax)': '销售价(含税)',
    'largeInventoryPrice(includingTax)': '大库价格(含税)',
    'number': '件数',
    'salesPrice': '销售价',
    'company': '公司',
    'address': '地址',
    'phone': '电话',
    'wechat': '微信',
    'brandNumber': '品牌编号',
    'notes': '注释',
    'referencePrice': '参考价格',
    'yearf': '年份',
    'see': '查看',
    'partNumber': '零件号',
    'channelPrice(includingtax)': '渠道价格(含税)',
    'mainGroupQuery': '主组查询',
    'originalFactoryStructure': '原厂结构',
    'standardStructure': '标准结构',
    'Bmw': '宝马',
    'Benz': '奔驰',
    'LRJ': '路虎捷豹',
    'Maserati': '玛莎拉蒂',
    'HDT': '重汽',
    'Liberate': '解放',
    'SA': '陕汽',
    'pAll': '输入17位车架号或OE号',
    'pBmw': '输入宝马后7位或17位车架号',
    'pBenz': '输入奔驰后8位或17位车架号',
    'pLRJ': '输入路虎捷豹后8位或17位车架号',
    'pMaserati': '输入玛莎拉蒂后8位或17位车架号',
    'pHDT': '输入重汽后8位或17位车架号',
    'pLiberate': '输入解放后8位或17位车架号',
    'pSA': '输入陕汽后8位或17位车架号',
    'csearch': '查询',
    'inputFP': '输入零件原厂名／零件号',
    'filterg': '过滤',
    'notfilterg': '未过滤',
    'nofilterg': '不过滤',
    'inputConfig': '请输入配置信息',
    'selectConfig': '选择配置信息',
    'addSuccess': '添加成功！',
    'explosionDiagram': '爆炸图',
    'switch': '切换',
    'PartOENumber': '零件OE号',
    'decompositionDiagram': '分解图',
    'searchHomepage': '查询首页',
    'shoppingCart': '购物车',
    'determine': '确定',
    'copySuccess': '复制成功！'
  }
}

export default zh