import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, message, Popconfirm, Radio } from 'antd'
import accountApi from '@/api/accountApi'

const Permission: React.FC<any> = (props) => {

  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [keyword, setKeyword] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [carOpen, setCarOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [perOptions, setPerOptions] = useState<any>([])

  useEffect(() => {
  },[])

  useEffect(() => {
    getList()
  },[keyword, pager, pageSize])

  useEffect(() => {
    if(open) {
      accountApi.getPermissionList({pagesize: 1000000, page: 1}).then((res: any) => {
        if(res.code === 1) {
          setPerOptions(res.data.records ? res.data.records : [])
        }
      })
    }
  },[open])

  const getList = () => {
    let params = {
      search_key: keyword,
      page: pager.page,
      pagesize: pageSize,
      parent_permission_id: 0
    }
    setLoading(true)
    accountApi.getPermissionList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        let _data = res.data.records ? res.data.records : []
        _data.forEach((item: any) => {
          item.type = 1
          if(item.id && item.parent_permission_id && item.permission_name) {
            item.type = 2
          }
        })
        setList(_data)
      }
    })
  }
  
  const editItem = (item: any, type?: string) => {
    setActiveItem({...item})
    setOpen(true)
  }

  const delPermission = ({ id }: any) => {
    setSubmitLoading(true)
    accountApi.delPermission({ del_id: id }).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功")
        getList()
      }
    })
  }

  const submit = () => {
    let params = {
      edit_id: activeItem.id || undefined,
      permission_name: activeItem.permission_name,
      permission_url: activeItem.permission_url,
      parent_permission_name: activeItem.parent_permission_name,
      parent_permission_id: activeItem.type === 1 ? Math.floor(Math.random() * 100000) : activeItem.parent_permission_id,
      parent_path: activeItem.parent_path,
      parent_icon: activeItem.parent_icon
    }
    setSubmitLoading(true)
    accountApi.updatePermission(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功")
        setOpen(false)
        getList()
      }
    })
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '类别',
      dataIndex: 'type',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>
            {text === 1 ? '1级' : '2级'}
          </div>
        )
      }
    },
    {
      title: '主分类',
      dataIndex: 'parent_permission_name',
      align: 'center',
    },
    {
      title: '子分类',
      dataIndex: 'permission_name',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>{text || '-'}</div>
        )
      }
    },
    {
      title: '路径',
      dataIndex: 'permission_url',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>{record.type === 1 ? record['parent_path'] : text}</div>
        )
      }
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 200,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            <div className="flex-c-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
            <Popconfirm
              title="确定删除吗？"
              okButtonProps={{
                loading: submitLoading
              }}
              onConfirm={() => delPermission(record)}
            >
              <div className="flex-c-c d-color pointer mlr20" >
                <i className="iconfont icon-shanchu mr5 fs14" />
                删除
              </div>
            </Popconfirm>
          </div>
        )
      }
    },
  ]
  
  return (
    <div className={'bgc-fff p15 br4 min-content-height1'}>
      <div className='flex-b-c'>
        <Input.Search placeholder="搜索" style={{ width: 220 }} onSearch={(value: any) => { setPager({page: 1}); setKeyword(value) }}  />
        <Button onClick={() => { setActiveItem({ type: 1 }); setOpen(true); }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />} >添加权限</Button>
      </div>
      <div className="mt10">
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pager.page,
            total: pager.total,
            pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current: any, size: any) => setPageSize(size),
            onChange: (page: any) => setPager({...pager, page}),
            showTotal: (total: number) => `共 ${total} 条`
          }}
        />
      </div>

      <Modal
        title={activeItem.id ? '编辑' : '添加'}
        open={open}
        width={460}
        onCancel={() => setOpen(false)}
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={() => submit()}
      >
        <div className="mtb20">
          {
            !activeItem.id ? (
              <div className="flex-s-c mt10">
                <label className="right" style={{ width: 100 }}>类别：</label>
                <Radio.Group
                  style={{ flex: 1 }}
                  optionType="button"
                  buttonStyle="solid"
                  options={[
                    {label: '1级', value: 1},
                    {label: '2级', value: 2}
                  ]}
                  value={activeItem.type}
                  onChange={(e: any) => setActiveItem({ ...activeItem, type: e.target.value })}
                />
              </div>
            ): null
          }
          {
            activeItem.type === 1 ? (
              <>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>主分类：</label>
                  <Input
                    style={{ flex: 1 }}
                    value={activeItem.parent_permission_name}
                    onChange={(e: any) => setActiveItem({ ...activeItem, parent_permission_name: e.target.value })}
                  />
                </div>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>路径地址：</label>
                  <Input
                    style={{ flex: 1 }}
                    value={activeItem.parent_path}
                    onChange={(e: any) => setActiveItem({ ...activeItem, parent_path: e.target.value })}
                  />
                </div>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>图标地址：</label>
                  <Input
                    style={{ flex: 1 }}
                    value={activeItem.parent_icon}
                    onChange={(e: any) => setActiveItem({ ...activeItem, parent_icon: e.target.value })}
                  />
                </div>
              </>
            ): activeItem.type === 2 ? (
              <>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>主分类：</label>
                  <Select
                    style={{ flex: 1 }}
                    placeholder="选择"
                    options={perOptions}
                    fieldNames={{ label: 'parent_permission_name', value: 'parent_permission_id' }}
                    value={activeItem.parent_permission_id || undefined}
                    onChange={(value: any, option: any) => {
                      let { parent_permission_name, parent_permission_id } = option
                      console.log(option)
                      setActiveItem({ ...activeItem, parent_permission_name, parent_permission_id })
                    }}
                  />
                </div>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>子分类：</label>
                  <Input
                    style={{ flex: 1 }}
                    value={activeItem.permission_name}
                    onChange={(e: any) => setActiveItem({ ...activeItem, permission_name: e.target.value })}
                  />
                </div>
                <div className="flex-s-c mt10">
                  <label className="right" style={{ width: 100 }}>路径地址：</label>
                  <Input
                    style={{ flex: 1 }}
                    value={activeItem.permission_url}
                    onChange={(e: any) => setActiveItem({ ...activeItem, permission_url: e.target.value })}
                  />
                </div>
              </>
            ): null
          }
        </div>
      </Modal>
    </div>
  )
}

export default Permission