import axios from 'axios'

const dataApi: any = {}


// 获取首页数据
dataApi.getHomeData = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/home/threed/statistics", {params})
}

// 获取全部品牌
dataApi.getBrandsList = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/brands/list", params)
}
dataApi.getBrandData = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/brands/list", {params})
}

// 车型车系
dataApi.getSeriesList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/series/association/overview", {params})
}
dataApi.getModelsList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/series/association/overview", {params})
}
dataApi.getRelatedList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/series/match/list", {params})
}
dataApi.getUnRelatedList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/series/nomatch/list", {params})
}
dataApi.relatedItems = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/series/nomatch/associate", params)
}
dataApi.removeItems = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/series/match/remove", params)
}

dataApi.getAssociationList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/series/association/detail", {params})
}
dataApi.relatedOrMove = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/series/association", params)
}
dataApi.getAttributeList = (params: {}): Promise<{}> => {
  return axios.post("/api/v2/cars/attribute_search", params)
}


// 底盘
dataApi.getSearhList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/chassis/menu/list", {params})
}
dataApi.getStdList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/chassis/list", {params})
}
dataApi.updateStdList = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/chassis/edit", params)
}

dataApi.getAllChassisList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/chassis/list/all", {params})
}


// 发动机
dataApi.getEngineList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/list", {params})
}
dataApi.getEngineSelectOptions = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/menu/list", {params})
}
dataApi.updateEngine = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/engine/edit", params)
}
dataApi.getAllEngineList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/list/all", {params})
}
dataApi.updateItems = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/engine/edit/all", params)
}

// 零件
dataApi.getField = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/field_list', params)
}
dataApi.getData = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/part', params)
}
dataApi.updateStatus = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/part/update', params)
}
dataApi.addPart = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/part/add', params)
}
dataApi.addVin = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/vin/add', params)
}
dataApi.addVinRemark = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/vin/remark/add', params)
}
dataApi.addPosition = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/remark/update', params)
}
dataApi.vinUpdate = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/vin/update', params)
}

dataApi.vinDel = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/vin/delete', params)
}

dataApi.carsUpdate = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/cars/update', params)
}
dataApi.addCartCode = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/attrib/add', params)
}
dataApi.delCartCode = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/attrib/update', params)
}

dataApi.updatePartExtra = (params: any) => {
  return axios.post('/v1/operate/api/v1/check/extra/update', params)
}


// 标准车型

dataApi.getSmodelList = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/cars/list", {params})
}
dataApi.addSmodel = (params: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/cars/add", params)
}
dataApi.updateSmodel = (params: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/cars/update", params)
}
dataApi.delSmodel = (params: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/cars/delete", params)
}

// 车型vin

dataApi.getModelVinList = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/vin/list", {params})
}
dataApi.getModelVinDetail = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/vin/carvinlist", {params})
}
dataApi.updateModelVin = (params: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/vin/update", params)
}
dataApi.lookRecords = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/vin/recordlogs", {params})
}

// 品类oe

dataApi.getOtherBrandList = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/common/brandlist", {params})
}
dataApi.getStandardlist = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/common/standardlist", {params})
}
dataApi.getAttrlist = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/common/attrlist", {params})
}
dataApi.getCategoryoeList = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/oe/list", {params})
}
dataApi.updateOe = (params: any, config: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/oe/update", params, config)
}
dataApi.getVinList = (params: any) => {
  return axios.get("/v1/operate/api/v1/databzpt/oe/vinlist", {params})
}
dataApi.getOeList = (params: any) => {
  return axios.post("/v1/operate/api/v1/databzpt/oe/getoelist", params)
}

export default dataApi