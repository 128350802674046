import { Input, message, Modal, Select, Spin, Button, Row, Col, Divider, Table, Slider, Progress } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios'
import Datatopbox from "./Components/Datatopbox";
import UploadModel from '@/components/UploadModel'


const { confirm } = Modal

let indexObj: any = {}


const model_type_arr: any = [
  {
    label: '车身',
    value: 'body_model_name',
    key: 'body_model_name'
  },
  {
    label: '底盘',
    value: 'chassis_model_name',
    key: 'chassis_model_name'
  },
  {
    label: '发动机',
    value: 'engine_model_name',
    key: 'engine_model_name'
  },
  {
    label: '电池',
    value: 'battery_model_name',
    key: 'battery_model_name'
  },
  {
    label: '变速箱',
    value: 'gearbox_model_name',
    key: 'gearbox_model_name'
  }
]


let model_type = ''
let category: any = ""

let position = {
  ri: 0,
  ci: 0
}

const Tcar: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth, brandsList } = store
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const [brand, setBrand] = useState<any>("奥迪")
  const [selectModelOptions, setSelectModelOptions] = useState<any>([])


  const brandRef = useRef<any>(null)

  let header = [
    {
      label: '品牌',
      key: 'brand'
    },
    {
      label: '车系',
      key: 'series'
    },
    {
      label: '车型',
      key: 'models'
    },
    {
      label: '操作时间',
      key: 'update_time'
    },
    ...model_type_arr,
    {
      label: '操作',
      key: 'operate'
    },
    {
      label: 'code',
      key: 'code'
    },
    {
      label: 'body_model',
      key: 'body_model'
    },
    {
      label: 'chassis_model',
      key: 'chassis_model'
    },
    {
      label: 'engine_model',
      key: 'engine_model'
    },
    {
      label: 'battery_model',
      key: 'battery_model'
    },{
      label: 'gearbox_model',
      key: 'gearbox_model'
    },

  ]
  

  useEffect(() => {
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      brandRef.current = brand
      getList()
    }
  },[brand])

  


  


  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: header.length - 6,
        width: 120
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',(e: any) => console.log(e))

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let code = rowData[indexObj['code']]?.text || ''
        let series = rowData[indexObj['series']]?.text || ''
        let models = rowData[indexObj['models']]?.text || ''
        let brand = rowData[indexObj['brand']]?.text || ''
        let is: boolean = false
        if(!code) return false

        // 解除绑定
        if(ci === indexObj['operate']) {
          Modal.confirm({
            content: '确定清空此车型的全部模型数据吗？',
            onOk() {
              let params = {
                code,
                delete_type: 'line'
              }
              axios.get("/v1/operate/threed/optimize/cars/model/delete", {params}).then((res: any) => {
                if(res.code === 1) {
                  message.success("操作成功！")
                  getList()
                }
              })
            }
          })
          return false
        }

        if(ci === indexObj['body_model_name']) {
          model_type = 'body_model'
          category = '车身'
          is = true
        }else if(ci === indexObj['chassis_model_name']) {
          model_type = 'chassis_model'
          category = '底盘'
          is = true
        }else if(ci === indexObj['engine_model_name']) {
          model_type = 'engine_model'
          category = '发动机'
          is = true
        }else if(ci === indexObj['battery_model_name']) {
          model_type = 'battery_model'
          category = '电池'
          is = true
        }else if(ci === indexObj['gearbox_model_name']) {
          model_type = 'gearbox_model'
          category = '变速箱'
          is = true
        }
        if(!is) return false
        position = {
          ri,
          ci
        }
        let model_car_id = rowData[indexObj[model_type]]?.text || ''
        setOpen(true)
        setActiveItem({
          category,
          code,
          brand,
          series,
          models,
          model_car_id
        })
        getSelectModelOptions()
      }
      
    })
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = model_type_arr.map((it: any) => it.value)
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        if(other.includes(item.key)) {
          cells.push({
            text,
            editable: false,
            style: 3
          })
        }else if(item.key === 'operate') {
          cells.push({
            text: '清空此车型的绑定',
            editable: true,
            style: 2
          })
        }else {
          cells.push({
            text,
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        2: {
          width: 400
        }
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        },
        {
          color: '#096BED'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    let params = {
      brand: brandRef.current || ""
    }
    setLoading(true)
    axios.get("/v1/operate/threed/optimize/cars/model/list", {params}).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        
        let list = res.data
        initData(list, header)
      }
    })
  }


  const submit = () => {
    let params = {}
    let api: any
    if(!activeItem.model_car_id) {
      let other: any = {
        '车身': {
          key: 'body_model',
          key_name: 'body_model_name'
        },
        '底盘': {
          key: 'chassis_model',
          key_name: 'chassis_model_name'
        },
        '发动机': {
          key: 'engine_model',
          key_name: 'engine_model_name'
        },
        '电池': {
          key: 'battery_model',
          key_name: 'battery_model_name'
        },
        '变速箱': {
          key: 'gearbox_model',
          key_name: 'gearbox_model_name'
        }
      }
      let otherParams: any = {} 
      Object.values(other[activeItem.category]).forEach((v: any) => {
        otherParams[v] = ''
      })
      params = {
        code: activeItem.code,
        delete_type: 'column',
        ...otherParams
      }
      setSubmitLoading(true)
      api = axios.get("/v1/operate/threed/optimize/cars/model/delete", {params})
    }else {
      params = {
        ...activeItem
      }
      setSubmitLoading(true)
      api = axios.post(`/v1/operate/threed/optimize/cars/model/edit`, params)
    }
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        // getList()
        if(!activeItem.model_car_id) {
          handleModelData({name: '', value: ''})
        }else {
          handleModelData({name: activeItem.model_car_name, value: activeItem.model_car_id})
        }
        setOpen(false)
        message.success("操作成功")
      }
    })
  }

  const getSelectModelOptions = () => {
    let params = {
      category
    }
    axios.get("/v1/operate/threed/optimize/model/name/search", {params}).then((res: any) => {
      if(res.code === 1) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.car_model_name,
            value: item.id + "",
          }
        })
        setSelectModelOptions(_list)
      }
    })
  }

  const handleModelData = (option: any) => {
    
    console.log(option)
    jexcelRef.current.datas[0].rows.setCell(position.ri, indexObj[model_type], {
      text: option.value,
      editable: true,
    }, 'all')
    jexcelRef.current.datas[0].rows.setCell(position.ri, indexObj[model_type + '_name'], {
      text: option.name,
      editable: true,
      style: 3
    }, 'all')
    jexcelRef.current.reRender()

    
    
    
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Select
        className="mr12"
        style={{ width: 200 }}
        showSearch
        filterOption={(input: any, option: any) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={brandsList}
        placeholder="品牌"
        value={brand || undefined}
        onChange={(value) => setBrand(value)}
      />
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title="关联"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={submit}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className='mtb20 flex-b-c'>
          <label style={{ width: 100 }}>{activeItem.category}：</label>
          <Select
            placeholder="请选择需要关联的模型"
            style={{ flex: 1 }}
            showSearch
            allowClear
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={selectModelOptions}
            value={activeItem.model_car_id}
            onChange={(value: any, option: any) => setActiveItem({...activeItem, model_car_id: value || "", model_car_name: option?.label || ""})}
          />
        </div>
      </Modal>

    </div>
  )
}

export default Tcar








