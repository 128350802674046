import React, { useEffect } from 'react'
import { Modal } from 'antd'


let progress = 0
const Lookthreed: React.FC<any> = (props) => {

  const { tempData, file, open, setOpen, title } = props

  useEffect(() => {
    if(file){
      var model: any = (window as any).matchbox({
        file,
        id: 'container',
        scale: 1.5,
        loaded: function(val: any){
          let progressDiv: any = document.getElementById('progress')
          progress = val
          if(val === 100){
            if(progressDiv) {
              progressDiv.style.display = 'none'
            }
            if(tempData){
              setModel(tempData)
            }
          }else {
            if(progressDiv) {
              progressDiv.innerText = val + '%'
            }
          }
        }
      })
      const setModel = (data: any) => {
        model.setActive(data, true, true, '#FF00FF')
      }
      
    }
  },[])

  useEffect(() => {
    return () => {
      progress = 0
    }
  },[])
  
  return (
    <Modal
      title={title}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={1100}
    >
      <div style={{ position: 'relative', border: '1px solid #f8f8f8' }}>
        <div id="progress" style={{ position: 'absolute', left: 10, top: 10 }}></div>
        <div id="container" style={{ width: 1060, height: 700 }}></div>
      </div>
    </Modal>
  )
}

export default Lookthreed