import React from "react";
import ThreedPart from "./Components/ThreedPart";
import Datatopbox from "./Components/Datatopbox";

const Part: React.FC<any> = (props) => {
  return (
    <div>
      <Datatopbox/>
      <div className="bgc-fff p10 mt8">
        <ThreedPart/>
      </div>
    </div>
  )
}

export default Part
