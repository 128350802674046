import axios from 'axios'

const threedApi: any = {}

// 获取全部品牌
threedApi.getBrandsList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/brands/menu/list", {params})
}
threedApi.getBrandData = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/brands/list", {params})
}

// 车身
threedApi.getBodyList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/body/list", {params})
}
threedApi.operateBodyItem = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/body/add", params)
}




// 底盘
threedApi.getChassisList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/chassis/list", {params})
}
threedApi.operateChassisItem = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/chassis/add", params)
}

// 发动机
threedApi.getEngineList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/engine/list", {params})
}
threedApi.operateEngineItem = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/engine/add", params)
}

threedApi.getEngineSelectOptions = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/menu/list", {params})
}
threedApi.updateEngine = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/engine/edit", params)
}


threedApi.getWholeList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/car/list", {params})
}
threedApi.operateWholeItem = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/car/add", params)
}


// 零件

threedApi.getMainGroup = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/pid/menu/main", {params})
}
threedApi.getSubGroup = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/pid/menu/sub", {params})
}
threedApi.getPartList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/pid/list", {params})
}
threedApi.updatePart = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/pid/add", params)
}
threedApi.delPart = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/threed/pid/del", params)
}


// 

threedApi.getStatistics = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/threed/statistics", {params})
}




export default threedApi