import axios from 'axios'

const namestdApi: any = {}

namestdApi.getTopList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzmc/list", {params})
}
namestdApi.addTopItem = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzmc/add", {params})
}
namestdApi.updateTopItem = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzmc/update", {params})
}
namestdApi.delTopItem = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzmc/del", {params})
}

namestdApi.getQdlxList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/qdlx/list", {params})
}
namestdApi.getStandardlist = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/common/standardlist", {params})
}
namestdApi.getStdclassList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzfl/list", {params})
}
namestdApi.addStdclass = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzfl/add", {params})
}
namestdApi.updateStdclass = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzfl/update", {params})
}
namestdApi.getMappingList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/bzfl/name/mapping/list", {params})
}
namestdApi.updateMappingList = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/api/v1/databzpt/bzfl/name/mapping/add", params)
}


namestdApi.getOenameList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzoe/name/list", {params})
}
namestdApi.addOename = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzoe/name/add", {params})
}
namestdApi.updateOename = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzoe/name/update", {params})
}
namestdApi.delOename = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzoe/name/delete", {params})
}
namestdApi.getSelectNamelist = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzcomm/get/namelist", {params})
}
namestdApi.getOeBrandList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzcomm/getoe/brand", {params})
}
namestdApi.getSelectStandardlist = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzcomm/get/standerlabellist", {params})
}


namestdApi.getOthername = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzbm/name/list", {params})
}
namestdApi.addOthername = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzbm/name/add", {params})
}
namestdApi.updateOthername = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzbm/name/update", {params})
}
namestdApi.delOthername = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzbm/name/delete", {params})
}

namestdApi.getGroupList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzsub/label/list", {params})
}
namestdApi.addGroupItem= (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzsub/label/add", {params})
}
namestdApi.updateGroupItem = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzsub/label/update", {params})
}
namestdApi.delGroupItem = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/bzsub/label/delete", {params})
}



export default namestdApi
