import { Select, Collapse, Pagination, Spin, Input } from 'antd'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import styles from './index.module.scss'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'

const Model: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const _brandCode = searchParams.get("brandCode")
  const { pathname } = useLocation()
  const { store, setStore } = useContext(context)
  
  const { brandsList, writeAuth } = store
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [searchData, setSearchData] = useState<any>({
    brand: _brandCode || brandsList[0].value,
    series: ''
  })
  const [filterWord, setFilterWord] = useState<string>("")

  useEffect(() => {
    if(searchData.brand || true) {
      getList()
    }
  },[searchData.brand])


  const getList = () => {
    let params = {
      page: 1,
      size: 10000000,
      ...searchData
    }
    setLoading(true)
    dataApi.getModelsList(params).then((res: any) => {
      if(res.code === 1) {
        let list = (res.data.data ? res.data.data : []).filter((it: any) => it.series)
        let listSeries = list.map((it: any) => it.series)
        let newParams = {
          attribute: 'models',
          keyword: {brand: [searchData.brand]}
        }
        dataApi.getBrandsList(newParams).then((newRes: any) => {
          setLoading(false)
          if(newRes.code === 1) {
            let newListSeries = newRes.data
            let otherListSeries: any = []
            newListSeries.forEach((it: any) => {
              if(!listSeries.includes(it)) {
                otherListSeries.push(it)
              }
            })
            let otherList = otherListSeries.map((item: any) => {
              return {
                series: item,
                associatedTotal: 0,
                brand: searchData.brand
              }
            })
            setList([...list, ...otherList])
          }
        })
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  return (
    <div className={styles['model']}>
      <div className="threed-con">
        <div className="data-top-box">
          <div className="box-item f0">
            <i className="iconfont icon-benyuexinzeng i" />
            <span className="mlr10">本月新增</span>
            <span className="b">40,000</span>
          </div>
          <div className="box-item f1">
            <i className="iconfont icon-wancheng i" />
            <span className="mlr10">已关联</span>
            <span className="b">40,000</span>
          </div>
          <div className="box-item f2">
            <i className="iconfont icon-weiguanlian i" />
            <span className="mlr10">未关联</span>
            <span className="b">40,000</span>
          </div>
        </div>
      </div>
      <div className={styles['content']}>
        <div className={styles['search']}>
          <label>筛选：</label>
          <Select
            style={{ width: 170, marginRight: 12 }}
            placeholder="品牌"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={brandsList}
            value={searchData.brand || undefined}
            onChange={(value: any) => setSearchData({ ...searchData, brand: value })}
          />
          <Input  
            allowClear
            style={{ width: 220 }}
            placeholder='车系'
            value={filterWord}
            onChange={(e: any) => setFilterWord(e.target.value || "")}
          />
        </div>
        <Spin spinning={loading}>
        <div className={styles['list'] + ' mt8'}>
          {
            list.filter((it: any) => (it.series.toUpperCase()).includes(filterWord.toUpperCase())).map((item: any, index: number) => {
              return (
                <div className={styles['item'] + " flex-b-c plr10 ptb8"} key={index}>
                  <div className="flex-s-c">
                    <span style={{ width: 300 }}>{item.series}</span>
                    <span className='ml10'>已关联 {item.associatedTotal}</span>
                  </div>
                  <div  className="right ">
                    {
                      writeAuth[pathname] ? (
                        <div onClick={() => navigate(`/datastandard/model/detail?brandCode=${searchData.brand}&series=${item.series}&navname=${item.brand + ' ' + item.series}`)} className="flex-s-c p-color pointer" style={{ justifyContent: 'flex-end' }} >
                          <i className="iconfont icon-bianji fs14  mr2" />
                          编辑
                        </div>
                      ): null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        </Spin>
      </div>
    </div>
  )
}


export default Model