import React, { useEffect, useState } from "react";
import styles from './index.module.scss'
import { Button, Input, Modal, Select, Table } from "antd";

const Datamanage: React.FC<any> = (props) => {
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchData, setSearchData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(true)

  const [list, setList] = useState<any>([{}])
  const [activeItem, setActiveItem] = useState<any>({})

  useEffect(() => {
    getList()
  },[searchData.keyword, searchData.category])

  const getList = () => {

  }
  const columns: any = [
    {
      title: '编号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '资料名称',
      dataIndex: 'name',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            
          </div>
        )
      }
    },
    {
      title: '操作人',
      dataIndex: 'username',
      align: 'center',
      width: 150,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            
          </div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 200,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c p-color">
            <div className="mr20 pointer">详情</div>
            <div className="pointer">查看车型</div>
          </div>
        )
      }
    },
  ]
  return (
    <div className="bgc-fff p10">
      <div className="flex-s-c">
        <Select
          placeholder="资料分类"
          style={{ marginRight: 12, width: 220 }}
          value={searchData.category}
          onChange={(value: string) => setSearchData({...searchData, category: value})}
        />
        <Input.Search  
          placeholder="编码/名称"
          style={{ width: 220 }}
          onSearch={(value: string) => setSearchData({...searchData, keyword: value})}
        />
      </div>

      <div className="flex-s-c mt15">
        <Button className="mr10" type="primary" ghost>+新增</Button>
        <Button className="mr10" type="primary" ghost>复制</Button>
        <Button type="primary" ghost>删除</Button>
      </div>

      <Table
        className="mt10"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={{
          current: pager.page,
          total: pager.total,
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current: any, size: any) => setPageSize(size),
          onChange: (page: any) => setPager({...pager, page}),
          showTotal: (total: number) => `共 ${total} 条`
        }}
      />


      {/*  */}
      <Modal
        title={activeItem.id ? '编辑' : '新增'}
        open={open}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Addoredit

        />
      </Modal>
    </div>
  )
}

export default Datamanage


// 新增 编辑
const Addoredit: React.FC<any> = (props) => {
  return (
    <div style={{ border: '1px solid #E0E0E0' }}>
      <div>
        <h2 className="p10" style={{ borderBottom: '1px solid #E0E0E0' }}>基本信息</h2>
        <div className="p10">
          <div className="flex-s-c">
            <label className="right pr10" style={{ width: 100 }}><span className="red">*</span>资料名称</label>
            <Input  />
          </div>
          <div className="flex-s-c mt10">
            <label className="right pr10" style={{ width: 100 }}>备注</label>
            <Input.TextArea  />
          </div>
        </div>
      </div>
      <div style={{ height: 10, background: "#EBF1FA" }}></div>
      <div>
        <h2 className="p10 flex-b-c" style={{ borderBottom: '1px solid #E0E0E0' }}>
          <span>图片/视频信息</span>
          <span className="p-color pointer">+上传图片/视频</span>
        </h2>
        <div className="p10">
          
        </div>
      </div>
      <div style={{ height: 10, background: "#EBF1FA" }}></div>
      <div>
        <h2 className="p10 flex-b-c" style={{ borderBottom: '1px solid #E0E0E0' }}>
          <div className="flex-s-c">
            <span className="mr10">附件文档</span>
            <span style={{ fontSize: 14, color: '#979797', fontWeight: 400 }}>注意: 支持 pdf、图片、文件夹上传，单个文件大小不超过 30M；</span>
          </div>
          <div>
            <Button type="primary" className="mr10">新建文件夹</Button>
            <Button type="primary" className="mr10">上传文件</Button>
            <Button type="primary">上传文件夹</Button>
          </div>
        </h2>
        <div className="p10">
          
        </div>
      </div>


    </div>
  )
}