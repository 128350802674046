import React, { useState, useEffect, useRef } from 'react'
import { cdnsImgPath } from '@/utils/utils'
import css from './exploded.module.scss'

interface Parameter {
  data: any,
  activeHsKey: string,
  activePart: any,
  cntIndex: any,
  getSvgImgRect: () => void,
  setPoints: (val: string) => void,
  setActivePoint: (val: string) => void,
}

let zoomOriginal = {
  width: 0,
  height: 0,
  zoomNum: 10
}
let position = {
  startX: 0,
  startY: 0,
  startSubX: 0,
  startSubY: 0,
  startTextX: 0,
  startTextY: 0,
  tx: 0,
  ty: 0
}
let zoomNum = 10
let moveFn: (e: any) => void
let transform: any = {}

const width = 1120
const height = 1240

const Exploded: React.FC<Parameter> = (props: Parameter) => {
  const imgBoxRef = useRef<HTMLDivElement>(null)
  const svgBoxRef = useRef<HTMLDivElement>(null)
  const [ move, setMove ] = useState<any>({})
  const [ maskOpacity, setMaskOpacity ] = useState<number>(parseInt(localStorage.getItem('maskOpacity') || '50'))
  
  useEffect(()=>{
    const onWindowResize = zoom.bind(this, 'original')
    window.addEventListener( 'resize', onWindowResize )
    if(svgBoxRef.current){
      svgBoxRef.current.style.width = width + 'px'
      svgBoxRef.current.style.height = height + 'px'
      zoomOriginal.width = width
      zoomOriginal.height = height
      initSvg()
    }
    return () => {
      window.removeEventListener( 'resize', onWindowResize )
    }
  }, [])
  const initSvg = () => {
    if(svgBoxRef.current){
      const svgImgRect: any = props.getSvgImgRect()
      let top = Math.floor((zoomOriginal.height - svgImgRect.height) / 2)
      let left = Math.floor((zoomOriginal.width - svgImgRect.width) / 2)

      zoomNum = Math.ceil((Number((svgImgRect.width / zoomOriginal.width).toFixed(2))) * 10) + 1
      // zoomNum = zoomNum < 6 ? 6 : zoomNum
      zoomOriginal.zoomNum = zoomNum
      svgBoxRef.current.style.top = -top + 'px'
      svgBoxRef.current.style.left = -left + 'px'
      svgBoxRef.current.style.transform = `translate3d(1%, 1%, 0) scale(${zoomNum / 10})`
      // if(props.data.img_name_simple){
      //   svgBoxRef.current.style.backgroundImage = `url(${cdnsImgPath}/${props.data.img_name_simple})`
      // }
      if(props.data.img_name){
        svgBoxRef.current.style.backgroundImage = `url(${props.data.img_name})`
      }
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", moveFn)
      })
      
    }

  }
  const setSvgMove = (e: {pageX: number, pageY: number}) => {
    position.startX = e.pageX - position.tx
    position.startY = e.pageY - position.ty
    document.addEventListener("mousemove", svgMove)
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", svgMove);
    })
  }

  const svgMove = (e: {pageX: number, pageY: number}) => {
    let tx = e.pageX - position.startX;
    let ty = e.pageY - position.startY;
    position.tx = tx
    position.ty = ty
    const transformStr = `translate(${tx}px,${ty}px)`;
    if(imgBoxRef.current){
      imgBoxRef.current.style.transform = transformStr
    }
  }
  

  const zoom = (type: string) => {
    if(type === 'add' && zoomNum < 10){
      zoomNum++
    }
    if(type === 'minus' && zoomNum > 0){
      zoomNum--
    }
    if(type === 'original'){
      zoomNum = zoomOriginal.zoomNum
      if(imgBoxRef.current){
        position.tx = 0
        position.ty = 0
        const transformStr = `translate(0px,0px)`;
        imgBoxRef.current.style.transform = transformStr
      }
      initSvg()
    }
    if(svgBoxRef.current){
      svgBoxRef.current.style.transform = `translate3d(1%, 1%, 0) scale(${zoomNum/10})`
    }
  }
  const setMovePath = (item?: any, marker?: string) => {
    if(item && item.sub_img_rect){
      let curve = 'M '
      let startX = item.sub_img_rect[0] + item.sub_img_rect[2]/2 + (item.subDx || 0)
      let startY = item.sub_img_rect[1] + item.sub_img_rect[3]/2 + (item.subDy || 0)

      let mX = startX
      let mY = startY
      
      let arrowES = item.rect[0] + item.rect[2]/2 + (item.dx || 0)
      let arrowNW = item.rect[1] + item.rect[3]/2 + (item.dy || 0)
      let radian = ~~((mX + item.rect[0]) / 2)

      curve += mX + ', ' + 
      mY + ' C ' + 
      radian + ', ' + 
      mY + ', ' + 
      radian + ', ' + 
      arrowNW + ', ' + 
      arrowES + ', ' + 
      arrowNW

      setMove({
        curve,
        marker
      })
    }else {
      setMove({})
    }
  }

  const gTextDown = (event: any, item: any) => {
    event.stopPropagation();
    if(event.button){
      return
    }
    position.startTextX = event.pageX - (item.textDx || 0) / (10 / zoomNum);
    position.startTextY = event.pageY - (item.textDy || 0) / (10 / zoomNum);
    
    moveFn = (e) => {
      setGTextMove(e, item)
    }
    document.addEventListener("mousemove", moveFn);
  }
  const setGTextMove = (event: any, item: any) => {
    event.stopPropagation();
    let tx = event.pageX - position.startTextX;
    let ty = event.pageY - position.startTextY;
    let markW = 30
    let markH = 18
    tx = tx * 10 / zoomNum
    ty = ty * 10 / zoomNum
    
    if(item.rect[0] + tx < 0){
      tx = 0 - item.rect[0]
    }
    if((tx + item.rect[0] + markW) >= (width - 4)){
      tx = width - 4 - markW - item.rect[0]
    }
    
    if(item.rect[1] + ty - markH < 0){
      ty = 0 - item.rect[1] + markH
    }
    if((ty + item.rect[1]) >= (height - 4)){
      ty = height - 4 - item.rect[1]
    }
    const transformStr = `translate(${tx}px,${ty}px)`;
    // subG.style.transform = transformStr
    item.transformText = transformStr

    item.textDx = tx;
    item.textDy = ty;
    if(!transform['itid' + item.itid]){
      transform['itid' + item.itid] = {}
    }
    transform['itid' + item.itid].textDx = tx
    transform['itid' + item.itid].textDy = ty
    transform['itid' + item.itid].transformText = transformStr

    props.setPoints(props.data)
  }
  const gDown = (event: any, item: any) => {
    event.stopPropagation();
    if(event.button){
      return
    }
    position.startX = event.pageX - (item.dx || 0) / (10 / zoomNum);
    position.startY = event.pageY - (item.dy || 0) / (10 / zoomNum);
    moveFn = (event) => {
      setGMove(event, item)
    }
    document.addEventListener("mousemove", moveFn);
  }

  const setGMove = (event: any, item: any) => {
    event.stopPropagation();
    const { data } = props
    let tx = event.pageX - position.startX;
    let ty = event.pageY - position.startY;
    tx = tx * 10 / zoomNum
    ty = ty * 10 / zoomNum
    
    if(item.rect[0] + tx < 0){
      tx = 0 - item.rect[0]
    }
    if((tx + item.rect[0] + item.rect[2]) >= (width - 4)){
      tx = width - 4 - item.rect[2] - item.rect[0]
    }
    
    if(item.rect[1] + ty < 0){
      ty = 0 - item.rect[1]
    }
    if((ty + item.rect[1] + item.rect[3]) >= (height - 4)){
      ty = height - 4 - item.rect[3] - item.rect[1]
    }
    const transformStr = `translate(${tx}px,${ty}px)`;
    // subG.style.transform = transformStr
    item.transform = transformStr

    item.dx = tx;
    item.dy = ty;
    console.info(item.point)
    if(item.point){
      item.point.forEach((p: string)=>{
        data.information[p].pDx = tx
        data.information[p].pDy = ty
      })
      console.info(tx, ty)
    }
    if(!transform['itid' + item.itid]){
      transform['itid' + item.itid] = {}
    }
    transform['itid' + item.itid].dx = tx
    transform['itid' + item.itid].dy = ty
    transform['itid' + item.itid].transform = transformStr

    if(item && item.sub_img_rect){
      let temp = JSON.parse(JSON.stringify(move))
      let curve = 'M '

      let startX = item.sub_img_rect[0] + item.sub_img_rect[2]/2 + (item.subDx || 0)
      let startY = item.sub_img_rect[1] + item.sub_img_rect[3]/2 + (item.subDy || 0)

      let mX = startX
      let mY = startY
      
      let arrowES = item.rect[0] + item.rect[2]/2 + item.dx
      let arrowNW = item.rect[1] + item.rect[3]/2 + item.dy
      let radian = ~~((mX + item.rect[0]) / 2)

      curve += mX + ', ' + 
      mY + ' C ' + 
      radian + ', ' + 
      mY + ', ' + 
      radian + ', ' + 
      arrowNW + ', ' + 
      arrowES + ', ' + 
      arrowNW

      temp.curve = curve
      setMove(temp)
      
    }else {
      setMove({})
    }
    props.setPoints(data)
  }
  // const dashedClick = (item: any) => {
  //   const { hsKeyAnchor, setCntIndex } = props
  //   let cntIndex = item.marks || []
  //   if(item.itids){
  //     cntIndex = [item.mark]
  //   }
  //   setCntIndex(cntIndex)
  //   hsKeyAnchor(item.itid_old)
  // }
  const updateMaskOpacity = (val: any) => {
    localStorage.setItem('maskOpacity', val)
    setMaskOpacity(val)
  }
  return (
    <div className={css['standard']}>
      <div ref={imgBoxRef} onMouseDown={(e)=>setSvgMove(e)}>
        <div ref={svgBoxRef} className={css['svg-box']}>
          <svg>
            <>
            {
                props.data.cnts.map((item: any, parentIndex: number)=>{
                  let path = 'M'
                  item.cnts.forEach((cn: any, i: number)=>{
                    if(!i){
                      path += cn.join(' ')
                    }else {
                      path += ' L' + cn.join(' ')
                    }
                  })
                  path += ' Z'
                  return (
                    <g
                      key={parentIndex}
                      onMouseEnter={()=>setMovePath(item, 'markerArrowReverse')}
                      onMouseLeave={()=>setMovePath()}
                      style={{transform: item.transform || ''}}
                    >
                      <path 
                        d={path}
                        strokeWidth={props.cntIndex.length && props.cntIndex.indexOf(item.mark) !== -1 ? '4' : '0'}
                        stroke={(props.cntIndex.length && props.cntIndex.indexOf(item.mark) !== -1) ? "#FFBF00" : ""}
                        strokeLinejoin="round"
                        fill={`url(#img${props.data.mid}${item.mark}${parentIndex})`}
                        className="move"
                        // fill="#ffffff"
                        opacity={props.data.itid_list.indexOf(item.itid_old) !== -1 || !props.data.mask ? 1 : (maskOpacity/100)}
                        onMouseDown={(event)=>gDown(event, item)}
                        onClick={(event) => props.data.itid_list.indexOf(item.itid_old) !== -1 ? props.setActivePoint(item): {}}
                      >
                      </path>
                    </g>
                  )
                })
              }
              {
                props.data.information.map((item: any, index: number)=>{
                  let arrowPath = 'M'
                  if(item.sub_itids_rect && item.sub_itids_rect.length){
                    item.sub_itids_rect.forEach((cn: any, i: number)=>{
                      if(!i){
                        arrowPath += cn.join(' ')
                      }else {
                        arrowPath += ' L' + cn.join(' ')
                      }
                    })
                  }
                  
                  return (
                    <g
                      key={index}
                      onMouseEnter={()=>setMovePath(item, 'markerArrowReverse')}
                      onMouseLeave={()=>setMovePath()}
                      style={{transform: item.transform || ''}}
                    >
                      <>
                        {
                          item.rect ? (
                            props.data.itid_list.indexOf(item.itid_old) !== -1 ? (
                              <text
                                x={item.rect[0]}
                                y={item.rect[1] ? item.rect[1] : 0}
                                fill={props.activeHsKey === item.itid_old || props.activePart.pnum === item.itid_old ? "#333333" : "#1A90FF"}
                                filter={props.activeHsKey === item.itid_old || props.activePart.pnum === item.itid_old ? "url(#solidActive)" : "url(#solid)"}
                                fontSize="22"
                                className="move"
                                style={{transform: item.transformText || ''}}
                                onMouseDown={(event)=>gTextDown(event, item)}
                                onClick={() => props.setActivePoint(item)}
                              >
                                {item.itid}
                              </text>
                            ): (
                              <text
                                x={item.rect[0]}
                                y={item.rect[1] ? item.rect[1] : 0}
                                fill="#cccccc"
                                filter="url(#solid)"
                                fontSize="22"
                                className="move"
                                style={{transform: item.transformText || ''}}
                                onMouseDown={(event)=>gTextDown(event, item)}
                              >
                                {item.itid}
                              </text>
                            )
                          ): null
                        }
                        {
                          item.marks_point && item.rect ? (
                            item.marks_point.map((point: any, pointIndex: number)=>{
                              return (
                                <line
                                  key={pointIndex}
                                  x1={item.rect[0] + (item.textDx || 0)}
                                  y1={item.rect[1] - 7 + (item.textDy || 0)}
                                  x2={point[0] + (item.pDx || 0)}
                                  y2={point[1] + (item.pDy || 0)}
                                  strokeWidth="1"
                                  stroke={props.activeHsKey === item.itid_old || props.activePart.pnum === item.itid_old ? "#FFBF00" : '#333333'}
                                />
                              )
                            })
                          ): null
                        }
                        {
                          item.sub_itids_rect && item.sub_itids_rect.length ? (
                            <path 
                              d={arrowPath}
                              strokeWidth='2'
                              stroke="#000000"
                              fill="rgba(255, 255, 255, 0)"
                              strokeLinejoin="round"
                              className="move"
                              onMouseDown={(event)=>gDown(event, item)}
                              onClick={() => props.setActivePoint(item)}
                            >
                            </path>
                          ): null
                        }
                      </>
                    </g>
                  )
                })
                
              }
              
            </>

            <g>
              <path id="moveG" d={move.curve || ''} strokeDasharray="5, 5" strokeWidth="1" style={{fill: 'none', stroke: '#000', markerStart: move.marker === 'markerArrowReverse' ? 'url(#markerArrowReverse)' : '', markerEnd: move.marker === 'markerArrowReverse' ? '' : 'url(#markerArrow)'}}></path>
            </g>
            <defs>
              <marker id="markerArrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto" >
                <path d="M2, 2 L2, 11 L10, 6 L2, 2" fill="#000000" />
              </marker>
              <marker id="markerArrowReverse" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
                <path d="M10, 2 L10, 11 L2, 6 L10, 2" fill="#000000" />
              </marker>
              <filter x="0" y="0" width="1" height="1" id="solid">
                <feFlood floodColor="#f6fbff" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter x="0" y="0" width="1" height="1" id="solidActive">
                <feFlood floodColor="#FFBF00" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter x="0" y="0" width="1" height="1" id="solidActiveOther">
                <feFlood floodColor="#ff5900" />
                <feComposite in="SourceGraphic" />
              </filter>
              {
                props.data.cnts.map((item: any, parentIndex: number)=>{

                  return (
                    item.image_name && item.cnts && item.rect ? (
                      <pattern 
                        key={parentIndex}
                        id={`img${props.data.mid}${item.mark}${parentIndex}`} 
                        width="1"
                        height="1"
                      >
                        <image
                          x={item.rect[0] * -1}
                          y={item.rect[1] * -1}
                          width={width} 
                          height={height}
                          // href={`https://cdns.007vin.com/img/qiye_rank_banner_210324.png`}
                          // href={`https://structimgs.007vin.com/${item.image_name}`}
                          href={`${cdnsImgPath}/${props.data.img_name_simple}`}
                        />
                      </pattern>
                    ): null
                  )
                })
              }
            </defs>
          </svg>
        </div>
      </div>
      <div className={css['img-tools']}>
        <span>
          <i className="iconfont icon-fangda1" onClick={()=>zoom('add')} />
          <u />
          <i className="iconfont icon-suoxiao1" onClick={()=>zoom('minus')} />
          <u />
          {
            props.data.mask ? (
              <div className={css.mask}>
                <div className={css.range}>
                  <input type="range" min="20" max="100" value={maskOpacity} onChange={(e)=>updateMaskOpacity(e.target.value)} />
                </div>
                <i className="iconfont icon-yiliaohangyedeICON-" />
              </div>
            ): null
          }
        </span>
        <span>
          <i className="iconfont icon-zhongzhi" onClick={()=>zoom('original')} />
        </span>
      </div>
    </div>
  )
}

export default Exploded