import { AutoComplete, Button, Checkbox, Input, message, Modal, Select, Spin, Tag } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import axios from 'axios'
import namestdApi from '@/api/namestdApi'


const CancelToken = axios.CancelToken
const controller = new AbortController();

let indexObj: any = {}


let timeout: any = null
let activeIndex: any = -1

const Oeothername: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth, brandData } = store
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [associationOpen, setAssociationOpen] = useState<boolean>(false)
  
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [activeItem, setActiveItem] = useState<any>({})

  const [keyword, setkeyword] = useState<string>("")
  

  const [selectStdList, setSelectStdList] = useState<any>([])
  const [nameOptions, setNameOptions] = useState<any>([])
  const [oeBrandList, setOeBrandList] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const keywordRef = useRef<any>(0)
  const fidRef = useRef<any>(0)

  let getCancel = useRef<any>(null)

  let header = [
    {
      label: 'OEM',
      key: 'oe'
    },
    {
      label: '品牌',
      key: 'brandCode'
    },
    {
      label: '原厂名称',
      key: 'label'
    },
    {
      label: '别名',
      key: 'name'
    },
    {
      label: '标准名称',
      key: 'standlabel'
    },
    {
      label: '位置信息',
      key: 'pos'
    },
    {
      label: '操作',
      key: 'operate'
    },
  ]

  
  

  useEffect(() => {
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
      keywordRef.current = null
      fidRef.current = null
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      keywordRef.current = keyword || ""
      // getList()
    }
  },[keyword])

  const searchNameList = (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
    const getCompanyOption = () => {
      namestdApi.getSelectNamelist({
        cancelToken: rToken,
        signal: controller.signal,
        name: value
      }).then((res: any) => {
        if(res.code === 1) {
          let _list = res.data.map((item: any) => {
            return {
              label: item,
              value: item
            }
          })
          setNameOptions(_list)
        }
      })
    }
    if (value) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setNameOptions([]);
    }
  }

  const searchBrandList = (oe?: string) => {
    let ioe = oe || activeItem.oe
    ioe && namestdApi.getOeBrandList({oe: ioe}).then((res: any) => {
      if(res.code === 1) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.name,
            value: item.brand
          }
          
        })
        console.log(_list)
        setOeBrandList(_list)
      }
    })
    return false
  }



  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: header.length,
        width: 1200 / (header.length)
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    jexcelRef.current.on('cell-edited',() => {
      console.log(123)
    })
    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        console.log(rowData)
        let fid = rowData[indexObj['fid']] ? rowData[indexObj['fid']].text : ''
        fidRef.current = fid
        let oe = rowData[indexObj['oe']]?.text || ''
        let brandCode = rowData[indexObj['brandCode']]?.text || ''
        let name = rowData[indexObj['name']]?.text || ''
        let standlabel = rowData[indexObj['standlabel']]?.text || ''
        let pos = rowData[indexObj['pos']]?.text || ''
        let label = rowData[indexObj['label']]?.text || ""
        if(!oe) return false
        if(ci === indexObj['name']) {
          setActiveItem({
            oe,
            brandCode,
            name,
            standlabel,
            pos,
            label,
            fid: 1
          })
          activeIndex = ri
          searchBrandList(oe)
          setOpen(true)
          
        }else if(ci === indexObj['operate']) {
          Modal.confirm({
            content: '确定删除这条数据吗？',
            onOk() {
              let params = {
                oe,
                brandCode
              }
              namestdApi.delOename(params).then((res: any) => {
                if(res.code === 1) {
                  message.success("操作成功")
                  getList()
                }
              })
            }
          })
        }
      }
      
    })

    // 编辑输入
    jexcelRef.current.on("cell-blur", (text: any, ri: any, ci: any) => {
      return false
      if(!ri) return false
      console.log(text, ri, ci)
      let key = Object.keys(indexObj).filter((it: any) => indexObj[it] === ci)[0] || ''
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      rowData = Object.values(rowData)
      let params: any = {}
      Object.keys(indexObj).forEach((k: string) => {
        params[k] = rowData[indexObj[k]]?.text || ''
      })
      delete params['operate']
      if(key) {
        params[key] = text
        submit(params)
      } 
      
    })
    
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers = header) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        let editable = true
        let styleObj = {}
        if(item.key === 'operate') {
          editable = false
          styleObj = {style: 2}
          text = '删除'
        }else if(item.key === 'name') {
          editable = false
          styleObj = {style: 3}
        }
        cells.push({
          text,
          editable,
          ...styleObj
        })
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        },
        {
          color: '#096BED'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    if(keywordRef.current.length < 5) {
      message.warning("请输入至少5位数！")
      return false
    } 
    setLoading(true)
    namestdApi.getOenameList({oe: keywordRef.current || ""}).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        initData(res.data)
      }
    })
  }


  const submit = (inputItem?: any) => {
    let item = inputItem || activeItem
    let api: any
    if(item.fid) {
      api = namestdApi.updateOename(item)
    }else {
      api = namestdApi.addOename(item)
    }
    setSubmitLoading(true)
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        if(item.fid) {
          jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['name'], {
            text: item.name,
            style: 3,
            editable: false
          }, 'all')
          jexcelRef.current.reRender()
        }else {
          getList()
        }
        setOpen(false)
        message.success("操作成功！")
      }
    })
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div className='flex-b-c'>
          <div className='flex-s-c'>
            <Input.Search
              placeholder='输入OE'
              onChange={(e: any) => setkeyword(e.target.value)}
              onSearch={(value: any) => getList()}
            />
          </div>
          
          <Button onClick={() => { setOpen(true); setActiveItem({}) }} type="primary" >添加OE标准名称</Button>
        </div>
        <div className='mt10' style={{ height: 'calc(100vh - 100px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title={`${activeItem.fid ? '修改' : '添加'}OE标准名称`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => submit()}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className='mtb20'>
          <div className='flex-s-c'>
            <label className='right' style={{ width: 100 }}><span className='red'>*</span>OE：</label>
            <Input
              placeholder="输入OE"
              style={{ flex: 1 }}
              value={activeItem.oe}
              onChange={(e: any) => setActiveItem({...activeItem, oe: e.target.value})}
              onBlur={() => searchBrandList()}
              disabled={activeItem.fid}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>品牌：</label>
            <Select
              placeholder="选择品牌"
              style={{ flex: 1 }}
              options={oeBrandList}
              value={activeItem.brandCode}
              onChange={(value) => setActiveItem({...activeItem, brandCode: value})}
              disabled={activeItem.fid}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}><span className='red'>*</span>原厂名称：</label>
            <Input
              placeholder="输入原厂名称"
              style={{ flex: 1 }}
              value={activeItem.label}
              onChange={(e: any) => setActiveItem({...activeItem, label: e.target.value})}
              disabled={activeItem.fid}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>别名：</label>
            <Select 
              showSearch
              style={{ flex: 1 }} 
              value={activeItem.name} 
              allowClear
              placeholder="输入名称搜索选择"
              options={
                nameOptions
              }
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onSearch={(value: any) => searchNameList(value)}
              onChange={(value: any) => setActiveItem({...activeItem, name: value || ""})} 
            />
          </div>
          {/* <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>标准名称：</label>
            <Input style={{ flex: 1 }} value={activeItem.standlabel} disabled />
          </div>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}>位置信息：</label>
            <Input style={{ flex: 1 }} value={activeItem.pos} disabled />
          </div> */}
        </div>
      </Modal>

      

    </div>
  )
}

export default Oeothername









