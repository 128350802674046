import axios from 'axios'

const accountApi: any = {}

accountApi.login = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/user/login", params)
}
// accountApi.uploadFile = (params: {}): Promise<{}> => {
//   return axios.post("/v1/operate/user/login", params)
// }

accountApi.getUserList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/user/list", {params})
}
accountApi.updateUserPwd = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/user/password/reset", params)
}
accountApi.updateUser = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/user/edit", params)
}
accountApi.getRoleList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/role/list", {params})
}
accountApi.updateRole = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/role/edit", params)
}
accountApi.delRole = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/role/del", {params})
}
accountApi.getRolePermission = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/role/permission/list", {params})
}
accountApi.updateRolePermission = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/role/permission/edit", params)
}

accountApi.getPermissionList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/permission/list", {params})
}
accountApi.updatePermission = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/permission/edit", params)
}
accountApi.delPermission = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/permission/del", {params})
}

accountApi.getButtonsList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/button/list", {params})
}
accountApi.updateButtons = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/admin/button/edit", params)
}
accountApi.delButtons = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/admin/button/del", {params})
}

export default accountApi