import React, { Fragment, useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Tabs, Spin, message } from 'antd'
import type { TabsProps } from 'antd'
import UploadModel from '@/components/UploadModel'
import { downloadFile } from '@/utils/utils'
import { MinusCircleOutlined } from '@ant-design/icons'
import dataApi from '@/api/dataApi'
import { useSearchParams, useLocation } from 'react-router-dom'
import context from '@/context/MainContext'



const Detail: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const brandCode = searchParams.get("brandCode") || ""
  const brand = searchParams.get("brandName") || ""
  const series = searchParams.get("series") || ""
  const seriesId = searchParams.get("seriesId") || ""

  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  
  
  const [searchData, setSearchData] = useState<any>({
    keyword: ''
  })

  const [activeKey, setActiveKey] = useState<'related' | 'unrelated'>("unrelated")
  const [rowKeys, setRowKeys] = useState<any>([])

  
  
  useEffect(() => {
    getList()
  },[searchData.keyword, pager, pageSize, activeKey])

  const getList = () => {
    let params = {
      page: pager.page,
      size: pageSize,
      search_key: searchData.keyword,
      brand: brandCode,
      // seriesId: activeKey === 'related' ? seriesId : undefined,
      associated: activeKey === 'related' ? 1 : 0
    }
    setLoading(true)
    dataApi.getAssociationList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.data)
      }
    })
  }

  

  const activeKeyChange = (value: any) => {
    setActiveKey(value)
    setPager({
      page: 1,
      total: 0
    })
    setSearchData({keyword: ''})
    setRowKeys([])
    setList([])
  }

  const relatedItems = (item?: any) => {
    let params: any = {
      batch_ids: item ? item.id + '' : rowKeys.join(","),
      series: ''
    }
    if(activeKey === 'unrelated') {
      params = {
        ...params,
        series,
        // seriesId
      }
    }
    dataApi.relatedOrMove(params).then((res: any) => {
      if(res.code === 1) {
        getList()
        message.success("操作成功")
      }
    })
  }



  const columnsItem = activeKey === 'related' || true ? [
    {
      title: '车型ID',
      dataIndex: 'cid',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {text}
          </div>
        )
      }
    }
  ] : []

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    ...columnsItem,
    {
      title: '车型',
      dataIndex: 'full_condition_path',
      align: 'center'
    },
    
    // {
    //   title: '示例车架号',
    //   dataIndex: 'vin',
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => {
    //     return (
    //       <div className="flex-c-c p-color">
    //         {text}
    //       </div>
    //     )
    //   }
    // },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (text: any, record: any, index: number) => {
        return (
          <Spin spinning={record.loading ? true : false}>
            <div className="flex-c-c">
              {
                activeKey === 'unrelated' ? (
                  <div className="flex-s-c p-color pointer" onClick={() => relatedItems(record)}>
                    <i className="iconfont icon-guanlian fs14  mr2" />
                    关联
                  </div>
                ): (
                  <div className="flex-s-c d-color pointer" onClick={() => relatedItems(record)} >
                    <MinusCircleOutlined className="fs14  mr2" />
                    移除
                  </div>
                )
              }
            </div>
          </Spin>
        )
      }
    },
  ]

  const rowSelection = {
    selectedRowKeys: rowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setRowKeys(selectedRowKeys)
    }
  }

  const items: TabsProps['items'] = [
    {
      key: 'unrelated',
      label: '未关联',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <Input.Search 
              style={{ width: 230 }}
              placeholder="关键字搜索"
              onSearch={(value: any) => {pager.page = 1; setSearchData({ keyword: value })}}
            />
            <Button onClick={() => relatedItems()} loading={submitLoading} type="primary" ghost  >批量关联</Button>
          </div>
          <div className="mt10">
            <Table
              rowKey="id"
              rowSelection={{...rowSelection}}
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
    {
      key: 'related',
      label: '已关联',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <Input.Search 
              style={{ width: 230 }}
              placeholder="关键字搜索"
              onSearch={(value: any) => {pager.page = 1; setSearchData({ keyword: value })}}
            />
            <Button onClick={() => relatedItems()} loading={submitLoading} type="primary" ghost style={{ color: '#FF7A00', borderColor: '#FF7A00' }} >批量移除</Button>
          </div>
          <div className="mt10">
            <Table
              rowKey="id"
              rowSelection={{...rowSelection}}
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
  ]
  return (
    <div className=" bgc-fff plr15 pb10 br4" style={{ minHeight: 'calc(100vh - 60px)' }}>
      <Tabs
        items={items}
        activeKey={activeKey}
        onChange={(value: any) => activeKeyChange(value)}
      />
    </div>
  )
}

export default Detail