import React, { Fragment, useState, useMemo } from 'react'
import { Upload, Spin, message, Progress } from 'antd'
import axios from 'axios'
import commonApi from '@/api/commonApi'
import { v4 } from 'uuid'


const UploadModel: React.FC<any> = (props) => {
  const { item, width = 230, disabled = false, uploadLabel, lkey, updateWrap, uuid, imgs } = props
  const [change, setChange] = useState<boolean>(false)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [percent, setPercent] = useState<any>(0)

  const fileName = useMemo(() => {
    let url = item[lkey]
    if(url) {
      let urlSplitArr = url.split("/")
      let urlRes = ''
      let longStrArr = urlSplitArr[urlSplitArr.length - 1].split(".")
      if(longStrArr.length >= 2) {
        urlRes = longStrArr[longStrArr.length - 2] + '.' + longStrArr[longStrArr.length - 1]
      }
      return urlRes
    }
    return ''
    
  },[item[lkey]])

  const fileUpload = (e: any) => {
    let file = e.file
    let name = e.file.name
    console.log("e:", e, 'name:', name)
    setPercent(0)
    commonApi.getUploadObs().then((res: any) => {
      if(res.code === 1) {
        let resData = res.data
        let key = (Math.floor(Math.random() * (1000)) + 1) + '.' + name
        if(uuid) {
          key = v4().replaceAll("-", "") + '.png'
        } 
        let policy = resData.policy
        let signature = resData.signature
        let accessKeyId = resData.accessKeyId
        let url = resData.url + '/'
        let formData = new FormData();
        formData.append('key', key)
        formData.append('content-type', 'text/plain')
        formData.append('policy', policy)
        formData.append('signature', signature)
        formData.append('accessKeyId', accessKeyId)
        formData.append('file', file)
        setUploadLoading(true)
        axios.post(url, formData, {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); 
            setPercent(percentCompleted)
          }
        }).then((res1: any) => {
          setPercent(100)
          setUploadLoading(false)
          let _fileUrl = 'https://bigfiles.007vin.com/' + key
          item[lkey] = _fileUrl
          if(uuid) {
            let _key = key.split(".")[0]
            if(item['structure_illustration']) {
              item['structure_illustration'] = _key
            }else {
              item['imageLarge'] = _key
            }
            if(imgs) imgs[_key] = _fileUrl
          } 
          setChange(change => !change)
          updateWrap && updateWrap()
          
        }).catch((error: any) => {
          setUploadLoading(false)
        });
      }
    })  
  }
  return (
    <Fragment>
      {
        item[lkey] ? (
          <div className='flex-b-c bgc-f8 plr10 br4' style={{ height: 40, width, opacity: disabled ? .5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}>
            <div>{fileName}</div>
            <Upload
              name="file"
              showUploadList={false}
              disabled={disabled}
              customRequest={fileUpload}
            >
              <Spin spinning={uploadLoading} tip={<Progress  percent={percent} />}>
                <div className="p-color pointer">重新上传</div>
              </Spin>
            </Upload>
          </div>
        ): (
          <Upload
            name="file"
            showUploadList={false}
            disabled={disabled}
            customRequest={fileUpload}
          >
            <Spin spinning={uploadLoading} tip={<Progress  percent={percent} />}>
              {
                props.children ? props.children : (
                  <div className='flex-c-c p-color p-b-color br4 pointer' style={{ height: 40, width, opacity: disabled ? .5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}>
                    {uploadLabel || '上传文件'}
                  </div>
                )
              }
            </Spin>
          </Upload>
        )
      }
    </Fragment>
  )
}

export default UploadModel