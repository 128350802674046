/**
 * 抛物线动画函数
 * @param ballWrapper 小球DOM
 * @param origin 动画起点DOM
 * @param target 动画目标DOM
 * @param time 持续时间
 * @param a 抛物线参数
 * @param callback 回调
 */

export function parabola(config) {
  const {
    ballWrapper,
    origin,
    target,
    time = 1000,
    a = 0.004,
    callback
  } =
  config || {};
  ballWrapper.style.display = 'block';
  const ballWrapperDimension = ballWrapper.getBoundingClientRect();
  const originDimension = origin.getBoundingClientRect();
  const targetDimension = target.getBoundingClientRect();
  const x1 = originDimension.left + 0.5 * originDimension.width;
  const y1 = originDimension.top + 0.5 * originDimension.height;
  const x2 = targetDimension.left + 0.5 * targetDimension.width;
  const y2 = targetDimension.top + 0.5 * targetDimension.height;
  const diffx = x2 - x1;
  const diffy = y2 - y1;
  const speedx = diffx / time;
  const b = (diffy - a * diffx * diffx) / diffx;
  ballWrapper.style.top = y1 + 'px';
  ballWrapper.style.left = x1 + 'px';
  ballWrapper.style.opacity = 0;
  // ballWrapper.style.display = 'none';
  ballWrapper.style.transform = 'translate(0px,0px)';
  let moveStyle = 'position',
    testDiv = document.createElement('input');
  if ('placeholder' in testDiv) {
    ['', 'ms', 'moz', 'webkit'].forEach(function(pre) {
      var transform = pre + (pre ? 'T' : 't') + 'ransform';
      if (transform in testDiv.style) {
        moveStyle = transform;
      }
    });
  }
  let start = new Date().getTime();

  let timer = setInterval(function() {
    if (new Date().getTime() - start > time) {
      ballWrapper.style.left = `${x2}px`;
      ballWrapper.style.top = `${y2}px`;
      ballWrapper.style.transform = 'translate(0px,0px)';
      ballWrapper.style.opacity = 0;
      ballWrapper.style.display = 'none';
      callback && (typeof callback === 'function') && callback();
      clearInterval(timer);
      timer = null;
      return;
    }
    let x = speedx * (new Date().getTime() - start);
    let y = a * x * x + b * x;
    if (moveStyle === 'position') {
      ballWrapper.style.left = `${x + x1}px`;
      ballWrapper.style.top = `${y + y1}px`;
    } else {
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          ballWrapper.style.transform = 'translate(' + x + 'px,' + y + 'px)';
          ballWrapper.style.opacity = 1;
        });
      } else {
        ballWrapper.style.transform = 'translate(' + x + 'px,' + y + 'px)';
        ballWrapper.style.opacity = 1;
      }
    }
  }, 15);
}
// 直线动画
export const linearJs = (config) => {
  const {
    ballWrapper, // 动画元素
    origin, // 触发元素
    target, // 目的地
    time = 1000, // 动画效果时间
    a = 0.004,
    callback
  } = config || {};
  const ballWrapperDimension = ballWrapper.getBoundingClientRect();
  const originDimension = origin.getBoundingClientRect();
  const targetDimension = target.getBoundingClientRect();
  const ox1 = originDimension.left;
  const oy1 = originDimension.top
  const tx2 = targetDimension.left
  const ty2 = targetDimension.top
  // 移动到起始点，并设置过渡效果
  ballWrapper.style.display = 'block'
  ballWrapper.style.top = oy1 + 'px';
  ballWrapper.style.left = ox1 + 'px';
  ballWrapper.style.opacity = 1;
  // 移动到目标位置
  setTimeout(()=>{
    ballWrapper.style.transition = 'all .5s ease';
    ballWrapper.style.top = ty2 + 'px';
    ballWrapper.style.left = tx2 + 'px';
    ballWrapper.style.opacity = 0;
    setTimeout(()=>{
      ballWrapper.style.opacity = 0;
      ballWrapper.style.transition = 'all 0s ease';
      ballWrapper.style.display = 'none'
    }, 500)
  }, 30)
}
