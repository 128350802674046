import { Input, message, Modal, Select, Spin, Button, Row, Col, Divider, Table } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios'
import namestdApi from '@/api/namestdApi'


const { confirm } = Modal

let indexObj: any = {}
let carsid: any = ""

let leftSearch = {
  brand: '',
  attr: 'all'
}
let rightSearch = {
  vin: '',
}
let searchTag = 0
let standard_label = ""
let ris: string = ""

const Categoryoe: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [keyword, setKeyword] = useState<string>("")
  const [brandCode, setBrandCode] = useState<string>('haval')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [vinOpen, setVinOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [vinData, setVinData] = useState<any>([{vin: "", pos: "", oe: ""}])

  const [brandsList, setBrandsList] = useState<any>([])
  const [standardList, setStandardList] = useState<any>([])
  const [attrList, setAttrList] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const [vinList, setVinList] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)

  const [updateType, setUpdateType] = useState<number>(1)

  const [farkOpen, setFarkOpen] = useState<any>(false)
  const [vinLoading, setVinLoading] = useState<boolean>(false)


  useEffect(() => {
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    return () => {
      carsid = ""
      leftSearch = {
        brand: '',
        attr: 'all'
      }
      rightSearch = {
        vin: '',
      }
      searchTag = 0
      standard_label = ""
    }
    
  },[])

  useEffect(() => {
    dataApi.getOtherBrandList().then((res: any) => {
      if(res.code === 1) {
        let _options = res.data.map((it: any) => {
          return {
            label: it,
            value: it
          }
        })
        setBrandsList(_options)
      }
    })
    namestdApi.getTopList().then((res: any) => {
      if(res.code === 1) {
        let _options = res.data.map((it: any) => {

          return {
            label: it.name,
            value: it.name,
            ...it
          }
        })
        setStandardList(_options)
        // set_standard_label(_options[0] ? _options[0].value : '')
        standard_label = _options[0] ? _options[0].value : ''

      }
    })
    dataApi.getAttrlist().then((res: any) => {
      if(res.code === 1) {
        let data = res.data
        let _options = Object.keys(data).map((key: string) => {
          return {
            label: data[key],
            value: key
          }
        })
        setAttrList([{label: '全部', value: 'all'}, ..._options])
      }
    })
  },[])

  const setLeftSearch = (data: any) => {
    leftSearch = {...data}
    setChange(change => !change)
  }
  const setRightSearch = (data: any) => {
    rightSearch = {...data}
    setChange(change => !change)
  }


  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      contextMenu: [
        { 
          key: 'price', 
          title: '修改oe', 
          label: 'Ctrl+R', 
          keyCode: 82,
          onClick: moreOeUpdate
        },
      ],
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 19,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',(e: any) => console.log(e))

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      // console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let _carid = rowData[indexObj['carsid']] ? rowData[indexObj['carsid']].text : ''
        carsid = _carid
        if(ci === indexObj['oe']) {
          // setOpen(true)
        }else if(ci === indexObj['vin'] && rowData[indexObj['vin']]) {
          let brand = rowData[indexObj['brand']] ? rowData[indexObj['brand']].text : ''
          setVinLoading(true)
          setVinList([])
          dataApi.getVinList({brand: brand || leftSearch.brand, carsid: carsid}).then((res: any) => {
            setVinLoading(false)
            if(res.code === 1) {
              setVinOpen(true)
              setVinList(res.data)
            }
          })
        }else if(ci === indexObj['carremark'] && rowData[indexObj['carremark']]) {
          // getList(searchTag, carid)
          setFarkOpen(true)
        }
      }
      
    })
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    jexcelRef.current.on('cell-enter', (e: any) => console.log(e))
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:S1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const moreOeUpdate = (data: any) => {
    console.log(data)
    setOpen(true)
    let _data = data.filter((it: any) => {
      return it.ci === indexObj['oe'] && it.ri
    })
    console.log('menu-price data:', data)
    if(_data.length === 1) {
      let ri = _data[0].ri
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      carsid = rowData[indexObj['carsid']]?.text || ''
      if(!carsid) return false
      ris = ri + ''
      dataApi.getOeList({carsid: carsid, standard_label}).then((res: any) => {
        if(res.code === 1) {
          res.data.forEach((it: any) => {
            it.pos = it.remark
          })
          setVinData(res.data && res.data.length ? res.data : [{vin: "", pos: "", oe: ""}])
          setOpen(true)
          setUpdateType(1)
        }
      })
    }else {
      let _carid: any = []
      let _ris: any = []
      _data.forEach((it: any) => {
        let ri = it.ri
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let rcarid = rowData[indexObj['carsid']]?.text || ""
        if(rcarid) {
          _carid.push(rcarid)
          _ris.push(ri)
        }
      })
      carsid = _carid.join(",")
      ris = _ris.join(",")
      setOpen(true)
      setUpdateType(2)
      setVinData([{vin: "", pos: "", oe: ""}])
    }
  }
  const getList = (type = searchTag, _carid?: any) => {
    let params: any = { standard_label }
    if(type === 1) {
      params = {
        ...params,
        ...leftSearch,
        _carid
      }
      setRightSearch({})
      searchTag = 1
    }else if(type === 2) {
      params = { ...params, ...rightSearch, _carid }
      setLeftSearch({attr: 'all'})
      searchTag = 2
    }
    setLoading(true)
    dataApi.getCategoryoeList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let column_name_dict: any = res.data.column_name_dict || {}
        let column_name_list: any = res.data.column_name_list || []
        let _header = column_name_list.map((name: string, index: number) => {
          let key = Object.keys(column_name_dict).filter((_key: any) => column_name_dict[_key] === name)[0] || ""
          if(key === 'carsid') {
            key = 'carsid'
          }
          return {
            label: name,
            key
          }
        })
        
        initData(res.data.row_list, [..._header])
      }
    })
  }


  const submit = () => {
    let params = {
      brand: leftSearch.brand,
      standard_label,
      carsid: carsid,
      oes: vinData
    }
    setSubmitLoading(true)
    dataApi.updateOe(params, { headers: {
      'Content-Type': 'application/json'
    } }).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setOpen(false)
        message.success("操作成功")
        let textArr = vinData.map((it: any) => it.oe)
        let text = textArr.join(",")
        let textLen = textArr.length
        ris.split(",").forEach((indexStr: string) => {
          let index: number = parseInt(indexStr)
          jexcelRef.current.datas[0].rows.setCell(index, indexObj['oe'], {
            text: text,
          }, 'all')
          jexcelRef.current.datas[0].rows.setCell(index, indexObj['oenum'], {
            text: textLen,
          }, 'all')
          jexcelRef.current.reRender()
        })
      }
    })


  }
  const setVinDataChange = (item: any, key: string, value: string) => {
    item[key] = value
    setChange(change => !change)
  }

  const handleAttr = (labels: any) => {
    let attrs: any = []
    let labelsArr = labels ? labels.split(",") : []
    attrList.forEach((item: any) => {
      if(labelsArr.length && labelsArr.includes(item.label)) {
        attrs.push(item.value)
      }
    })
    setLeftSearch({...leftSearch, attr: attrs.length ? attrs.join(",") : 'all'})
  }
  return (
    <div className='p10 bgc-fff' >
      <div className='flex-s-c'>
        <div className='flex-s-c'>
          <span>筛选：</span>
          <Select
            style={{ width: 170 }}
            placeholder="品类"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={standardList}
            value={standard_label || undefined}
            onChange={(value: any, option: any) => {standard_label = value;  handleAttr(option.carsrule || "")}}
          />
          <Divider  type="vertical" />
          <Select
            className="mr12"
            style={{ width: 170, marginRight: 12 }}
            placeholder="品牌"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={brandsList}
            value={leftSearch.brand || undefined}
            onChange={(value: any) => setLeftSearch({...leftSearch, brand: value})}
          />
          <Select
            mode="tags"
            className="mr12"
            style={{ width: 380, marginRight: 12 }}
            placeholder="属性"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={attrList}
            value={leftSearch.attr && attrList.length ? leftSearch.attr.split(",") : undefined}
            onChange={(value: any) => setLeftSearch({...leftSearch, attr: value ? value.join(",") : ''})}
          />
          <Button type="primary" ghost onClick={() => {
            if(leftSearch.brand && leftSearch.attr) {
              getList(1)
            }else {
              message.warning("请选择筛选条件！")
            }
          }}>搜索</Button>

        </div>
        <Divider className='mlr30' type="vertical" />
        <div>
          <Input.Search 
            style={{ width: 220 }}
            placeholder='VIN搜索' 
            value={rightSearch.vin} 
            onChange={(e: any) => {
              let _vin: string = e.target.value
              setRightSearch({...rightSearch, vin: _vin.toUpperCase()})
            }}  
            onSearch={() => {
              if(rightSearch.vin && rightSearch.vin.length === 17) {
                getList(2)
              }else {
                message.warning("请输入正确的车架号！")
              }
            }}
          />
        </div>
        
      </div>
      
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title={
          <div className='flex-s-c'>{updateType === 1 ? '单个' : '批量'}车型OE关联<i className='iconfont icon-tianjia p-color pointer ml5' onClick={() => setVinData([...vinData, {vin: "", pos: "", oe: ""}])} /></div>
        }
        open={open}
        width={620}
        onCancel={() => setOpen(false)}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className='mtb20'>
          {
            vinData.map((item: any, index: number) => {
              return (
                <Row className='mt10' align="middle" key={index} gutter={10}>
                  <Col span={updateType === 1 ? 7 : 11}>
                    <Input placeholder='请输入OE号' value={item.oe} onChange={(e: any) => setVinDataChange(item, 'oe', e.target.value)} />
                  </Col>
                  <Col span={updateType === 1 ? 7 : 11}>
                    <Input placeholder='位置/备注' value={item.pos} onChange={(e: any) => setVinDataChange(item, 'pos', e.target.value)} />
                  </Col>
                  {
                    updateType === 1 ? (
                      <Col span={8}>
                        <Input placeholder='请输入VIN号' value={item.vin} onChange={(e: any) => setVinDataChange(item, 'vin', e.target.value)} />
                      </Col>
                    ): null
                  }
                  <Col span={2}>
                    <i className='iconfont icon-jianshao d-color pointer' onClick={() => {
                      let _vinData: any[] = JSON.parse(JSON.stringify(vinData))
                      _vinData.splice(index, 1)
                      setVinData(_vinData)
                    }} />
                  </Col>
                </Row>
              )
            })
          }
        </div>
      </Modal>
      <Modal
        title="vin详细"
        open={vinOpen}
        onCancel={() => setVinOpen(false)}
        footer={null}
      >
        <div className='mtb20'>
          <Table
            columns={[
              {dataIndex: 'carsid', title: '车型id'},
              {dataIndex: 'vin', title: '车架号'},
              {dataIndex: 'remark', title: '备注'},
            ] as any}
            loading={vinLoading}
            dataSource={vinList}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </div>
      </Modal>
      {
        farkOpen ? (
          <Fark
            onCancel={() => setFarkOpen(false)}
          />
        ): null
      }
    </div>
  )
}

export default Categoryoe


let indexObj1: any = {}

const Fark: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { onCancel } = props
  const [loading, setLoading] = useState<boolean>(false)

  const [vinLoading, setVinLoading] = useState<boolean>(false)
  const [vinList, setVinList] = useState<any>([])
  const [vinOpen, setVinOpen] = useState<boolean>(false)

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  useEffect(() => {
    initExcel()
    setTimeout(() => {
      getList()
    },100)
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  const getList = (type = searchTag) => {
    let params: any = { standard_label }
    if(type === 1) {
      params = {
        ...params,
        ...leftSearch,
        carsid,
        attr: 'all'
      }
    }else if(type === 2) {
      params = { ...params, ...rightSearch, carsid, attr: 'all' }
    }
    setLoading(true)
    dataApi.getCategoryoeList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let column_name_dict: any = res.data.column_name_dict || {}
        let column_name_list: any = res.data.column_name_list || []
        let _header = column_name_list.map((name: string, index: number) => {
          let key = Object.keys(column_name_dict).filter((_key: any) => column_name_dict[_key] === name)[0] || ""
          if(key === 'carsid') {
            key = 'carsid'
          }
          return {
            label: name,
            key
          }
        })
        
        initData(res.data.row_list, [..._header])
      }
    })
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj1 = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj1[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname + '1')
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'read', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 19,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)


    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let _carid = rowData[indexObj1['carsid']] ? rowData[indexObj1['carsid']].text : ''
        carsid = _carid
        if(ci === indexObj1['oe']) {
          // setOpen(true)
        }else if(ci === indexObj1['vin'] && rowData[indexObj1['vin']]) {
          let brand = rowData[indexObj1['brand']] ? rowData[indexObj1['brand']].text : ''
          setVinLoading(true)
          setVinList([])
          dataApi.getVinList({brand: brand || leftSearch.brand, carsid: carsid}).then((res: any) => {
            setVinLoading(false)
            if(res.code === 1) {
              setVinOpen(true)
              setVinList(res.data)
            }
          })
        }else if(ci === indexObj1['carremark'] && rowData[indexObj1['carremark']] && rowData[indexObj1['carremark']].text) {
          // getList(searchTag, carid)
          // setFarkOpen(true)
        }
      }
      
    })


    jexcelRef.current.validate()
  }
  return (
    <>

      <Modal
        open={true}
        onCancel={() => onCancel()}
        width={1000}
        footer={null}
        centered
      >
        <Spin spinning={loading}>
          <div className='mt10' style={{ height: 'calc(100vh - 200px)' }} ref={jexcelDivRef} id={pathname + '1'}></div>
        </Spin>
      </Modal>
      <Modal
        title="vin详细"
        open={vinOpen}
        onCancel={() => setVinOpen(false)}
        footer={null}
      >
        <div className='mtb20'>
          <Table
            columns={[
              {dataIndex: 'carsid', title: '车型id'},
              {dataIndex: 'vin', title: '车架号'},
              {dataIndex: 'remark', title: '备注'},
            ] as any}
            loading={vinLoading}
            dataSource={vinList}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </div>
      </Modal>
    </>
    
  )
}

