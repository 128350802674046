import React, { useContext, useState } from "react";
import { Input } from 'antd'
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom'
import MainContext from "@/context/MainContext";
import Breadcrumb from "./Breadcrumb";


const Vincon: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
  const { vinData } = store
  const {  } = props
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const [vin, setVin] = useState<string>(vinData.vin || "")
  const [loading, setLoading] = useState<boolean>(false)

  const search = () => {
    navigate(`/dataedit/vin?vin=${vin}`,{replace: true})
    setStore({ type: 'routekey', value: store.routekey + 1 })
    setStore({ type: 'vinData', value: {} })
    sessionStorage.removeItem("vinData")
    setStore({ type: "crumb", value: [] })
    sessionStorage.removeItem("crumb")
    setStore({ type: 'editKey', value: '' })
  }
  return (
    <div className="bgc-fff p10">
      <Input.Search 
        placeholder="输入17位VIN车架号"
        size="large"
        allowClear
        value={vin} 
        onChange={(e: any) => {
          let _vin: string = e.target.value
          setVin(_vin ? _vin.toUpperCase() : '')
        }} 
        enterButton="车架号查询"
        onSearch={() => search()}
      />
      <Breadcrumb  />
      <Outlet key={store.routekey}  />
    </div>
  )
}

export default Vincon