import { Button, Col, Row, Switch, Modal, Input, message, Checkbox, Spin, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import styles from './index.module.scss'
import accountApi from '@/api/accountApi'




const Role: React.FC<any> = (props) => {

  const [change, setChange] = useState<boolean>(false)
  const [lfLoading, setLfLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [editData, setEditData] = useState<any>({})

  const [roleList, setRoleList] = useState<any>([])
  const [activeRole, setActiveRole] = useState<any>({})

  const [list, setList] = useState<any[]>([])
  const [role_ids, set_role_ids] = useState<any>([])

  useEffect(() => {
    getRoleList()
    // getAllAuth()
  },[])

  useEffect(() => {
    if(activeItem.id) {
      getAuth()
    }
  },[activeItem])

  const getRoleList = () => {
    setLfLoading(true)
    accountApi.getRoleList({ pagesize: 100000 }).then((res: any) => {
      setLfLoading(false)
      if(res.code === 1) {
        let _data: any[] = res.data.records ? res.data.records : []
        setRoleList(_data)
        if(!activeItem.id) {
          setActiveItem(_data[0] || {})
        }
      }
    }).catch(() => {
      setLfLoading(false)
    })
  }

  const getAllAuth = () => {
    accountApi.getPermissionList().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data.records ? res.data.records : []
        let _list: any = []
        _data.forEach((item: any) => {
          let i = _list.findIndex((it: any) => it.parent_permission_id === item.parent_permission_id)
          if(i === -1) {
            _list.push({
              name: item.parent_permission_name,
              parent_permission_id: item.parent_permission_id,
              children: [item]
            })
          }else {
            _list[i].children.push(item)
          }
        })
        setList(_list)
      }
    })
  }

  const getAuth = () => {
    setLoading(true)
    accountApi.getRolePermission({ role_id: activeItem.id }).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _data = (res.data ? res.data : []).filter((it: any) => it.id && it.parent_permission_id && it.permission_name)
        let _list: any = []
        _data.forEach((item: any) => {
          if(item.permission_type === 'all' || item.permission_type === 'write') {
            item.aingle = [
              {status: 1},
              {status: 1}
            ]
          }else if(item.permission_type === 'read') {
            item.aingle = [
              {status: 1},
              {status: 0}
            ]
          }else {
            item.aingle = [
              {status: 0},
              {status: 0}
            ]
          }
          let i = _list.findIndex((it: any) => it.parent_permission_id === item.parent_permission_id)
          if(i === -1) {
            _list.push({
              name: item.parent_permission_name,
              parent_permission_id: item.parent_permission_id,
              children: [item]
            })
          }else {
            _list[i].children.push(item)
          }
        })
        setList(_list)


        // res.data.forEach((items: any) => {
        //   let children: any = []
        //   let permission_catalog_id_arr: any = []
        //   items.list.forEach((item: any) => {
        //     if(!permission_catalog_id_arr.length || !permission_catalog_id_arr.includes(item.permission_catalog_id)) {
        //       permission_catalog_id_arr.push(item.permission_catalog_id)
        //     }
        //   })
          
        //   permission_catalog_id_arr.forEach((id: any) => {
        //     let aingle: any = []
        //     let name = ''
        //     items.list.forEach((item: any) => {
        //       if(item.permission_catalog_id === id) {
        //         if(item.operation === 'read') {
        //           aingle[0] = item
        //         }else {
        //           aingle[1] = item
        //         }
        //         name = item.catalog_name
        //       }
        //     })
        //     children.push({
        //       name,
        //       aingle
        //     })
        //   })
          
        //   items.children = children
        // })
        // setList(res.data)
      }
    })
  }

  const save = () => {
    let data: any = []
    list.forEach((items: any) => {
      items.children.forEach((item: any) => {
        item.aingle.forEach((ai: any) => {
          if(ai.status) {
            data.push(ai)
          }
        })
      })

    })
    let params = {
      role_id: activeItem.role_id,
      data
    }
    setSaveLoading(true)
    accountApi.updateRolePermission(params).then((res: any) => {
      setSaveLoading(false)
      if(res.code === 1) {
        message.success("操作成功！")
      }
    }).catch(() => {
      setSaveLoading(false)
    })

  }

  const addRole = () => {
    setEditData({})
    setVisible(true)
  }
  const editRole = (e: any, item: any) => {
    e.stopPropagation()
    setEditData(JSON.parse(JSON.stringify(item)))
    setVisible(true)
  }
  const delRole = (id: any) => {
    editData.loading = true
    setChange(change => !change)
    accountApi.delRole({ del_id: id }).then((res: any) => {
      editData.loading = false
      setChange(change => !change)
      if(res.code === 1) {
        setVisible(false)
        message.success("删除成功")
        getRoleList()
      }
    })
  }



  const submit = () => {
    let params = {
      ...editData,
    }
    params.edit_id = editData.id
    delete editData.id
    setSubmitLoading(true)
    accountApi.updateRole(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("操作成功！")
        getRoleList()
        setVisible(false)
      }
    }).catch(() => {
      setSubmitLoading(false)
    })
  }

  const updateRolePer = (item: any, i: any, value: any) => {
    console.log(item, i, value)
    let _action = ''
    if(i) {
      if(value) {
        _action = 'write'
      }else {
        _action = 'read'
      }
    }else {
      let opeateStatus = item.aingle[1].status
      if(value) {
        if(opeateStatus) {
          _action = 'write'
        }else {
          _action = 'read'
        }
      }else {
        if(opeateStatus) {
          _action = 'write'
        }
      }
    }
    let params = {
      role_id: activeItem.id + '',
      permission_id: item.id + '',
      action: _action
    }
    item.aingle[i].loading = true
    setChange(change => !change)
    accountApi.updateRolePermission(params).then((res: any) => {
      if(res.code === 1) {
        item.aingle[i].loading = false
        item.aingle[i].status = value
        setChange(change => !change)
        message.success("操作成功")
      }else {
        item.aingle[i].loading = false
        setChange(change => !change)
      }
    })
  }
  return (
    <div className={styles['role'] + ' p15 min-content-height1'} >
      <div className={styles['role-lf']}>
        <Button onClick={addRole} style={{ width: '100%' }} type="primary"  ghost icon={<i className="iconfont icon-guanlian" />} >添加角色</Button>
        <Spin spinning={lfLoading}>
          <div className={styles['tab-list']}>
            {
              roleList.map((item: any, index: number) => {
                return (
                  <div key={index} onClick={() => setActiveItem(item)} className={styles['tab'] + " " + `${item.id === activeItem.id ? styles['active'] : ''}` }>
                    <span>{item.role_name}</span>
                    {
                      item.id !== activeItem.id ? (
                        <i className="iconfont icon-bianji fs14 p-color  mr2 pointer" onClick={(e: any) => editRole(e, item)} />
                      ): null
                    }
                  </div>
                )
              })
            }
          </div>
        </Spin>
      </div>
      <div className={styles['role-rt']} key={activeItem.role_id}>
        <Spin spinning={loading}>
        {
          list.map((items: any, indexs: number) => {
            return (
              <div className={styles['items'] + ' mb10'}>
                <div className={styles['items-head']}>
                  <div className="flex-s-c">
                    <i className="iconfont icon-chanpinguanli mr5"/> 
                    <span>{items.name}</span>
                    <i className="iconfont icon-xiangqing-copy ml15 pointer"/>
                  </div>
                  <div className={styles['operate']}>
                    <div>查看</div>
                    <div>操作</div>
                  </div>
                </div>
                <div className={styles['items-body']}>
                  {
                    items.children.map((item: any, index: number) => {
                      return (
                        <div className={styles['item']}>
                          <div>{item.permission_name}</div>
                          <div className={styles['operate']}>
                            {
                              item.aingle.map((ain: any, i: number) => {
                                return (
                                  <div>
                                    <Switch loading={item.aingle[i].loading ? true : false} checkedChildren="开启" unCheckedChildren="关闭" checked={ain.status} onChange={(value: any) => updateRolePer(item, i, value)} />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
        </Spin>
        {/* <div style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => save()} loading={saveLoading}>保存</Button>
        </div> */}
      </div>




      <Modal
        title={editData.id ? '修改角色' : '添加角色'}
        visible={visible}
        onCancel={() => setVisible(false)}
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={submit}
      >
        <div className="mtb20" >
          <div className='flex-s-c'>
            <label style={{ width: 100 }}><i className="red">*</i>角色名称：</label>
            <div className="flex-1">
              <Input value={editData.role_name} placeholder='输入角色名称' onChange={(e) => setEditData({...editData, role_name: e.target.value})} />
            </div>
          </div>
          {
            editData.id ? (
              <div className="right mt20">
                <Popconfirm
                  title="确认删除这个角色吗？"
                  onConfirm={() => delRole(editData.id)}
                  okButtonProps={{
                    loading: editData.loading ? true : false
                  }}
                >
                  <i className="iconfont icon-shanchu red pointer" />
                </Popconfirm>
              </div>
            ): null
          }
        </div>
      </Modal>
    </div>
  )
}

export default Role