import axios from 'axios'
import qs from 'qs'

const vinApi: any = {}

vinApi.vin = (params: any): Promise<{}>=>{
  params.yc_id = '11111111111'
  return axios.get('/v1/operate/cars/vin', { params })
}
vinApi.group = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/main/group/list', { params })
}
vinApi.structure = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/structure', { params })
}
vinApi.subGroup = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/main/sub/group', { params })
}
vinApi.config = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/config/search', { params })
}
vinApi.points = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/structimgs/points", {params})
}
vinApi.partnew = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/partnew", {params})
}
vinApi.part = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/part", {params})
}

vinApi.config = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/config", {params})
}
vinApi.configSearch = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/config/search", {params})
}
vinApi.tag = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/part/tags", {params})
}
vinApi.getStandardLabel = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/lable/get", {params})
}

vinApi.baseinfo = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/baseinfo", {params})
}
vinApi.replace = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/replace", {params})
}
vinApi.stocks = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/user/zhb/match/stocks/simple", params)
}
vinApi.articles = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/articles", {params})
}
vinApi.applicable = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/generacars", {params})
}
vinApi.pics = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/pics", {params})
}
vinApi.removePic = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/picdel", {params})
}

vinApi.getMaintenancePoint = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/maintenance/sub/group/struct", {params})
}
vinApi.getMaintenancePart = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/maintenance/sub/group/parts", {params})
}

vinApi.getVinHistory = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/epc/history/vin", {params})
}
vinApi.getPartHistory = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/history/parts", {params})
}

vinApi.getBrand = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/brands", {params})
}
vinApi.getSearchCount = () => {
  return axios.get('/v1/operate/user/part/search/rank')
}
vinApi.getTitle = (params: {}) => {
  return axios.get('/v1/operate/cars/brand/title', {params})
}
vinApi.getModels = (params: {}, cancelToken: any): Promise<{}> => {
  return axios.get("/v1/operate/cars/models", {...{params}, ...cancelToken})
}
vinApi.ocr = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/vin/ocr", params)
}

vinApi.upkeep = (params: {}): Promise<{}> => {
  return axios.get('/v1/operate/epc/vin/oil/products', {params})
}

vinApi.getSpecialParts = (params: {}) => {
  return axios.get('/v1/operate/special/category/list_v2', {params})
}
vinApi.updateVinConfig = (params: any) => {
  return axios.post('/v1/operate/cars/vin/edit', params)
}

vinApi.updateGroup = (params: any) => {
  return axios.post('/v1/operate/cars/main/group/list/edit', params)
}

vinApi.updateStructure = (params: any) => {
  return axios.post('/v1/operate/cars/structure/edit', params)
}
vinApi.updatePartList = (params: any) => {
  return axios.post('/v1/operate/cars/part/edit', params)
}
vinApi.updateImgVer = (params: any) => {
  return axios.post('/v1/operate/cars/structure/img/switch', params)
}

vinApi.ocrPath = '/v1/operate/vin/ocr'
export default vinApi