import React, { useState, useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Routes from '@/Routes'
import MainContext from '@/context/MainContext'
import ResInterceptors from '@/components/ResInterceptors'
import { Header, Menu } from './'
import dataApi from '@/api/dataApi'


import mainScss from './main.module.scss'


const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'userInfo':
      return {
        ...state,
        userInfo: action.value
      }
    case 'brandsList':
      return {
        ...state,
        brandsList: action.value
      }
    case 'brandData': 
      return {
        ...state,
        brandData: action.value
      }
    case 'writeAuth': 
      return {
        ...state,
        writeAuth: action.value
      }
    case 'menuData': 
      return {
        ...state,
        menuData: action.value
      }
    case 'vinData': 
      return {
        ...state,
        vinData: action.value
      }
    case 'routekey': 
      return {
        ...state,
        routekey: action.value
      }
    case 'crumb':
      return {
        ...state,
        crumb: action.value
      }
    case 'param':
      return {
        ...state,
        param: action.value
      }
    case 'editKey':
      return {
        ...state,
        editKey: action.value
      }
    default: 
      return state
  }
}

const Main: React.FC = () => {
  const other = ['/login', '/register', '/otest']
  const { pathname } = useLocation()
  const [key, setKey] = useState<number>(1)
  
  const [store, setStore] = useReducer(reducer, {
    userInfo: JSON.parse(window.localStorage.getItem("userInfo") || '{}'),
    brandsList: JSON.parse(window.localStorage.getItem("brandsList") || '[]'),
    writeAuth: JSON.parse(window.localStorage.getItem("writeAuth") || '{}'),
    brandData: JSON.parse(window.localStorage.getItem("brandData") || '[]'),
    menuData: JSON.parse(window.localStorage.getItem("menuData") || '[]'),
    vinData: JSON.parse(window.sessionStorage.getItem('vinData') || '{}'),
    routekey: 1,
    crumb: JSON.parse(window.sessionStorage.getItem('crumb') || '[]'),
    param: JSON.parse(window.sessionStorage.getItem('param') || '{}'),
    editKey: '',
  })

  useEffect(() => {
    if(other.indexOf(pathname) === -1 && pathname !== '/') {
      getBrandsList()
      getBrandData()
    }
  },[])

  const getBrandsList = () => {
    dataApi.getBrandsList().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data.map((item: any) => {
          return {
            label: item,
            value: item
          }
        })
        localStorage.setItem("brandsList", JSON.stringify(_data))
        setStore({value: _data, type: 'brandsList'})
      }
    })
  }

  const getBrandData = () => {
    dataApi.getBrandData().then((res: any) => {
      if(res.code === 1) {
        setStore({ value: res.data, type: 'brandData' })
        localStorage.setItem("brandData", JSON.stringify(res.data))
      }
    })
  }

  const update = () => {
    setKey(key+1)
  }

  return (
    <MainContext.Provider value={{store, setStore}}>
      <>
        <ResInterceptors />
        {  
          other.indexOf(pathname) === -1 && pathname !== '/' ? (
            <div className={mainScss.main} style={{backgroundImage: 'url(/admin/image/bg_2.png)'}}>
              <Header />
              <Menu />
              <div className={mainScss.content}>
                <Routes key={key} />
              </div>
            </div>
          ): (
            <Routes />
          )
        }
      </>
    </MainContext.Provider>
  )
}

export default Main