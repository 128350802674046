import React, { useEffect, useState } from "react";
import { Select, Steps, Button, message, DatePicker } from 'antd'
import UploadModel from "@/components/UploadModel";
import dataOperationApi from "@/api/dataOperationApi";

import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

let options = () => {
  let _options: any = []
  for(let i = 65; i < 91; i++) {
    _options.push({
      label: String.fromCharCode(i),
      value: String.fromCharCode(i)
    })
  }
  return _options
}

let columns = [
  {label: '文件', key: 'fileurl', must: 1},
  {label: 'OEM', key: 'oem', must: 1},
  {label: '名称', key: 'name', must: 1},
  {label: '进价（不含税）', key: 'factory_price', must: 0},
  {label: '进价（含税）', key: 'purchase_price', must: 0},
  {label: '4S销售价', key: 'price', must: 0},
  {label: '品牌', key: 'brand', must: 1},
  {label: '价格时间', key: 'date', must: 1},
]

const Fourprice: React.FC<any> = (props) => {

  const [step, setStep] = useState<number>(0)
  const [activeItem, setActiveItem] = useState<any>({})
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [brandOptions, setBrandOptions] = useState<any>([])


  useEffect(() => {
    getBrandOptions()
  },[])

  const getBrandOptions = () => {
    dataOperationApi.getBrandOptions().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data.map((it: any) => {
          return {
            label: it,
            value: it
          }
        })
        setBrandOptions(_data)
      }
    })
  }
  
  const submit = () => {
    let params = {
      ...activeItem
    }
    let is = false
    columns.forEach((item: any) => {
      let key = item.key
      if(!params[key] && item.must) is = true
    })
    if(is) {
      message.warning("请选择必填项！")
      return false
    }
    setSubmitLoading(true)
    dataOperationApi.updateFourPrice(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success("提交成功！")
        // setStep(1)
      }
    })
    return false
  }
  
  return (
    <div className="bgc-fff p10 min-content-height1">
      <Steps
        current={step}
        onChange={(value) => setStep(value)}
        items={[
          {
            title: '第 1 步，上传价格文件并配置关联属性'
          },
          // {
          //   title: '第 2 步，等待上线'
          // },
        ]}
      />
      {
        step === 0 ? (
          <div className="mt20">
            <div className="mt15">
              <div className="flex-s-c ptb10 b" style={{ background: '#F8F8F8', border: '1px solid #E9E9E9' }}>
                <span style={{ width: '40%', textAlign: 'center' }}>属性</span>
                <span style={{ width: '60%', textAlign: 'center' }}>关联列序号</span>
              </div>
              <div>
                {
                  columns.map((item: any, index: number) => {
                    let key: string = item.key
                    return (
                      <div key={index} className="flex-s-c ptb5" style={{ border: '1px solid #E9E9E9', borderTop: 'none' }}>
                        <span style={{ width: '40%', textAlign: 'center' }}>{item.label}</span>
                        <span className="flex-c-c" style={{ width: '60%', textAlign: 'center' }}>
                          <i className="red" style={{ opacity: item.must ? 1 : 0, marginRight: 10 }}>*</i>
                          {
                            key === 'fileurl' ? (
                              <UploadModel
                                width={500}
                                item={activeItem}
                                lkey="fileurl"
                                uploadLabel="上传文件"
                              />
                            ): key === 'date' ? (
                              <DatePicker
                                size="large"
                                style={{ width: 360 }}
                                value={activeItem.date ? dayjs(activeItem.date, 'YYYY-MM-DD') : undefined}
                                onChange={(date: any, dateString: any) => setActiveItem({...activeItem, date: dateString || ""})}
                              />
                            ): (
                              <Select
                                showSearch
                                size="large"
                                placeholder={key === 'brand' ? `选择品牌${item.must ? '（必选）': ''}` : `选择对应列序号${item.must ? '（必选）': ''}`}
                                style={{ width: 360 }}
                                options={key === 'brand' ? brandOptions : options()}
                                value={activeItem[key]}
                                onChange={(value) => setActiveItem({...activeItem, [key]: value})}
                              />
                            )
                          }
                          
                        </span>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>

            <div className="mt30 center">
              <Button size="large" style={{ width: 500 }} type="primary" loading={submitLoading} onClick={() => submit()} >确定</Button>
            </div>
          </div>
        ): step === 1 ? (
          <div>

          </div>
        ): null
      }
    </div>
  )
}

export default Fourprice