import React from 'react'
import { Button, Input } from 'antd'
import styles from './index.module.scss'

const Repairdoc: React.FC<any> = (props) => {
  return (
    <div className={styles['repairdoc'] + ' br4'}>
      <div className={styles['list'] + ' p10'}>
        <div className={styles['item'] + ' flex'}>
          <div className={styles['item-left']}>
            <i className="iconfont icon-tianjia pointer p-color mt10"  />
            <i className="iconfont icon-jianshao pointer d-color mt10"/>
          </div>
          <div className={styles['item-right']}>
            <div className={styles['title'] + ' flex-s-c br4 pl10'}>
              我是标题名称
              <i className="iconfont icon-bianji pointer ml5 p-color fs12 " />
            </div>
            <div className="p10">
              <Input.TextArea style={{ minHeight: 80 }} />
              <div className={styles['operate']}>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['tools'] + ' flex-c-c'}>
        <Button className='mr20' type="primary" ghost>预览</Button>
        <Button type="primary">保存</Button>
      </div>
    </div>
  )
}

export default Repairdoc