import React, { Fragment, useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useParams } from 'react-router-dom'
import Datatopbox from './Components/Datatopbox'
import UploadModel from '@/components/UploadModel'
import { downloadFile } from '@/utils/utils'
import ThreedPart from './Components/ThreedPart'
import axios from 'axios'



const ThreedModel: React.FC<any> = (props) => {
  const { path } = useParams()
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [ispreview, setIspreview] = useState<boolean>(false)
  const [list, setList] = useState<any>([{vin: 'WDC0J8BB6LF748077', id: 1}])
  const [activeItem, setActiveItem] = useState<any>({})

  const [currencyData, setCurrencyData] = useState<any>({url: ''})
  
  
  const [searchData, setSearchData] = useState<any>({
    brand: '',
    models: '',
    isrelationModels: ''
  })

  const [activeKey, setActiveKey] = useState<'model' | 'part'>("model")

  
  
  useEffect(() => {
    getList()
  },[searchData.brand, searchData.models, searchData.isrelationModels, pager, pageSize])

  const getPathConfig = () => {
    let params = {
      path
    }
    axios.get("", {params}).then((res: any) => {
      if(res.code === 1) {
        getList()
      }
    })
  }

  const getList = () => {

  }

  const editItem = (item: any) => {
    setActiveItem({...item})
    setOpen(true)
  }
  const closeOpen = () => {
    setActiveItem({})
    setIspreview(false)
    setOpen(false)
  }

  const preview = (item: any) => {
    editItem(item)
    setIspreview(true)
  }
  const downLoad = (item: any) => {
    downloadFile(item.url)
  }

  const submit = () => {
    closeOpen()
  }

  const submitCurrency = () => {

  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '品牌',
      dataIndex: 'id',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            
          </div>
        )
      }
    },
    {
      title: '车系',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '已关联车型',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '示例车架号',
      dataIndex: 'vin',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c p-color">
            {text}
          </div>
        )
      }
    },
    {
      title: '大小 (MB)',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 220,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            <div className="flex-s-c p-color pointer" onClick={() => preview(record)}>
              <i className="iconfont icon-yulan fs14 mr2" />
              预览
            </div>
            <div className="flex-s-c p-color pointer mlr15" onClick={() => downLoad(record)}>
              <i className="iconfont icon-xiazai fs14 mr2" />
              下载
            </div>
            <div className="flex-s-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
          </div>
          
        )
      }
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: 'model',
      label: '模型',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <div className='flex-s-c'>
              <span>筛选：</span>
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="品牌"
                value={searchData.brand || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, brand: value}) }}
              />
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="车系"
                value={searchData.models || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, models: value}) }}
              />
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="是否关联车型"
                value={searchData.isrelationModels || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, isrelationModels: value}) }}
              />
              
            </div>
            <div>
              <Button onClick={() => setCurrencyOpen(true)} className="mr15" type="primary" ghost>通用模型</Button>
              <Button onClick={() => { setOpen(true); setActiveItem({}) }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />}>添加模型</Button>
            </div>
          </div>
          <div className="mt10">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
    {
      key: 'part',
      label: '零件',
      children: <ThreedPart/>
    },
  ]
  return (
    <div >
      <Datatopbox

      />
      <div className="mt8 bgc-fff plr15 pb10 br4" style={{ minHeight: 'calc(100vh - 140px)' }}>
        <Tabs
          items={items}
          activeKey={activeKey}
          onChange={(value: any) => setActiveKey(value)}
        />
      </div>

      <Modal
        title={ispreview ? '预览模型' : activeItem.id ? '修改模型' : "添加模型"}
        open={open}
        width={460}
        onCancel={() => closeOpen()}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
        {...(ispreview ? {footer: null} : {})}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>品牌：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择品牌"
              size="large"
              disabled={ispreview}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>车系：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择车系"
              size="large"
              disabled={ispreview}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>模型：</label>
            <div className='flex-1'>
              <UploadModel
                item={{url: ''}}
                disabled={ispreview}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="通用模型"
        width={460}
        open={currencyOpen}
        onCancel={() => setCurrencyOpen(false)}
        onOk={() => submitCurrency()}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className="mtb20">
          <div className='flex-s-c'>
            <label className="right" style={{ width: 100 }}>两厢：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
          <div className='flex-s-c mt10'>
            <label className="right" style={{ width: 100 }}>三厢：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
          <div className='flex-s-c mt10'>
            <label className="right" style={{ width: 100 }}>轿跑：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
          <div className='flex-s-c mt10'>
            <label className="right" style={{ width: 100 }}>SUV：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
          <div className='flex-s-c mt10'>
            <label className="right" style={{ width: 100 }}>牵引车：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
          <div className='flex-s-c mt10'>
            <label className="right" style={{ width: 100 }}>载重车：</label>
            <div className="flex-1">
              <UploadModel
                item={currencyData}
              />
            </div>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default ThreedModel