import axios from 'axios'
import qs from 'qs'

const commonApi: any = {}

commonApi.logout = () => {
  return axios.get('/logout')
}

commonApi.getModel = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/maintenance/chassis/check", {params})
}
commonApi.getDetail = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/maintenance/label/details", {params})
}
commonApi.getLabel = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/maintenance/getlabels", {params})
}
commonApi.login = (params: {}): Promise<{}> => {
  return axios.post('/v1/operate/web/user/login', params)
}

commonApi.searchKey = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/engine/parts/search_key", qs.stringify(params))
}

commonApi.searchDefaultHot = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/parts/default_hot_name", {params})
}
commonApi.searchHot = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/engine/parts/search_hot_name", {params})
}
commonApi.uploadQrcode = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/qrcode", {params})
}



commonApi.uploadPath = '/v1/operate/user/ugc/part/picupload'
commonApi.upload = (params: {}): Promise<{}>=>{
  return axios.post(commonApi.uploadPath, params)
}



commonApi.getUploadObs = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/common/obs/signature", {params})
}
export default commonApi