import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { clearAllCookies } from '@/utils/utils'
const w: any = window



const otherCode = [1, 4005, 4001, 205, 10]

let ResInterceptors: React.FC = () => {
  const navigate = useNavigate()
  useEffect(()=>{
    init()
  },[])

  const init = () => {
    axios.interceptors.request.use((config: any) => {
      // config.headers['language'] = w.hostname || 'en'
      config.headers['token'] = window.localStorage.getItem("token") || ''
      if(/get/i.test(config.method)){
        const date: any = new Date()
        config.params = config.params || {}
        config.params.noCacheTime = Date.parse(date)/1000
      }

      return config
    }, (err: any) => {
      return Promise.reject(err);
    })

    axios.interceptors.response.use((response: any) => {
      // console.log(response)
      if(response.data.code !== 1) {
        if(response.config.url === 'https://file-big.obs.cn-south-1.myhuaweicloud.com/') {
          return false
        }
        message.error(response.data.msg)
      }
      if(response.data.code === 205) {
        navigate("/login")
      }
      return response.data
    }, (err: any) => {
      return Promise.reject(err)
    })
  }

  return null
}

export default ResInterceptors;



