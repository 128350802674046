import React, { useState, useEffect, useReducer } from 'react'
import { Rnd } from "react-rnd"
import css from './modal.module.scss'

interface PropsIn {
  title: string,
  visible: boolean,
  cancel: Function,
  children: any
}

const Modal: React.FC<PropsIn> = (props) => {
  const [ rndRect, setRndRect ] = useState<any>(JSON.parse(window.localStorage.getItem('rndRect') || '{}'))

  return (
    props.visible ? (
      <Rnd
        style={{zIndex: 100000}}
        position={{ x: rndRect.x || 0, y: rndRect.y || 0 }}
        size={{ width: rndRect.w || 500, height: rndRect.h || 500 }}
        onDragStop={(e: any, d: any) => {
          let temp = JSON.parse(JSON.stringify(rndRect))
          temp.x = d.x
          temp.y = d.y
          setRndRect(temp)
          window.localStorage.setItem('rndRect', JSON.stringify(temp))
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          let temp = {
            w: parseInt(ref.style.width),
            h: parseInt(ref.style.height),
            x: position.x,
            y: position.y
          }
          setRndRect(temp)
          window.localStorage.setItem('rndRect', JSON.stringify(temp))
        }}
      >
        <div className={css.rndModal}>
          <div className={css.head}>
            <h6>{props.title}</h6>
            <i className='iconfont icon-close' onClick={()=>props.cancel(false)}></i>
          </div>
          {props.children}
        </div>
      </Rnd>
    ): null
  )
}

export default Modal;