import React from 'react'

const Pagecommon: React.FC<any> = (props) => {
  return (
    <div>
      apple
    </div>
  )
}

export default Pagecommon