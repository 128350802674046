import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import MainContext from '@/context/MainContext'
import Tooltip from 'rc-tooltip'
import { PartDetail, SearchPartInput, PartSearch } from '@/page/Dataedit/Vin'
import Modal, { RndModal } from '@/components/Modal'
import Toast from '@/components/Toast'
import Exploded, { Lite } from '@/components/Exploded'
import Copy from '@/components/Copy'
import Loading from '@/components/Loading'
import { cdnsImgPath, getUrlParams, removeQueryParam } from '@/utils/utils'
import { parabola } from "@/utils/parabola"
import axios from 'axios'
import vinApi from '@/api/vinApi'
import css from './vin.module.scss'
import styles from './partspriceNew.module.scss'


import { Button, Input, message, Select } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

interface PropsIn {
  brandcode: string,
  mcid: string,
  num: string,
  update?: Function,
  vin?: string,
  index?: string,
  subindex?: string,
  type?: string,
  subgroup?: string,
  mid?: string,
  pid?: string,
  finalid?: string,
  backFn?: Function
}

let getPartUpload = false
let getPartTag = false


const VinDetail: React.FC<PropsIn> = (props) => {
  const navigate = useNavigate();
  const { oe } = getUrlParams()
  const { vin = '', brandcode, mcid, num, index, subindex, type, subgroup, mid = '', pid, backFn, finalid } = props
  const { store, setStore } = useContext(MainContext)
  const { crumb, vinData, selectOE } = store

  const boxContentRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  
  const [ structure, setStructure ] = useState<any>(JSON.parse(sessionStorage.getItem('structure') || '[]'))
  const [ subGroup, setSubGroup ] = useState<any>(JSON.parse(sessionStorage.getItem('subGroup') || '[]'))

  const [ data, setData ] = useState<any>({})
  const [ activeHsKey, setActiveHsKey ] = useState<string>('')

  const [ cntIndex, setCntIndex ] = useState<any>([])
  const [ partKey, setPartKey ] = useState<string>('')
  
  const [ activePart, setActivePart ] = useState<any>({})
  const [ detailPart, setDetailPart ] = useState<any>({})
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ config, setConfig ] = useState<any>([])
  
  const [ scrollTo, setScrollTo ] = useState<boolean>(true)
  const [ partDetailVisible, setPartDetailVisible ] = useState<boolean>(false)

  const [trendData, setTrendData] = useState<any>([])
  const [trendVisible, setTrendVisible] = useState<boolean>(false)
  const [trendLoading, setTrendLoading] = useState<boolean>(false)

  const [rpVisible, setRpVisible] = useState<boolean>(false)
  const [rpLoading, setRpLoading] = useState<boolean>(false)
  const [replaceData, setReplaceData] = useState<any>({})
  
  const [cbVisible, setCbVisible] = useState<boolean>(false)
  const [cburl, setCbUrl] = useState<string>(window.sessionStorage.getItem('cburl') || '')
  
  const cbname = window.sessionStorage.getItem('cbname')
  const cbicon = window.sessionStorage.getItem('cbicon')
  
  const [ activeRndPart, setActiveRndPart ] = useState<any>({})

  const [change, setChange] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [partListDetail, setPartListDetail] = useState<any>([])

  const [submitLoading, setSubmitLoading] = useState<any>({
    save: false,
    submit: false
  })

  const [otherLoading, setOtherLoading] = useState<boolean>(false)

  const [updateParams, setUpdateParams] = useState<any>({})
  const [imgVer, setImgVer] = useState<string>("")

  const isEditRef = useRef<any>(null)

  const initPartItem = {
    "pnum": "",
    "psort": 0,
    "sa_code": "",
    "refmid": [],
    "itid": "--",
    "pid": "",
    "label": "",
    "remark": "",
    "quantity": "",
    "model": "",
    "iscomt": "",
    "has_article": "",
    "isreplace": "",
    "condition": "",
    "colorvalue": 1,
    "spid": ""
  }
  const [addPartList, setAddPartList] = useState<any>([
    {
      ...initPartItem
    }
  ])

  useEffect(() => {
    isEditRef.current = isEdit
  },[isEdit])

  useEffect(()=>{
    if(type === 'partSearch' || structure.length || subGroup.length){
      if(finalid){
        getMaintenancePart()
      }else {
        getPart()
      }
    }else {
      let sir = subindex?.split(',') || []
      if(sir.length > 1){
        let url = `/dataedit/vin?vin=${vin}&num=${num}&brandcode=${brandcode}`
        //catalog
        if(!vin){
          url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${num}`
        }
        navigate(url)
      }else {
        getStructure()
      }
      
    }
    if (!type) {
      document.addEventListener('keydown', handleKeydown)
    }
    return (()=>{
      document.removeEventListener('keydown', handleKeydown)
    })
  }, [])

  useEffect(()=>{
    if(activeHsKey){
      scrollToAnchor()
    }
  }, [activeHsKey])

  useEffect(()=>{
    if((pid || oe) && data.data && scrollTo){
      setScrollTo(false)
      scrollToAnchor()
    }
  }, [activePart])

  useEffect(()=>{
    if(getPartUpload){
      if(subindex){
        getSubGroup()
      }else {
        getPart()
      }
    }
  }, [structure])

  useEffect(()=>{
    if(getPartUpload){
      getPart()
    }
  }, [subGroup])

  useEffect(()=>{
    if(getPartTag){
      tag()
    }
  }, [data])

  useEffect(()=>{
    if(detailPart.pid){
      setPartDetailVisible(true)
    }
  }, [detailPart])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])
  
  
  const getStructure = () => {
    const param = {
      vin: vin,
      mcid: mcid,
      brandCode: brandcode,
      num: num
    }
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1) {
        result.data.forEach((d: any)=>{
          d.img = result.imgs[d.imageLarge] || ''
        })
        getPartUpload = true
        sessionStorage.setItem('structure', JSON.stringify(result.data))
        setStructure(result.data)
      }
    })
  }
  const getSubGroup = () => {
    if(index){
      const item = structure[index]
      const param = {
        vin,
        mcid: item.mcid,
        brandCode: item.brandCode,
        num: item.num,
        subgroup: item.subgroup,
        maingroup: item.maingroup,
        level: 1
      }
      vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
        if(result.code === 1) {
          result.data.forEach((d: any)=>{
            d.img = result.imgs[d.imageLarge] || ''
          })
          getPartUpload = true
          sessionStorage.setItem('subGroup', JSON.stringify(result.data))
          setSubGroup(result.data)
        }
      })
    }
  }
  const getPart = () => {
    let param: any = {}
    if(type === 'partSearch'){
      param = {
        vin,
        brandCode: brandcode,
        mcid: mcid,
        num: num,
        subgroup: subgroup,
        mid: mid
      }
    }else if(index) {
      let item = structure[index] || {}
      if(subindex){
        item = subGroup[subGroup.length - 1][index]
      }
      param = {
        vin,
        brandCode: brandcode,
        mcid: item.mcid,
        num: item.num,
        subgroup: item.subgroup,
        mid: item.mid
      }
    }
    setUpdateParams(param)
    vinApi.points(param).then((result: any)=>{
      if(result.code === 1 && result.data && result.data.information && result.data.information !== '[]' && result.data.cnts && result.data.cnts !== '[]'){
        
        result.data.information = result.data.information ? JSON.parse(result.data.information) : []
        result.data.cnts = result.data.cnts ? JSON.parse(result.data.cnts) : []
        if(boxContentRef.current){
          result.data.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }

        let imageFormat = 'image/png'
        if(result.data.img_name_simple){
          imageFormat = 'image/' + result.data.img_name_simple.substring(result.data.img_name_simple.lastIndexOf('.') + 1)
        }
        result.data.mask = false
        result.data.information.forEach((item: any, index: number)=>{
          for(let key in result.data.cnts){
            let cnt = result.data.cnts[key]
            // let x = 0
            // let y = 0
            if(cnt.itids.indexOf(item.itid_index) !== -1){
              if(!cnt.point){
                cnt.point = []
              }
              cnt.point.push(index)
              cnt.itid_old = item.itid_old
              result.data.mask = true
            }
            // if(cnt.cnts){
            //   cnt.cnts.forEach((c: any)=>{
            //     if(x === 0 || x > c[0]){
            //       x = c[0]
            //     }
            //     if(y === 0 || y > c[1]){
            //       y = c[1]
            //     }
            //   })
            // }
            // cnt.imgXY = [-x, -y]
          }
        })

        vinApi.partnew(param).then((res: any)=>{
          let itid_list: any = []
          let pid_list: any = []
          let tempDetail = {}

          res.data.part_detail.forEach((item: any)=>{
            item.forEach((detail: any)=>{
              if(detail.pnum){
                itid_list.push(detail.pnum)
              }
              if(detail.pid){
                pid_list.push(detail.pid)
              }
              if(detail.pid === pid){
                tempDetail = detail
              }else if(oe && oe === detail.pid){
                tempDetail = detail
              }
              if(!vin){
                detail.colorvalue = 1
              }
              detail.progress = 0

              detail.img = result.data.img_name_simple ? (cdnsImgPath + '/' + result.data.img_name_simple) : result.data.img_name
              
              if(result.data.information){
                result.data.information.forEach((pItem: any)=>{
                  if(pItem.itid_old === detail.pnum && pItem.rect){
                    // detail.rect = pItem.rect
                    detail.img_point = {
                      hotspots: [
                        {
                          "hsX": pItem.rect[0],
                          "hsY": pItem.rect[1],
                          "maxX": pItem.rect[0] + pItem.rect[2],
                          "maxY": pItem.rect[1] + pItem.rect[3],
                          "hsKey": pItem.itid_old
                        }
                      ],
                      maxScaleFactor: "",
                      imageFormat,
                      imageHeight: 1240,
                      imageWidth: 1120
                    }
                  }
                  
                })
              }
            })
          })
          result.data.itid_list = itid_list
          res.points = result.data
          res.pid_list = pid_list
          getPartTag = true
          setData(res)
          setActivePart(tempDetail)
        })
      }else {
        getOldPart(param)
      }
    })
  }
  const getOldPart = (param: any) => {
    vinApi.part(param).then((result: any)=>{
      if(result.code === 1){
        let pid_list: any = []
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((item: any)=>{
          item.forEach((detail: any)=>{
            if(detail.pid){
              pid_list.push(detail.pid)
            }
            if(detail.pid === pid){
              setActivePart(detail)
            }else if(oe && oe === detail.pid){
              setActivePart(detail)
            }
            if(!vin){
              detail.colorvalue = 1
            }
            if(result.img){
              detail.img = result.img.imagePath
            }
            if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
              result.img.imgPoints.hotspots.forEach((pItem: any)=>{
                if(pItem.hsKey === detail.pnum){
                  // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                  detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                  detail.img_point.hotspots = [pItem]
                }
              })
            }
          })
        })
        result.pid_list = pid_list
        getPartTag = true
        setData(result)
        
      }
    })
  }
  const getMaintenancePart = () => {
    let item = structure[index || 0]
    const pointParam = {
      brandCode: brandcode,
      mcid: mcid,
      final_id: finalid
    }
    let partParam: any = {
      brandCode: brandcode,
      mcid: mcid,
      vin: vin,
      sub_name: item.standard_subgroup_name,
      label_list: []
    }
    if(item.label_list){
      item.label_list.forEach((label: any)=>{
        partParam.label_list.push(label.standard_label)
      })
      partParam.label_list = partParam.label_list.join(',')
    }
    axios.all([vinApi.getMaintenancePoint(pointParam), vinApi.getMaintenancePart(partParam)]).then(axios.spread((pointResult, partResult) => { 
      if(pointResult.code === 1 && partResult.code === 1){
        pointResult.data.imagePath = pointResult.data.img_url
        let pid_list: any = []
        let result: any = {
          data: {
            part_detail: partResult.data
          },
          img: pointResult.data
        }
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((detail: any, index: number)=>{
          if(detail.pid){
            pid_list.push(detail.pid)
          }
          if(detail.pid === pid){
            setActivePart(detail)
          }
          detail.colorvalue = 1
          if(result.img){
            detail.img = result.img.imagePath
          }
          if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
            result.img.imgPoints.hotspots.forEach((pItem: any)=>{
              if(pItem.hsKey === detail.hsKey){
                // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                detail.img_point.hotspots = [pItem]
              }
            })
          }
          result.data.part_detail[index] = [detail]
        })
        result.pid_list = pid_list
        getPartTag = true
        setData(result)
      }
    }))
  }
  const anchor = (item: any) => {
    setActivePart(item)
    setActiveHsKey('')
    if(data.points){
      data.points.information.forEach((pItem: any)=>{
        if(pItem.itid_old === item.itid){
          setActivePoint(pItem, true)
        }
      })
    }
  }
  
  const scrollToAnchor = () => {
    if(listRef.current){
      let anchorElement = listRef.current.getElementsByClassName("svg-part-active")[0]
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
    
  }

  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
      if(index){
        let indexTemp: number[] = []
        let i = parseFloat(index)
        if(subindex){
          subGroup[subGroup.length - 1].forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        
        if(indexTemp.indexOf(i) === -1){
          i = indexTemp[0]
          let item = structure[i]
          if(subindex){
            item = subGroup[subGroup.length - 1][i]
          }
          let param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}`
          if(subindex){
            param += `&subindex=${subindex}`
          }
          let url = `/group/detail?${param}`
          if(vin){
            url = `/dataedit/vin/detail?vin=${vin}&${param}`
          }
          navigate(url)
          if(props.update){
            props.update()
          }
        }
      }
    }
  }
  const setGroup = (type: string) => {
    
    if((structure.length && index) && (( subGroup.length && subindex) || !subindex)){
      let i = parseFloat(index)
      if(filter){
        if(type === 'before'){
          if(i){
            i--
          }
        }
        if(type === 'next'){
          if(subindex){
            if(i < subGroup[subGroup.length - 1].length - 1){
              i++
            }
          }else {
            if(i < structure.length - 1){
              i++
            }
          }
          
        }
      }else {
        let indexTemp: number[] = []
        if(subindex){
          subGroup[subGroup.length - 1].forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        if(type === 'before'){
          if(indexTemp.indexOf(i) > 0){
            i = indexTemp[indexTemp.indexOf(i) - 1]
          }
        }
        if(type === 'next'){
          if(indexTemp.indexOf(i) < indexTemp.length - 1){
            i = indexTemp[indexTemp.indexOf(i) + 1]
          }
        }
      }
      
      let param = ''
      let item: any = {}
      if(subindex){
        if(i === parseFloat(index)){
          Toast(type === 'before' ? '第一组 !' : '最后一组 !')
          return
        }
        item = subGroup[subGroup.length - 1][i]
        param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}&subindex=${subindex}`
      }else {
        if(i === parseFloat(index)){
          Toast(type === 'before' ? '第一组 !' : '最后一组 !')
          return
        }
        item = structure[i]
        param = `brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}`
        if(finalid){
          param += `&finalid=${item.final_id}`
        }
      }
      item.type = 'detail'
      item.index = crumb[crumb.length - 1].index
      crumb.pop()
      crumb.push(item)
      setStore({value: crumb, type: 'crumb'})
      window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
      
      let url = `/group/detail?${param}`
      if(vin){
        url = `/dataedit/vin/detail?vin=${vin}&${param}`
      }
      navigate(url)
      if(props.update){
        props.update()
      }
    }
  }
  const searchConfig = (key_words?: string) => {
    let api = vinApi.config
    if(key_words){
      api = vinApi.configSearch
    }
    api({vin, brandCode: brandcode, key_words}).then((result: {code: number, data: any})=>{
      if(result.code === 1){
        setConfig(result.data)
      }else {
        setConfig([])
      }
    })
  }
  const back = () => {
    let param = `vin=${vin}&num=${num}`
    if(!vin){
      param = `mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${num}`
    }else if(vinData.data) {
      param += `&vinmcid=${mcid.replace(/\=/g, '%3D')}&vincode=${brandcode}`
    }
    if(subindex){
      param += `&subindex=${subindex}`
    }

    let url = `/dataedit/vin?${param}`
    if(!vin){
      url = `/group?${param}`
    }
    // let groupCrumb = window.sessionStorage.getItem('groupCrumb') || '[]'
    let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
    tempCrumb.pop()
    window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb || []))
    setStore({value: tempCrumb, type: 'crumb'})
    navigate(url)
  }
  
  const tag = () => {
    return false
    let tempData = JSON.parse(JSON.stringify(data))
    let param = {
      vin,
      brandCode: brandcode,
      pid_list: tempData?.pid_list?.join(',')
    }
    getPartTag = false
    if(!param.pid_list){
      return
    }
    axios.all([vinApi.tag(param), vinApi.getStandardLabel({brandCode: brandcode, pids: param.pid_list})]).then(axios.spread((result, re) => { 
      if(tempData.data){
        tempData.data.part_detail.forEach((item: any)=>{
          item.forEach((detail: any)=>{
            if(result.code === 1 && result.data[detail.pid]){
              detail.tag = result.data[detail.pid]
            }
            if(re.code === 1 && re.data[detail.pid]){
              detail.standardLabel = re.data[detail.pid].standard_label
            }
          })
        })
        setData(tempData)
      }
    }))
    // vinApi.tag(param).then((result: any)=>{
    //   if(result.code === 1){
    //     vinApi.getStandardLabel({brandCode: brandcode, pids: param.pid_list}).then((re: any)=>{
    //       if(re.code === 1){
    //         tempData.data.part_detail.forEach((item: any)=>{
    //           item.forEach((detail: any)=>{
    //             if(result.data[detail.pid]){
    //               detail.tag = result.data[detail.pid]
    //             }
    //             if(re.data[detail.pid]){
    //               detail.standardLabel = re.data[detail.pid].standard_label
    //             }
    //           })
    //         })
    //         setData(tempData)
    //       }
    //     })
    //   }
    // })

  }
  const getStandardLabel = () => {
    let tempData = JSON.parse(JSON.stringify(data))

  }
  const hsKeyAnchor = (val: string) => {
    setActivePart({})
    setActiveHsKey(val)
  }
  const setPoints = (points: any) => {
    let temp = JSON.parse(JSON.stringify(data))
    temp.points = points
    setData(temp)
  }

  const setActivePoint = (item: any, isKey?: boolean) => {
    let cntIndex = item.marks || []
    if(item.itids){
      cntIndex = [item.mark]
    }
    setCntIndex(cntIndex)
    if(!isKey){
      hsKeyAnchor(item.itid_old)
    }
    
  }
  

  const replace = (param: {pid: string, brandCode: string}) => {
    setReplaceData({})
    setRpVisible(true)
    setRpLoading(true)
    vinApi.replace(param).then((result: any)=>{
      setRpLoading(false)
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        
        setReplaceData({directly, indirect})
      }
    })
  }
  const setActiveOe = (e: any, item: any, multiple?: boolean) => {
    if(item.pid && store.param.id){
      let temp = JSON.parse(JSON.stringify(selectOE))
      const i = temp.findIndex((it: any) => it.pid === item.pid)
      const w: any = window
      item.source_label = store.param.name
      item.mcid = mcid || ''
      item.paramId = store.param.id
      item.paramVin = store.param.vin || ''
      item.operate = 'add'
      item.brandcode = brandcode
      item.url = `${removeQueryParam(window.location.href, 'oe')}&oe=${item.pid}`
      if( i === -1 || !multiple){
        if(multiple){
          onAnimate(e.target)
        }
        if(item.tag?.isreplace){
          vinApi.replace({pid: item.pid, brandCode: brandcode}).then((result: any)=>{
            if(result.code === 1){
              let directly: any = []
              let indirect: any = []
              result.data.forEach((ritem: any) => {
                if((ritem.isre === "1" || ritem.isre === "3") && ritem.pid){
                  ritem.pid.forEach((p: any)=>{
                    if(directly.indexOf(p.pid) === -1){
                      directly.push(p.pid)
                    }
                  })
                }
                // if(item.isre === "0"){
                //   indirect.push(item)
                // }
              })
              item.replace = directly
            }
            
            if(w.JsEvent || w.webBridge || w.parent || (w.chrome && w.chrome.webview && !multiple)){
              Toast('添加成功！')
            }
            if(multiple){
              temp.unshift(item)
              setStore({value: temp, type: 'selectOE'})
              window.localStorage.setItem('selectOE', JSON.stringify(temp))
            }else {
              if(w.JsEvent){
                w.JsEvent.jsToOEList(store.param.id, store.param.vin || '', [item], '0')
              }
              if(w.webBridge){
                w.webBridge.jsToOEList(store.param.id, store.param.vin || '', [item], '0')
              }
              if(w.parent){
                w.parent.postMessage([item], '*')
              }
              if(w.chrome && w.chrome.webview){
                // window.parent.postMessage({ msg: JSON.stringify(item) }, '*')
                w.chrome.webview.postMessage([item])
              }
            }
            console.info(store.param.id, store.param.vin || '', [item])
          })
        }else {
          
          
          if(w.JsEvent || w.webBridge || w.parent || (w.chrome && w.chrome.webview && !multiple)){
            Toast('添加成功！')
          }
          if(multiple){
            temp.unshift(item)
            setStore({value: temp, type: 'selectOE'})
            window.localStorage.setItem('selectOE', JSON.stringify(temp))
          }else {
            if(w.JsEvent){
              w.JsEvent.jsToOEList(store.param.id, store.param.vin || '', [item], '0')
            }
            if(w.webBridge){
              w.webBridge.jsToOEList(store.param.id, store.param.vin || '', [item], '0')
            }
            if(w.parent){
              w.parent.postMessage([item], '*')
            }
            if(w.chrome && w.chrome.webview){
              w.chrome.webview.postMessage([item])
              
              // window.parent.postMessage({ msg: JSON.stringify(item) }, '*')
            }
          }
          
          console.info(store.param.id, store.param.vin || '', [item])
        }
        
      }else {
        // const itemDel = temp.splice(i, 1)
        // itemDel[0].operate = 'delete'
        // console.info(itemDel[0])
        // if(w.JsEvent){
        //   w.JsEvent.jsRemoveOE(store.param.id, store.param.vin || '', itemDel)
        // }
        // if(w.webBridge){
        //   w.webBridge.jsRemoveOE(store.param.id, store.param.vin || '', itemDel)
        // }
        // if(w.chrome && w.chrome.webview && !multiple){
        //   w.chrome.webview.postMessage(itemDel[0])
        // }
        if(multiple){
          temp.splice(i, 1)
          setStore({value: temp, type: 'selectOE'})
          window.localStorage.setItem('selectOE', JSON.stringify(temp))
        }
        
      }
      
    }
  }
  const getSvgImgRect = () => {
    let svgImgRect = {}
    if(boxContentRef.current){
      svgImgRect = boxContentRef.current.getBoundingClientRect()
    }
    return svgImgRect
  }
  const onAnimate = (origin: any) => {
    let cartBall = document.getElementById("cartBall")
    let shopCart = document.getElementById("shopCart")
    let shopCartMin = document.getElementById("shopCartMin")
    const config = {
      ballWrapper: cartBall,
      origin: origin,
      target: store.shopcart ? shopCart : shopCartMin,
      time: 1000,
      a: store.shopcart ? 0.001 : 0.002,
    };
    parabola(config);
  }
  const handleKeydown = (e: any) => {
    if(isEditRef.current) return false
    if (e.keyCode === 37) { 
      setGroup('before')
    } else if (e.keyCode === 39) {
      setGroup('next')
    }
  }

  const showCb = (item: any) => {
    let url = window.sessionStorage.getItem('cburl') || ''
    url += url.includes('?') ? '&' : '?'
    const param: any = {
      pid: item.pid,
      brandcode,
      standardLabel: item.standardLabel,
      label: item.label.replace(/<[^>]*>/g, ''),
      phone: localStorage.getItem("username"),
      config: []
    }
    crumb.forEach((c: any)=>{
      if(c.type && c.type !== 'logo'){
        param.config.push(c.name)
      }
    })
    param.config = param.config.join('>')
    if(vin){
      param.vin = vin
    }
    // console.info(window.encodeURIComponent(JSON.stringify(param)))
    // console.info(window.decodeURIComponent(window.encodeURIComponent(JSON.stringify(param))))
    // return
    Object.keys(param).map((key)=>{
      url += `${key}=${param[key]}&`
    })
    url = url.slice(0, -1)
    setCbUrl(url)
    setCbVisible(true)
    setActiveRndPart(item)
  }

  const startEdit = () => {
    if(!filter) {
      setFilterLS()
    }
    setTimeout(() => {
      setIsEdit(true)
      setPartListDetail(JSON.parse(JSON.stringify((data.data && data.data.part_detail && data.data.part_detail) || [])))
    })
  }
  const submit = (type: string) => {
    let pnums: any = []
    partListDetail.forEach((its: any) => {
      its.forEach((it: any) => {
        if(!pnums.includes(it.pnum)) {
          pnums.push(it.pnum)
        }
      })
    })

    let addPartListObj: any = {}
    let otherPartList: any = []
    addPartList.forEach((item: any) => {
      if(item.pnum) {
        item.itid = item.itid || '--'
        item.psort = isNaN(parseInt(item.itid)) ? 0 : parseInt(item.itid)
        if(addPartListObj[item.pnum]) {
          addPartListObj[item.pnum].push(item)
        }else {
          addPartListObj[item.pnum] = [item]
        }
      }
    })
    Object.keys(addPartListObj).forEach((key) => {
      if(!pnums.includes(key)) {
        otherPartList.push(addPartListObj[key])
      }
    })
    let _part_list = JSON.parse(JSON.stringify(partListDetail)).map((items: any) => {
      let pnum = (items[0] || {}).pnum
      let result = items
      if(pnum && addPartListObj[pnum]) {
        result = [...items, ...addPartListObj[pnum]]
      }
      return result
    })
    
    let params = {
      ...updateParams,
      status: 1,
      part_list: [..._part_list, ...otherPartList]
    }
    setSubmitLoading({...submitLoading, [type]: true})
    vinApi.updatePartList(params).then((res: any) => {
      setSubmitLoading({...submitLoading, [type]: false})
      if(res.code === 1) {
        message.success("操作成功")
        setIsEdit(false)
        setStore({ type: 'routekey', value: store.routekey + 1 })
      }
    })

  }

  const switchImgVer = () => {
    let imagePath: any = data.img.imagePath || ""
    let imagePathArr = imagePath.split("/")
    let image_uid = imagePathArr[imagePathArr.length - 1].split(".")[0]
    let params = {
      brandCode: brandcode,
      image_uid,
      version: imgVer
    }
    setOtherLoading(true)
    vinApi.updateImgVer(params).then((res: any) => {
      setOtherLoading(false)
      if(res.code === 1) {
        message.success("图片版本修改成功！")
      }
    })
  }
  let part_detail = isEdit ? partListDetail : (data.data && data.data.part_detail && data.data.part_detail ? data.data.part_detail : [])
  let has_img = data.img?.imagePath || ""
  return (
    <>
      <div className={!type ? css['vin'] : css['vin'] + ' ' + css['no-mg']}>
        <div className={css['l']}>
          <div className={css['box']}>
            <div className={css['box-top'] + ' flex-b-c'}>
              {/* <h2>{data.data && data.data.mid}</h2> */}
              <h2>爆炸图</h2>
              {/* <div className={css['box-top-tools']}>
                <span>
                  <input type="text" placeholder="Maingroup Search" />
                  <i className="iconfont iconsousuo" />
                </span>
              </div> */}
              {
                has_img ? (
                  <div>
                    <label htmlFor="">图片版本：</label>
                    <Input
                      style={{ width: 160 }}
                      placeholder="请输入版本号"
                      onChange={(e) => setImgVer(e.target.value)}
                    />
                    <Button loading={otherLoading} onClick={() => switchImgVer()}>确定</Button>
                  </div>
                ): null
              }
            </div>
            <div ref={boxContentRef} className={css['box-content']}>
              {
                data.svgImgRect ? (
                  <Lite data={data} getSvgImgRect={getSvgImgRect} activeHsKey={activeHsKey} activePart={activePart} hsKeyAnchor={hsKeyAnchor} />
                ): null
              }
              {
                data.points ? (
                  <Exploded data={data.points} getSvgImgRect={getSvgImgRect} setPoints={setPoints} cntIndex={cntIndex} setActivePoint={setActivePoint} activeHsKey={activeHsKey} activePart={activePart} />
                ): null
              }
            </div>
          </div>
        </div>
        <div className={css['r']}>
          <div className={css['box']}>
            <div className={css['box-top']}>
              <h2>列表</h2>
              <div className={css['box-top-tools']}>
                {
                  !type ? (
                    <>
                      <button onClick={()=>back()} className={css['other-r']}><i className="iconfont icon-left" />返回</button>
                      <button onClick={()=>setGroup('before')}><i className="iconfont icon-shangyifenzu" /></button>
                      <em>切换</em>
                      <button onClick={()=>setGroup('next')}><i className="iconfont icon-xiayifenzu" /></button>

                      {
                        vin && !finalid ? (
                          <>
                            {/* <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />配置</button> */}
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? '未过滤' : '过滤'}</button>
                          </>
                        ): null
                      }
                      {/* <div style={{marginLeft: 10}}>
                        <SearchPartInput 
                          mcid={mcid || (data.data && data.data.mcid) }
                          brandcode={brandcode || data.brandCode}
                          vin={vin || ''}  
                        />
                      </div> */}
                      {/* <span>
                        <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                        <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                      </span> */}

                      <div className={css['operate-tools'] + ' ml10 bgc-fff'}>
                        {
                          isEdit ? (
                            <>
                              <Button size="small" className='mr10' onClick={() => setIsEdit(false)}>取消</Button>
                              <Button onClick={() => submit('save')} size="small" loading={submitLoading['save'] ? true : false} className='mr10' type="primary" ghost >保存</Button>
                              <Button onClick={() => submit('submit')} className={css['submit']} size="small" loading={submitLoading['submit'] ? true : false} type="primary" >发布</Button>
                            </>
                          ): (
                            <Button onClick={() => startEdit()} size="small" icon={<i className='iconfont icon-bianji' />} type="primary" style={{ background: '#FF7A00', borderColor: '#FF7A00' }}>编辑</Button>
                          )
                        }
                      </div>

                    </>
                  ): (
                    <>
                      {
                        backFn ? (
                          <button onClick={()=>backFn()} className={css['other']}><i className="iconfont icon-left" />返回</button>
                        ): null
                      }
                      {
                        vin ? (
                          <>
                            <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />配置</button>
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? '不过滤' : '过滤'}</button>
                          </>
                        ): null
                      }
                    </>
                  )
                }
                
              </div>
            </div>
            <div className={css['box-content']}>
              {
                finalid ? (
                  <>
                    <table className={css['table-header']}>
                      <thead>
                        <tr>
                          <th style={{width: '16%'}}>零件OE号</th>
                          <th style={{width: '17%'}}>名称</th>
                          <th style={{width: '12%'}}>分解图</th>
                          <th style={{width: '5%'}}>件数</th>
                          <th style={{width: '14%'}}>位置</th>
                          <th style={{width: '11%'}}>备注</th>
                          <th style={{width: '12%'}}>参考价格</th>
                          {/* <th style={{width: '8%'}}>Notes</th> */}
                          <th style={{width: store.param.id ? '8%' : '13%'}}>操作</th>
                        </tr>
                      </thead>
                    </table>
                    <div className={css['list']} ref={listRef}>
                      <table>
                        <tbody>
                          {
                            data.data && data.data.part_detail && data.data.part_detail.map((item: any, index: number)=>{
                              return (
                                item.map((part: any, i: number)=>{
                                  let style = ''
                                  
                                  if(activeHsKey){
                                    style = activeHsKey === part.hsKey ? `svg-part-active ${css['svg-part-active']}` : ""
                                    if((!index || activeHsKey !== data.data.part_detail[index-1][0].hsKey ) && activeHsKey === part.hsKey){
                                      style = style + ' ' + css['svg-part-active-top']
                                    }
                                    if((index === data.data.part_detail.length - 1 || activeHsKey !== data.data.part_detail[index+1][0].hsKey) && activeHsKey === part.hsKey){
                                      style = style + ' ' + css['svg-part-active-bottom']
                                    }
                                  }
                                  if(activePart.itid && activePart.pid === part.pid && activePart.itid === part.itid){
                                    style = 'svg-part-active' + ' ' + css['svg-part-active'] + ' ' + css['svg-part-active-top'] + ' ' + css['svg-part-active-bottom']
                                  }
                                  if(part.colorvalue === 2){
                                    style = style + ' ' + css['partgrey']
                                  }
                                  return (
                                    filter || part.colorvalue || isEdit ? (
                                      <Tooltip mouseLeaveDelay={0} visible={part.colorvalue === 2 ? undefined : false} placement="top" overlay="取消" overlayStyle={{zIndex: 9999}}>
                                        <tr key={index + '' + i} className={style} onClick={()=>anchor(part)}>
                                          
                                          
                                          <td style={{width: '16%', cursor: 'pointer', position: 'relative', color: store.param.id ? '#2a98fe' : '#000'}} onClick={(e)=>setActiveOe(e, part)}>
                                            <Copy title={part.pid} noTips />
                                            {
                                              part.pid ? <div style={{position: 'absolute', top: 0, right: 0}}><Copy title={part.pid} noTitle icon /></div> : ''
                                            }
                                            
                                          </td>
                                          <td style={{width: '17%'}} dangerouslySetInnerHTML={{__html: part.label}}></td>
                                          <td style={{width: '12%'}}><img src={part.img_url} style={{width: '90%', cursor: 'pointer'}} onClick={()=>{setPartKey(part.pid); setPartSearchkey(partSearchkey + 1)}} /></td>
                                          <td style={{width: '5%'}} dangerouslySetInnerHTML={{__html: part.quantity}}></td>
                                          <td style={{width: '14%'}} dangerouslySetInnerHTML={{__html: part.model}}></td>
                                          <td style={{width: '11%'}} dangerouslySetInnerHTML={{__html: part.remark}}></td>
                                          <td style={{width: '12%'}}>
                                            {part.tag?.sale_price}
                                          </td>
                                          <td style={{width: store.param.id ? '8%' : '13%'}}>
                                            
                                            {
                                              part.pid ? (
                                                <div className={css.operation}>
                                                  <Tooltip mouseLeaveDelay={0} placement="top" overlay="详情" overlayStyle={{zIndex: 9999}}>
                                                    <span className="iconfont icon-xiangqing" style={{fontSize: 14}} onClick={()=>setDetailPart(part)}></span>
                                                  </Tooltip>
                                                  {
                                                    part.tag?.isreplace ? (
                                                      <Tooltip mouseLeaveDelay={0} placement="top" overlay="替换件" overlayStyle={{zIndex: 9999}}>
                                                        <span className="iconfont icon-ti" onClick={()=>replace({pid: part.pid, brandCode: brandcode})}></span>
                                                      </Tooltip>
                                                    ): null
                                                  }
                                                  {
                                                    cburl ? (
                                                      <Tooltip mouseLeaveDelay={0} placement="top" overlay={cbname || "信息"} overlayStyle={{zIndex: 9999}}>
                                                        {
                                                          cbicon ? (
                                                            <img src={cbicon} onClick={()=>showCb(part)} />
                                                          ): (
                                                            <span className="iconfont icon-a-Group1" style={{fontSize: 14}} onClick={()=>showCb(part)}></span>
                                                          )
                                                        }
                                                      </Tooltip>
                                                    ): null
                                                  }
                                                  
                                                  
                                                </div>
                                                
                                              ) : null
                                            }
                                          </td>
                                        </tr>
                                      </Tooltip>
                                    ): null
                                  )
                                })
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                ): (
                  <>
                    <table className={css['table-header']}>
                      <thead>
                        <tr>
                          <th style={{width: '9%'}}>pnum</th>
                          <th style={{width: '9%'}}>位置</th>
                          <th style={{width: '16%'}}>零件OE号</th>
                          <th style={{width: '15%'}}>名称</th>
                          {/* <th style={{width: '12%'}}>标准名称</th> */}
                          <th style={{width: '9%'}}>件数</th>
                          <th style={{width: '14%'}}>型号</th>
                          <th style={{width: '15%'}}>备注</th>
                          {/* <th style={{width: '12%'}}>参考价格</th> */}
                          {/* <th style={{width: '8%'}}>Notes</th> */}
                          <th style={{width: '13%'}}>操作</th>
                        </tr>
                      </thead>
                    </table>
                    <div className={css['list']} ref={listRef}>
                      <table>
                        <tbody>
                          {
                            part_detail.map((item: any, index: number)=>{
                              return (
                                item.map((part: any, i: number)=>{
                                  let style = ''
                                  
                                  if(activeHsKey){
                                    style = activeHsKey === part.pnum ? `svg-part-active ${css['svg-part-active']}` : ""
                                    if((!i || activeHsKey !== item[i-1].pnum || (!filter && !item[i-1].colorvalue)) && activeHsKey === part.pnum){
                                      style = style + ' ' + css['svg-part-active-top']
                                    }
                                    if((i === item.length - 1 || activeHsKey !== item[i+1].pnum || (!filter && !item[i+1].colorvalue)) && activeHsKey === part.pnum){
                                      style = style + ' ' + css['svg-part-active-bottom']
                                    }
                                  }
                                  if(activePart.itid && activePart.pid === part.pid && activePart.itid === part.itid){
                                    style = 'svg-part-active' + ' ' + css['svg-part-active'] + ' ' + css['svg-part-active-top'] + ' ' + css['svg-part-active-bottom']
                                  }
                                  if(!part.colorvalue){
                                    style = style + ' ' + css['filter']
                                  }
                                  if(part.colorvalue === 2){
                                    style = style + ' ' + css['partgrey']
                                  }
                                  if(isEdit) {
                                    return (
                                      <tr className='bgc-fff' style={{ position: 'relative' }}>
                                        <td className='ptb5' style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='pnum' value={part.pnum} onChange={(e: any) => { part['pnum'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td className='ptb5' style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='位置' value={part.itid} onChange={(e: any) => { part['itid'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '16%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='OE' value={part.pid} onChange={(e: any) => { part['pid'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '15%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='名称' value={part.label} onChange={(e: any) => { part['label'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        {/* <td style={{width: '12%'}}>
                                          <Input.TextArea autoSize disabled style={{ width: '100%' }} placeholder='标准名称' value={part.standardLabel} onChange={(e: any) => { part['standardLabel'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td> */}
                                        <td style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='件数' value={part.quantity} onChange={(e: any) => { part['quantity'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '14%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='型号' value={part.model} onChange={(e: any) => { part['model'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '15%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='备注' value={part.remark} onChange={(e: any) => { part['remark'] = e.target.value; part['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        {/* <td style={{width: '12%'}}>
                                          
                                        </td> */}
                                        <td className='center ptb10' style={{width: '13%'}}>
                                          {
                                            part.colorvalue ? (
                                              <Button onClick={() => { part.colorvalue = 0; part['fixed'] = 1; setChange(c => !c) }} size="small" type="primary" ghost danger>加入过滤</Button>
                                            ): (
                                              <Button onClick={() => { part.colorvalue = 1; part['fixed'] = 1; setChange(c => !c) }} size="small" type="primary" ghost >移出过滤</Button>
                                            )
                                          }
                                        </td>
                                        <CloseCircleOutlined  className={css['icon-close']} 
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            part_detail[index].splice(i, 1)
                                            setChange(c => !c)
                                          }}
                                        />
                                      </tr>
                                    )
                                  }
                                  return (
                                    filter || part.colorvalue ? (
                                      <Tooltip mouseLeaveDelay={0} visible={part.colorvalue === 2 ? undefined : false} placement="top" overlay="取消" overlayStyle={{zIndex: 9999}}>
                                        <tr key={index + '' + i} className={style} onClick={()=>anchor(part)} >
                                          <td style={{width: '9%'}}>{part.pnum}</td>
                                          <td style={{width: '9%'}}>{part.itid}</td>
                                          <td className={css['pid']} style={{width: '16%', cursor: 'pointer', position: 'relative', color: store.param.id ? '#2a98fe' : '#000'}} onClick={(e)=>setActiveOe(e, part)}>
                                            <Copy title={part.pid} noTips />
                                            {
                                              part.pid ? <div style={{position: 'absolute', top: 0, right: 0}}><Copy title={part.pid} noTitle icon /></div> : ''
                                            }
                                          </td>
                                          <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.label}}></td>
                                          {/* <td style={{width: '12%'}} dangerouslySetInnerHTML={{__html: part.standardLabel}}></td> */}
                                          <td style={{width: '9%'}} dangerouslySetInnerHTML={{__html: part.quantity}}></td>
                                          <td style={{width: '14%'}} dangerouslySetInnerHTML={{__html: part.model}}></td>
                                          <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.remark}}></td>
                                          {/* <td style={{width: '12%'}}>
                                            {part.tag?.sale_price}
                                          </td> */}
                                          <td style={{width: '13%'}}>
                                          </td>
                                          
                                        </tr>
                                      </Tooltip>
                                    ): null
                                  )
                                })
                              )
                            })
                          }
                          {
                            isEdit ? (
                              <>
                                {
                                  addPartList.map((it: any, i: number) => {
                                    return (
                                      <tr className='bgc-fff' style={{ position: 'relative' }}>
                                        <td className='ptb5' style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='pnum' value={it.pnum} onChange={(e: any) => { it['pnum'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td className='ptb5' style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='位置' value={it.itid} onChange={(e: any) => { it['itid'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '16%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='OE' value={it.pid} onChange={(e: any) => { it['pid'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '15%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='名称' value={it.label} onChange={(e: any) => { it['label'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        {/* <td style={{width: '12%'}}>
                                          <Input.TextArea autoSize disabled style={{ width: '100%' }} placeholder='标准名称' value={it.standardLabel} onChange={(e: any) => { it['standardLabel'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td> */}
                                        <td style={{width: '9%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='件数' value={it.quantity} onChange={(e: any) => { it['quantity'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '14%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='型号' value={it.model} onChange={(e: any) => { it['model'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        <td style={{width: '15%'}}>
                                          <Input.TextArea autoSize style={{ width: '100%' }} placeholder='备注' value={it.remark} onChange={(e: any) => { it['remark'] = e.target.value; it['fixed'] = 1; setChange(c => !c) }} />
                                        </td>
                                        {/* <td style={{width: '12%'}}>
                                        </td> */}
                                        <td className='center ptb10' style={{width: '13%'}}>
                                          {
                                            it.colorvalue ? (
                                              <Button onClick={() => { it.colorvalue = 0; it['fixed'] = 1; setChange(c => !c) }} size="small" type="primary" ghost danger>加入过滤</Button>
                                            ): (
                                              <Button onClick={() => { it.colorvalue = 1; it['fixed'] = 1; setChange(c => !c) }} size="small" type="primary" ghost >移出过滤</Button>
                                            )
                                          }
                                        </td>
                                        <CloseCircleOutlined  className={css['icon-close']} 
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            addPartList.splice(i, 1)
                                            setChange(c => !c)
                                          }}
                                        />
                                      </tr>
                                    )
                                  })
                                }
                                <tr>
                                  <td colSpan={9} style={{ padding: '5px 0', fontSize: 26, color: '#888888' }}>
                                    <i className='iconfont icon-tianjia pointer' onClick={() => {
                                      let _addPartList = [...addPartList]
                                      _addPartList.push(initPartItem)
                                      setAddPartList(_addPartList)
                                    }} />
                                  </td>
                                </tr>
                              </>
                            ): null
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                )
              }
              
            </div>
          </div>
        </div>
        {/* <div className={css['footer']}>
        声明:查询结果仅供参考
        </div> */}
      </div>
      <Modal
        title="配置"
        visible={configVisible}
        cancel={setConfigVisible}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="请输入配置信息" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              Object.keys(config).map((key: string, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{key}</label>
                    <span>{config[key]}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid}
            brandcode={brandcode}
            vin={vin}
          />
        ): null
      }
      {
        partDetailVisible ? (
          <Modal
            title='零件详情'
            visible={true}
            cancel={()=>{setPartDetailVisible(false); setDetailPart({})}}
            width={1000}
          >
            <PartDetail vin={vin} pid={detailPart.pid} brandCode={brandcode} mcid={mcid} mid={mid} num={num} queryType={vin ? 'vin' : 'cars'} />
          </Modal>
          
        ) : null
      }
      <Modal
        visible={rpVisible}
        cancel={() => setRpVisible(false)}
        width={1000}
      >
        <div className={styles['vehicle-brand-wrap']}>
          <div className={styles['vehicle-select']}>
            <span className={styles['vehicle-title']}>替换件</span>
          </div>
          <div className={styles['vehicle-table-h']}>
            <table className={styles['table-header']}>
              <thead>
                <tr>
                  <th style={{width: '15%', textAlign: 'center'}}>品牌</th>
                  <th style={{width: '25%', textAlign: 'center'}}>OE号</th>
                  <th style={{width: '20%', textAlign: 'center'}}>零件名称</th>
                  <th style={{width: '10%', textAlign: 'center'}}>数量</th>
                  <th style={{width: '15%', textAlign: 'center'}}>详情</th>
                  <th style={{width: '15%', textAlign: 'center'}}>价格</th>
                </tr>
              </thead>
            </table>
            <Loading visible={rpLoading}>
              <div className={styles.maxh}>
                {
                  replaceData.directly?.length ? (
                    <table>
                      <caption>直接替换</caption>
                      <tbody>
                        {
                          replaceData.directly.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} icon /> </p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i:number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
                {
                  replaceData.indirect?.length ? (
                    <table>
                      <caption>间接替代</caption>
                      <tbody>
                        {
                          replaceData.indirect.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}>{pid.pid}</p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}>{pid.pid}</p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
              </div>
            </Loading>
          </div>
        </div>
      </Modal>
      <RndModal
        title={activeRndPart.pid}
        visible={cbVisible}
        cancel={() => setCbVisible(false)}
      >
        <iframe src={cburl} style={{width: '100%', height: 'calc(100% - 40px)', border: 'none'}} ></iframe>
      </RndModal>
    </>
  );
}

export default VinDetail
