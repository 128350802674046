import React, { createContext } from 'react'

interface context {
  store: any,
  setStore: any
}

const MainContext= createContext<context>({
  store: {},
  setStore: ()=>{}
})

export default MainContext